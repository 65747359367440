.GinRummy {
    --deck-after-font-size: 17px;
    --gin-rummy-number-deck-font-size: 2.8rem;
    .cards-section {
        position: absolute;
        z-index: 9999;
        top: var(--gin-rummy-deck-top, 252.5px);
        left: var(--gin-rummy-deck-left, 613px);
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        gap: var(--gin-rummy-deck-gap, 16px);

        .discard-area,
        .deck-area {
            width: var(--deck-width, 96px);
            height: var(--deck-height, 144px);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: visible;

            &::before {
                position: absolute;
                top: var(--card-border-position, -5px);
                bottom: var(--card-border-position, -5px);
                left: var(--card-border-position, -5px);
                right: var(--card-border-position, -5px);
                border-radius: var(--card-border-radius, 14px);
                border: var(--card-border-width, 6px) solid var(--card-border-color, #ffffff);
            }

            &.active {
                &::before {
                    content: '';
                }

                animation: card-blinking 2s infinite;
            }
        }
    }

    .meld-bottom-line {
        position: absolute;
        height: var(--gin-rummy-meld-line-height, 8px);
        border-radius: var(--gin-rummy-meld-line-height, 8px);
        transform: translate(-50%, -100%);
        z-index: 99;

        &:nth-of-type(odd) {
            background: linear-gradient(180deg, #F0FF22 0%, #EAF207 40%, #3C1414 100%);
        }

        &:nth-of-type(even) {
            background: linear-gradient(180deg, #FF2222 0%, #F20707 40%, #3C1414 100%);
        }
    }

    .deadwood {
        &-area {
            position: absolute;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            overflow: visible;

            &::before {
                position: absolute;
                content: '';
                top: -4px;
                bottom: -2px;
                left: -3px;
                right: -3px;
                border: 1px solid #018e06;
                background-color: #018e06;
                border-radius: 10px;
            }

            &.showdown::before {
                border-color: #E9FE00;
                background-color: #E9FE00;
            }

            &:not(.showdown) {
                .deadwood-content {
                    font-size: var(--gin-rummy-deadwood-content-font-size, 16px);
                    height: var(--gin-rummy-deadwood-content-height, 25px);
                    color: #ADFF00;
                    background-color: #00B207;
                    margin: 0 -1px;

                    &::before {
                        content: "deadwood";
                        text-transform: uppercase;
                        color: #ffffff;
                        margin-right: 8px;
                    }

                    &::after {
                        content: " / 10";
                    }

                    &-wrapper {
                        position: absolute;
                        bottom: 100%;
                        display: flex;
                        margin-bottom: 4px;

                        &::before,
                        &::after {
                            content: '';
                            height: var(--gin-rummy-deadwood-content-height, 25px);
                            width: var(--gin-rummy-deadwood-content-height, 25px);
                            display: block;
                        }

                        &::before {
                            background-image: url('../../../../public/images/gin-rummy/deadwood-start.svg');
                        }

                        &::after {
                            background-image: url('../../../../public/images/gin-rummy/deadwood-end.svg');

                            .mobile & {
                                background-position-x: -5px;
                            }
                        }
                    }
                }
            }
        }

        &-showdown {
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 999;
            background-color: #EA5400;
            width: var(--gin-rummy-deadwood-showdown-width, 64px);
            height: var(--gin-rummy-deadwood-showdown-width, 64px);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #E9FE00;
            border-radius: 50%;
            color: #fff;
            font-size: var(--gin-rummy-deadwood-showdown-font-size, 28px);
            box-shadow: 2px 4px 10px #00000080;
        }
    }

    .card,
    .deck,
    .face {
        img {
            box-shadow: -2px 0 20px 0 rgba(0, 0, 0, 0.25);
        }
    }

    .table-result-head,
    .table-result-content-row {
        gap: 24px;
        padding: 0 15px;

        &>span:first-child {
            width: 80px;
        }

        &>span:last-child {
            width: 60px;
        }
    }

    .table-result-content-row {
        height: 30px;
    }

    .table-result-head {
        height: 40px;
    }

    .mobile & {
        --trentuno-number-deck-font-size: 28px;
        --gin-rummy-number-deck-top: 20px;
        --gin-rummy-meld-line-height: 4px;

        .show-score-btn {
            position: absolute;
            width: 40px;
            height: 40px;
            background-image: url('../../../../public/images/common/trentuno-ranking.png');
            background-repeat: no-repeat;
            top: 5px;
            right: 5px;

            &.active {
                background-image: url('../../../../public/images/common/trentuno-ranking-close.png');
            }

            &>span {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-size: 12px;
            }
        }

        .score-mobile-wrapper {
            &.table-result-box {
                transform: unset !important;
            }

            position: absolute;
            width: 134px !important;
            font-size: 12px;
            background-color: rgba(10, 32, 32, 0.2);
            backdrop-filter: blur(20px);
            top: 5px;
            right: 60px;
        }

        .table-result-head,
        .table-result-content-row {
            gap: 18px;
            padding: 0 10px;

            &>span:first-child {
                width: 60px;
            }

            &>span:last-child {
                width: 40px;
            }
        }

        .table-result-head {
            height: 30px;
        }

        .table-result-content-row {
            height: 24px;
        }

        @media screen and (orientation: landscape) {
            --gin-rummy-deck-left: 385px;
            --gin-rummy-deck-top: 115px;
            --deck-width: 48px;
            --deck-height: 72px;
            --table-bottom: 0;

            .table-button-list {
                margin-right: 0 !important;
            }

            .show-score-btn {
                top: 5px;
                right: 32px;
            }

            .score-mobile-wrapper {
                top: 65px;
                right: 5px;
            }
        }

        @media screen and (orientation: portrait) {
            --gin-rummy-deck-left: 174px;
            --gin-rummy-deck-top: 372px;
            --deck-width: 57.6px;
            --deck-height: 86.4px;
        }

        --card-border-position: -3px;
        --card-border-width: 4px;
        --card-border-radius: 10px;
        --gin-rummy-deadwood-showdown-width: 32px;
        --gin-rummy-deadwood-showdown-font-size: 14px;
        --gin-rummy-deadwood-content-height: 20px;
        --gin-rummy-deadwood-content-font-size: 12px;
    }

    .btn-green {
        background: linear-gradient(180deg, #41F373 0%, #00AB26 100%);
        box-shadow: 0px 2px 0px 0px #07581A;
    }
}



@keyframes card-blinking {
    50% {
        --card-border-color: rgb(240, 255, 34);
    }
}