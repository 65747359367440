.mobile-landscape {
    --badge-list-min-width: auto;
    --badge-list-height: 22px;
 
    --footer-main-flex-direction: column;
    --footer-main-align-items: start;
    --footer-main-flex-wrap: wrap;
    --footer-main-gap: 10px;
    --footer-content-top-justify-content: space-between;
    --footer-image-width-mobile: 176px;
    --footer-content-align-items: flex-start;
    --footer-content-top-gap: inherit;
 
    --profile-avatar-info-flex-direction: row;
    --profile-avatar-info-gap: 86px;
 
    --height-currency: 30px;
 
    --font-size-20: 24px;
    --font-size-14: 16px;
    --font-size-10: 16px;
    --font-size-12: 20px;
    --font-size: 16px;
    --font-size-th: 20px;
    --font-size-16: 20px;
 
    --title-font-size: 20px;
 
    --profile-user-rate-margin-top: 0;
    --profile-user-number-width: 130px;
    --profile-user-number-height: 40px;
    --profile-user-number-font-size: 20px;
    --profile-user-label-text-align: center;
    --profile-user-rate-gap: 15px;
    --profile-user-container-padding: 8px 50px;
    --profile-user-info-item-font-size: 20px;
    --profile-user-info-list-gap: 10px;
    --profile-user-info-item-gap: 40px;
    --profile-user-info-list-flex-direction: row;
    --profile-user-info-item-width: 49%;
    --profile-user-info-list-padding: 20px 50px;
 
    --padding-x-content: 50px;
 
    --profile-menu-item-child-pl: 85px;
    --profile-menu-item-height: 50px;
 
 
    --popover-edit-avatar-margin-left: 0;
    --popover-edit-avatar-margin-top: 0;
    --popover-edit-avatar-width: 100vw;
    --popover-edit-avatar-height: 100vh;
 
    --profile-edit-avatar-width: 110px;
    --profile-edit-container-align-items: center;
 
    --avatar-size: 64px;
    --avatar-list-margin-bottom: 0;
    --avatar-list-padding: 50px;
    --avatar-list-grid-template-columns: repeat(6, 110px);
 
    --button-medium-height: 40px;
    --button-medium-min-width: 175px;
    --button-large-min-width: 120px;
    --button-large-height: 40px;
    --button-large-font-size: 14px;
    --button-medium-max-width: 240px;
 
    --filter-mobile-list-margin: -16px 0;
    --height-title: 40px !important;
 
    --badge-icon-width: 25px;
 
    --notification-empty-margin-top: 0;
    // --notification-timeout-width: 110px;
    // --notification-timeout-height: 40px;
 
    --tournament-mobile-item-padding: 4.5px 10px;
    --tournament-mobile-item-width-button: 100%;
    --tournament-container-box-shadow: none;
    --tournament-info-table-width: 50%;
    --tournament-info-padding: 6px 10px;
    --tournament-info-border-bottom: #fff;
    --tournament-info-label-width: 150px;
    --tournament-info-table-padding: 6px 0;
    --tournament-info-list-padding: 0;
    --tournament-info-table-height: 100%;
 
    --container-lobby-page-padding: 12px;
 
    --state-label-font-size: 12px;
    --state-label-height: 23px;
 
    --chat-tabs-padding: 0 50px;
    --chat-list-padding: 0 50px;
 
    --tournament-info-font-size: 16px;
 
    --select-component-font-size: 16px;
    --select-component-height: 40px;
    --select-component-padding: 0 10px;
 
    --subcribe-pay-font-size: 20px;
    --subcribe-pay-border: none;
    --subcribe-pay-padding: 0;
    --subcribe-radio-font-size: 20px;
    // --subcribe-main-padding: 0 50px;
    --subcribe-main-font-size: 20px;
    --subcribe-radio-scale: 1.4;
    --subcribe-radio-icon-margin: 5px 10px 5px 0;
    --subcribe-left-padding: 40px;
 
    --margin-form-control: 0;
    --modal-main-padding: 50px;
    --mobile-landscape-padding: 50px;

    --chat-suggest-list-max-height: 93px;
    --chat-emoji-list-gap: 25px;

    --welcome-user-main-flex-direction: row;
    --welcome-user-avatar-title-height: 150px;
    --welcome-user-avatar-title-width: 1px;
    --welcome-user-avatar-title-top: 0;
    --welcome-user-avatar-title-left: 0;
    --welcome-user-left-width: 40%;
    --welcome-user-avatar-title-margin-top: 0;
    --welcome-user-avatar-title-padding-top: 0;

 
    @media screen and (max-width: 750px) {
        --chat-tabs-padding: 0 13px;
        --chat-list-padding: 0 13px;
        --profile-user-container-padding: 11px 13px 17px 13px;
        --padding-x-content: 13px;
        --profile-avatar-info-gap: 30px;
        --modal-main-padding: 13px;
        --profile-user-info-list-padding: 20px;
        --profile-user-info-item-gap: 10px;
        --mobile-landscape-padding: 12px;
    }
 
    .tournament-info-value {
        img {
            height: 30px;
        }
    }
 
    .subcribe-right {
        border-left: 1px solid #d2d2d3;
    }
 
    .session-tab {
        height: calc(100% - 90px);
        overflow: auto;
    }
 
    .tournament-info-container {
        padding: 0 0 0 50px;
        height: 100%;
        overflow: hidden;
        @media screen and (max-width: 750px) {
            padding: 0;
        }
    }
 
    .tournament-info {
        height: 100%;
        border-right: 1px solid #d2d2d3;
    }
 
    .tournament-info-table .table-container {
 
        th,
        td {
            &:first-child {
                padding-left: 12px !important;
            }
 
            &:last-child {
                padding-right: 12px !important;
            }
        }
    }
 
    .tournament-info-tabpanel {
        padding: 0 10px;
        height: 35px;
    }
 
    .profile-credit-bonus {
        >div>svg {
            width: 30px;
            height: 30px;
        }
    }
 
    .MuiPopover-paper {
        transform-origin: 0 0 !important;
        top: 0 !important;
        left: 0 !important;
    }
 
    .MuiDrawer-paper {
        width: calc(100% - 100px);
        bottom: 0 !important;
    }
 
    .filter-cost,
    .filter-matchType,
    .filter-status,
    .filter-objective {
        .filter-item-label {
            width: 33.33%;
        }
    }
 
    .filter-timeSpeed {
        .filter-item-label {
            width: 25%;
        }
    }
 
    .table-container {
        tr {
            td {
                padding: 0 12px;
            }
 
            th {
                --padding: 5.5px 12px;
                height: 40px;
                border-top: 1px solid #d2d2d3;
            }
        }
 
        .replay-btn {
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}