$deck-w-solitario: 65px;
$deck-h-solitario: 111px;
$deck-w-solitario-mb: 33px;
$deck-h-solitario-mb: 56px;

.Solitario {
    --player-content-border-radius: 100px 0 0 100px;
    --player-content-margin-right: -15px;
    --player-content-padding-right: 25px;
    --player-content-padding-left: 10px;
    --player-content-margin-left: 0;
    --card-width: 75px;
    --card-height: 112.5px;
    --card-border-radius: 4px;
    --card-back-border-radius: 4px;
    .mobile & {
        @media screen and (orientation: landscape) {
            --info-table-box-top: 0;
        }
        @media screen and (orientation: portrait) {
            --info-table-box-top: -43px;
        }
    }

    .player-info {
        transform: var(--player-info-transform, scale(0.8));
        transform-origin: center;
    }

    .points-desktop-container {
        right: 20px;
        top: 20px;
        position: absolute;
        height: calc(100% - 94px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .points-desktop {
            background: white;
            position: relative;
            width: 12px;
            border-radius: 10px;
            flex: 1;

            .points-counter {
                background: linear-gradient(90deg, #EB5F5F 0%, #E3E803 100%);
                border-radius: 10px;
                padding: 6px;
                position: absolute;
                bottom: 0;
            }
        }

        .points-desktop-winner {
            position: relative;
            width: 50px;
            z-index: 10;
        }

        .seat-container {
            position: absolute;
            top: 20px;
        }

        .mobile & {
            right: -190px;
            top: 0px;
            scale: 1;

            @media screen and (orientation: landscape) {
                right: -250px;
            }
        }
    }

    .points-mobile-container {
        position: absolute;
        bottom: var(--points-mobile-container-bottom);
        right: 0;
        left: 0;
        width: var(--points-mobile-container-width);
        height: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);

        .points-mobile {
            background-color: white;
            height: 18px;
            border-radius: 10px;
            flex: 1;

            .points-counter {
                background: linear-gradient(90deg, #EB5F5F 0%, #E3E803 100%);
                border-radius: 10px;
                padding: 9px;
            }
        }

        .points-mobile-winner {
            position: relative;
            width: 60px;
            height: 60px;
            z-index: 10;
        }
    }

    .time-container {
        width: var(--time-container-width, 100%);
        position: absolute;
        bottom: var(--time-container-bottom, 0);
        left: var(--time-container-left, 0);
        right: var(--time-container-right, 0);
        transform: var(--time-container-transform);
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 10px;

        .time-progress-container {
            border-radius: 10px;
            width: 95%;
            height: var(--time-progress-container-height, 20px);
            display: flex;
            z-index: 10;
            background-color: white;

            .time-counter {
                background: linear-gradient(180deg, #E40000 0%, #461111 100%);
                border-radius: 10px;
                padding: 9px;
            }
        }

        .time-number {
            font-size: var(--time-number-font-size, 20px);
            color: white;
            font-weight: bold;
            z-index: 10;
            min-width: var(--time-number-width, 54px);
        }
    }

    .pile-container {
        width: var(--card-width);
        height: var(--card-height);
        position: absolute;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 4px;
        border: 2px solid #FFF;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        background-image: url('../../images/add_circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
        z-index: -1;
    }

    .empty-card {
        width: var(--card-width);
        height: var(--card-height);
        position: absolute;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border: 2px solid #d3d3d3;
        border-radius: 4px;
        z-index: 1;
    }

    .user-point {
        color: white;
        font-size: 20px;

        .rank {
            .user-ranks {
                display: flex;
                align-items: baseline;

                .user {
                    font-size: 64px;
                    line-height: 1.1;
                }

                .total {
                    font-size: 32px;
                }
            }
        }

        .point {
            margin-top: 15px;

            .user-point {
                font-size: 32px;
            }
        }
    }

    .user-point-mobile {
        color: white;
        font-size: 22px;
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        align-items: baseline;
        width: 130vw;

        @media screen and (orientation: landscape) {
            width: 100%;
            display: flex;
            justify-content: end;
            gap: 30px;
            margin-left: -40px;
        }

        .rank {
            display: flex;
            align-items: baseline;
            gap: 20px;

            .user-ranks {
                display: flex;
                align-items: baseline;

                .user {
                    font-size: 48px;
                }

                .total {
                    font-size: 32px;
                }
            }
        }

        .point {
            display: flex;
            align-items: baseline;
            gap: 20px;

            .user-point {
                font-size: 40px;
            }
        }
    }

    .pile-card {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .player-main {
        flex-direction: row-reverse;
    }

    .player-content {
        align-items: self-end;
    }
}

.background-solitario {
    position: var(--background-solitario-position, absolute);
    top: 0;
    left: 0;
    right: 0;
    bottom: 54px;
    background-color: #076260;
    border-radius: var(--background-solitario-border-radius, 20px);
    z-index: -1;
}

.mobile {
    --background-solitario-position: fixed;
    --time-container-width: 100%;
    --time-container-transform: translateX(-50%);
    --time-progress-container-height: 18px;
    --time-number-font-size: 18px;
    --time-number-width: 40px;
    --player-info-transform: 1;
    --time-container-left: 50%;
    --points-mobile-container-width: 100%;
    --points-mobile-container-bottom: 55px;
    .Solitario {
        @media screen and (orientation: landscape) {
            --card-width: 40px;
            --card-height: 60px;
            .time-progress-container {  
                width: 90.5%;
            }
        }

        @media screen and (orientation: portrait) {
            --card-width: 44px;
            --card-height: 65px; 
        }
    }
    .table-Solitario {
        @media screen and (orientation: landscape) {
            bottom: 50px !important;
        }
        @media screen and (orientation: portrait) {
            top: 50px !important;
            bottom: 60px !important;
        }
    }
}

.background-solitario-mobile {
    background: #076260;
    width: var(--background-solitario-mobile-width);
    height: var(--background-solitario-mobile-height);
    position: fixed;
    top: var(--background-solitario-mobile-top);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    z-index: -1;
    --background-solitario-mobile-height: calc(100% - 100px);
    --background-solitario-mobile-width: 105%;
    --background-solitario-mobile-top: 0;

    @media screen and (orientation: landscape) {
        --background-solitario-mobile-width: 100%;
        --background-solitario-mobile-top: 1%;
    }

    @media screen and (orientation: portrait) {
        // --background-solitario-mobile-top: 40%;
    }
}