@import 'button.scss';
@import 'checkbox.scss';
@import 'table.scss';
@import 'seat.scss';
@import 'games';
@import 'loading-game.scss';
@import '../styles/common/mixin';
@import '../styles/common/common';

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  src: url('../../../public/fonts/Metropolis-Black.otf');
}

@font-face {
  font-family: 'Metropolis Black';
  font-style: normal;
  src: url('../../../public/fonts/Metropolis-Black.otf');
}

@font-face {
  font-family: 'Metropolis';
  font-style: bold;
  src: url('../../../public/fonts/Metropolis-Bold.otf');
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.sidebar-item {
  &:last-child {
    border-bottom: none !important;
  }
  .logo img {
    height: 35px;
  }
}

.sidebar-link {
  &::before {
    background-color: #F7F7F9;
  }
}

.sidebar-close {
  &::before {
    background-color: #CD1B1B;
  }

  &:hover path {
    stroke: #fff
  }
}

.scroll-bar,
.ant-drawer-body {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color: #828b92, $alpha: .12);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border: 1px solid #175EBE !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.ant-modal-content {
  border-radius: 12px !important;
  box-shadow: 0px 3px 66px rgba(11, 14, 17, 0.06);
  padding: 24px !important;

  .ant-modal-header {
    margin-bottom: 24px;
  }

  .ant-modal-title {
    font-size: 20px;
    color: #0B0E11;
    font-weight: 700;
  }
}

.ant-modal .ant-modal-close {
  width: 36px;
  height: 36px;

  &:hover {
    background-color: transparent;
  }
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 13px 16px;
  white-space: nowrap;
}

.table-room {
  .ant-table-tbody>tr {
    &:nth-child(odd) {
      background-color: #F7F7F9;

      td {
        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    >td {
      border: none !important;
    }
  }

  .ant-table-thead>tr>th {
    background-color: transparent !important;
    border: none !important;
    padding: 8px 16px;
    color: #9EA8B4;
    font-weight: 400;

    &::before {
      display: none;
    }
  }
}

.card.active {
  top: -35px !important;
}

.card.active.border {
  border: blue;
  border-style: solid;
}

.hhand {
  display: inline-block;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.Toastify__toast-container {
  padding: 0;

  @media screen and (max-width: 800px) {
    width: 100% !important;
    max-width: 320px;
  }
  
}

.toast-custom {
  border: 1px solid #fff8f8;
  border-radius: 8px !important;
  background: #0d282a !important;
  padding: 12px 20px !important;
  box-shadow: 0px 4px 4px rgba(7, 7, 7, 0.25) !important;
  margin: 0;

  .Toastify__progress-bar {
    opacity: 0 !important;
  }

  .Toastify__toast-body {
    @extend .font-raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    padding: 0;
    padding-right: 10px;

    .mobile & {
      @media screen and (orientation: portrait) {
        font-size: 14px;
      }
    }
  }

  .Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }

  .Toastify__close-button--light {
    color: #fff;
    opacity: 1;
  }
}


.toast-lobby {
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-right,
  .Toastify__toast-container--top-center {
    @media screen and (max-width: 480px) {
      transform: none;
    }
  }
  
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-right {
    top: 0%;
    left: 0%;
    .mobile & {
      width: fit-content;
    }
  }
}

.font-raleway {
  font-family: 'Raleway', sans-serif !important;
}

.toast-center {
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
  min-width: 400px;

  .mobile & {
    @media screen and (orientation: portrait) {
      min-width: 320px;
      max-width: 320px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

.Toastify__toast-container--top-left,
.Toastify__toast-container--top-right,
.Toastify__toast-container--top-center {
  @media screen and (max-width: 480px) {
    transform: none;
  }
}

.Toastify__toast-container--top-center,
.Toastify__toast-container--top-left,
.Toastify__toast-container--top-right {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .mobile & {
    width: fit-content;
  }
}

@media screen and (max-width: 500px) and (max-height: 900px) {
  .toast-portrait {
    position: fixed;
    width: 100%;
    max-width: 400px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-top: 350px;
  }
}

.MuiModal-backdrop {
  position: absolute !important;
}

.color-primary {
  color: #0b787c;
}

.table-result {
  .ant-tabs .ant-tabs-tab {
    padding: 0 !important;
    flex: 1;
    opacity: 0.3;

    &.ant-tabs-tab-active {
      opacity: 1;
    }
  }

  .ant-tabs-nav::before {
    display: none !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab-btn,
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }
}

.imgages-card {
  &:not(.active) {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}

.modal-custom {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-close {
    top: 0;
    right: 0;
    color: #fff;
  }

  .ant-modal-content {
    overflow: hidden;
  }

  .btn {
    height: 40px;
    text-transform: none;
  }
}

.introjs-tooltip {
  * {
    @extend .font-raleway;
  }

  @media screen and (max-width: 400px) {
    min-width: 200px !important;
    .introjs-button {
      width: 60px !important;
    }

    .tutorial-page & {
      min-width: 250px !important;

      .introjs-button {
        width: unset !important;
      }
    }
  }

  .introjs-tooltiptext {
    padding: 20px;
    @extend .font-raleway;
    font-weight: 500;
    font-size: 16px;
  }

  .introjs-tooltip-header,
  .introjs-tooltip-title {
    min-height: 1px;
  }

  .introjs-skipbutton {
    text-indent: 9999px;
    background-image: url(./../../../public/images/common/icon-close-red.svg);
    background-repeat: no-repeat;
    top: -20px;
    right: -20px;
  }

  @media screen and (max-width: 915px) {
    .introjs-skipbutton {
      text-indent: 0px;
      background: none;
      top: -15px;
      right: 0px;
      width: 25px;
      height: 25px;
      object-fit: fill;
      background-size: cover;
      scale: 1.5;
    }
  }




  .introjs-tooltipbuttons {
    padding: 10px 15px;
  }

  .introjs-button {
    @extend .btn;
    font-weight: normal;
    text-transform: none;
    width: 80px;
    padding: 0 10px;
    height: 35px;
    font-size: 15px;
    border-radius: 8px;

    &::before {
      display: none;
    }

    .tutorial-page & {
      width: unset;
    }

    &.introjs-hidden {
      display: none !important;
    }
  }

  .introjs-disabled {
    opacity: 0.5;
  }

  .introjs-prevbutton {
    color: #175EBE !important;
    background-color: #fff !important;
    border: 1px solid #175EBE !important;
    box-shadow: none !important;
  }

  .introjs-nextbutton {
    @extend .btn-success;
    border: 1px solid #175EBE !important;
    background: rgb(0, 102, 255) !important;
  }


  .introjs-tooltipbuttons {
    border-top: none
  }

  .introjs-button.introjs-disabled {
    opacity: 1;
  }

  .introjs-bullets ul li a {
    background: #175EBE;
    opacity: 0.3;
    border-radius: 3px;
    width: 15px;
    height: 2px;
    margin: 0 3px;

    &.active,
    &:hover {
      opacity: 1;
      background: #175EBE;
    }
  }
}

.table-line {
  padding: 10px 20px;
  @extend .font-raleway;
  font-weight: 500;
}

.table-line-user {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0;
}

.table-line-action {
  flex: 1;
  padding-right: 0;
}

.table-detail-bg .table-item {
  &:nth-child(2n) {
    background-color: #f7f7f9;
  }
}

.table-logo-Burraco,
.table-logo-Scala40 {
  position: absolute;
}

.table-Burraco,
.table-Scala40 {

  >.table-logo-Burraco,
  >.table-logo-Scala40 {
    display: none;
  }
}

.rule-game {
  p {
    margin-bottom: 15px;
  }

  .hearts,
  .diamonds,
  .clubs,
  .spades {
    font-size: 20px;
    line-height: 0.8rem;
  }

  .hearts,
  .diamonds {
    color: #f00;
  }

  .p-img {
    display: flex;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 15px;
    margin-left: 20px;

    li {
      list-style: initial;
    }

    ul {
      margin-top: 10px;

      li {
        list-style: circle;
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 20px;

    th {
      background-color: #cbcbcb;
      font-size: 14px;
    }

    td,
    th {
      // padding: 0.75rem;
      padding: 10px 20px;
      vertical-align: middle;
      border: 1px solid #dee2e6;
    }

    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
    }
  }

  a {
    color: #0E345E;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  .tutorial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
    gap: 16px;
    background-color: #303843;
    color: #ffffff;

    .game {
      background: #ffffff;
      color: #303843;
      padding: 4px 16px;
      border-radius: 4px;
      font-size: 12px;
    }

    .title {
      font-size: 20px;
    }

    .start-btn {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.rule-tabs {
  font-size: 16px;
  line-height: 1.5;
  @extend .font-raleway;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding-top: 60px;
  }

  @media screen and (max-width: 576px) {
    max-height: inherit !important;
  }

  .ant-tabs-nav {
    background-color: #f1f2fb;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d9d9d9;

    .ant-tabs-tab {
      width: 100%;
      padding: 0;

      @media screen and (min-width: 576px) {
        width: 270px;
      }
    }

    .ant-tabs-tab-btn {
      padding: 0 15px 0 24px;
      height: 45px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .ant-tabs-tab,
    .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 14px;

      +.ant-tabs-tab {
        margin-top: 5px;
      }
    }

    .ant-sub-tabs-list {
      overflow-y: auto;
      height: calc(100% - 50px);
      overflow-x: hidden;
    }

    .ant-tabs-tab-active {
      background-color: transparent;

      >.ant-tabs-tab-btn {
        color: #fff;
        background-color: #27347f;
      }
    }

    .ant-tabs-tab:hover:not(.ant-tabs-tab-active) {
      background-color: transparent;

      >.ant-tabs-tab-btn {
        color: #fff;
        background-color: #3F51B5;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-content-holder {
    border: none;
    flex: 1;
  }

  .ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
    padding-left: 15px;

    @media screen and (min-width: 576px) {
      padding-left: 40px;
    }
  }

  .ant-tabs-nav {
    @media screen and (max-width: 576px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      bottom: 0;
      overflow-y: auto;
      z-index: 9999;
      background-color: #fff;
      padding: 50px 10px 10px 10px;

      &.active {
        display: block;
      }
    }
  }

  .rule-game {
    @media screen and (max-width: 576px) {
      max-height: inherit !important;
    }
  }
}

.ant-tabs-nav-wrap,
.ant-tabs-nav-list {
  .mobile & {
    height: 100%;
  }
}

.sub-tabs-list {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #f1f2fb;
  transition: right 0.3s ease-in-out;

  &.show {
    right: 0;
    z-index: 99;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #0E345E;
}

.img-emoji {
  width: var(--player-emoji-width, 50px);
  position: absolute;
}

.player-msg {
  position: absolute;
  background: #fff;
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid;
  white-space: nowrap;
  z-index: 999;
  .mobile & {
    font-size: 8px;
  }
}

.Scopa,
.Cirulla,
.Bestia .table-result-head {
  padding: 0 7px;
}

.modal-full {
  .MuiDialog-container {
    align-items: baseline;
  }

  .MuiDialog-paper {
    width: 100%;
    max-width: 1000px;
  }
}

.modal-confirm {
  white-space: pre-line;
  .MuiDialog-paper {
    width: 100%;
  }

  .btn {
    text-transform: none;
  }
}

.form-group {
  +.form-group {
    margin-top: 15px;
  }
}

.ant-checkbox-wrapper {
  font-size: 15px;
}

.options-btn-group * {
  text-transform: none;
}

.ant-select-dropdown {
  z-index: 9999;
}

.options-item {
  @media screen and (min-width: 1024px) {
    flex: 1;
  }
}

%bg-game {
  background-image: url('./../../../public/images/common/bg-mobile.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 576px) {
    background-image: url('./../../../public/images/common/bg-new.webp');
  }
}

.bg-game-ItalianPoker,
.bg-game-Telesina,
.bg-game-Scala40,
.bg-game-Burraco,
.bg-game-Solitario {
  background-color: #114443;
}

.bg-game-Scopa,
.bg-game-Scopone,
.bg-game-Briscola,
.bg-game-Tresette,
.bg-game-Peppa,
.bg-game-Bestia,
.bg-game-Cirulla,
.bg-game-AssoPigliaTutto,
.bg-game-SetteEMezzo,
.bg-game-Trentuno,
.bg-game-GinRummy {
  @extend %bg-game;
}

.Confirm-btn-group {
  flex-wrap: wrap;
}

.format-s {
  font-size: 22px;

  @media screen and (min-width: 1024px) {
    font-size: 26px;
  }
}

.bg-game-Scopa,
.bg-game-AssoPigliaTutto {
  .header-mobile {
    @media screen and (orientation: portrait) {
      margin-top: 15px;
    }
  }
}

.bg-game-Cirulla {
  .header-mobile {
    @media screen and (orientation: portrait) {
      margin-top: 15px;
    }
  }
}

.bg-game-Scopone {
  .header-mobile {
    @media screen and (orientation: portrait) {
      margin-top: 20px;
    }
  }
}

.bg-game-Briscola {
  .header-mobile {
    margin-left: 16px;
    margin-top: 3px;

    @media screen and (orientation: portrait) {
      margin-top: 16px;
      margin-left: 2px;
    }
  }
}

.bg-game-Tresette {
  .header-mobile {
    margin-left: 16px;
    margin-top: 3px;

    @media screen and (orientation: portrait) {
      margin-top: 16px;
      margin-left: 2px;
    }
  }
}

.chat-desktop {
  z-index: 1100 !important;

  .MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.38);
    width: 100%;
    top: auto !important;
    max-width: 300px;
    left: auto;
    right: 16px;
    height: var(--chat-desktop-height, 340px);
    bottom: 155px;

    @include respond-to(576px) {
      left: 7px !important;
      right: 7px;
      max-width: 97%;
    }
  }
  &.desktop {
    @media screen and (max-height: 500px) {
      --chat-desktop-height: calc(100vh - 160px);
    }
  }
}

.ant-tabs {
  display: flex;
}

.ant-tabs-nav-list {
  flex-direction: column;
}

.ant-tabs-nav {
  width: auto;
}

.ant-tabs-nav-operations {
  display: none;
}

.sub-tab-list {
  padding: 0;
  margin-top: 8px;
}

.sub-tab-list-item {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: left;
  transition: none;
}

.sub-tab-list-item a {
  display: block;
  padding: 8px 20px;
  border-bottom: 1px solid #f0f0f0;
  color: #333;
}

.sub-tab-list-item a:hover {
  background-color: #f0f0f0;
}

.search-form {
  input {
    height: 100%;
    padding: 0 14px;
    background: transparent !important;
  }
}

.search-clear-icon {
  position: absolute !important;
  top: 1px;
  right: 40px;
  background-color: var(--background-header-tabs, #0E345E) !important;
  width: 34px;
  height: 34px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rule-details-italianPoker,
.rule-details-telesina {
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.20);
  color: #fff;
  display: flex;
  gap: 7px;
  flex-direction: column;
  font-family: 'Raleway';
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  min-width: 144px;
  padding: 10px;
  font-size: 14px;
  left: 0;
  bottom: 0;

  .mobile & {
    @media screen and (orientation: portrait) {
      bottom: auto;
      left: auto;
      top: 0;
      right: 0;
      display: block;
      text-align: right;
      font-size: 10px;
      line-height: 12px;
      min-width: auto;
      background-color: transparent;
      padding: 0;
    }

    @media screen and (orientation: landscape) {
      right: 0;
      bottom: 84px;
      left: auto;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 10px;
      gap: 5px;
      min-width: 115px;
    }
  }
}

.regulation-top {
  border-radius: var(--border-radius-regulation-top, 8px);

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .regulation-item {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    padding: 0 10px;

    &:first-of-type {
      border-top-left-radius: var(--border-radius-regulation-top, 8px);
      border-bottom-left-radius: var(--border-radius-regulation-top, 8px);
    }

    &:last-of-type {
      border-top-right-radius: var(--border-radius-regulation-top, 8px);
      border-bottom-right-radius: var(--border-radius-regulation-top, 8px);
    }

    +.regulation-item {
      border-left: 1px solid #bfbfbf;
    }

    .mobile & {
      font-size: 10px;
      padding: 0 4px;
    }
  }

  @media screen and (max-width: 1024px) {
    --border-radius-regulation-top: 0
  }
}


.table-result-box {
  width: 300px;
  border-radius: 16px;
  position: fixed;
  z-index: 9999;
  overflow: hidden;

  .Burraco &,
  .Scala40 & {
    &:not(.score-match-box) {
      background-color: #103939;
    }
  }

  .mobile & {
    @media screen and (orientation: landscape) {
      transform-origin: top left;
      transform: scale(0.65) translate(-50%, -50%);
    }
  }
  .table-result-head {
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.score-match-box {
  z-index: 10002;
  max-width: 500px;
  width: 100% !important;
  border: 1px solid #fff;
  background-color: #fff !important;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Raleway', sans-serif !important;

  .mobile & {
    @media screen and (orientation:landscape) {
      transform: scale(0.5) translate(-50%, -50%);
      transform-origin: top left;
    }

    @media screen and (orientation: portrait) {
      button+button {
        margin-top: 5px;
      }
    }
  }

  .score-match-top {
    padding: 6px 0 12px 0;
    font-size: 16px;
    font-weight: 700;
  }

  .score-match-win {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-score-match-win, #0E345E);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px;
    gap: 10px;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  .score-match-vince {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:empty) {
      height: 46px;
    }
  }

  .score-match-win-images {
    height: 30px;
  }

  .score-match-win-text {
    text-transform: uppercase;
  }

  .score-match-bottom {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 10px 15px;
  }

  .score-match-btn-next {
    margin-top: 10px;
    flex: 1;
    font-size: 14px !important;
  }

  .score-match-btn-exit {
    flex: 1;
    font-size: 15px !important;
  }

  .score-match-main {
    padding: 10px 20px 20px 20px;
    .mobile & {
      @media screen and (orientation: portrait) {
        padding: 0;
      }
    }
  }

  .rematch-message {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 5px;
    .mobile & {
      @media screen and (orientation: portrait) {
        padding: 0;
      }
    }
  }

  .rematch-coundown {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    .mobile & {
      @media screen and (orientation: portrait) {
        margin-bottom: 5px;
      }
    }
  }

  .rematch-btn-revenge {
    font-size: 15px !important;
    width: 200px;
    text-transform: uppercase;
  }

  .rematch-confirm {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: baseline;
    justify-content: center;
  }
}

.card-counter {
  color: #fff;
  background-color: #092020;
  border-radius: 99px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Inter';

  &.mobile {
    width: 20px;
    height: 20px;
    font-size: 8px;
    line-height: 9.6px;
  }
}

#deck-pile-hightlight {
  position: absolute;
  bottom: var(--deck-pile-hightlight-bottom, -4px);
  left: var(--deck-pile-hightlight-left, 130px);
  top: var(--deck-pile-hightlight-top);
  width: var(--deck-pile-hightlight-width, 140px);
  height: var(--deck-pile-hightlight-height, 95px);
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0 0 0 4px var(--box-shadow-color);
}

.deck-pile-hightlight {
  animation: blinking 2s infinite;
}

.question-icon {
  .mobile & {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.badge-icon {
  width: var(--badge-icon-width, 40px);
  height: var(--badge-icon-width, 40px);
  min-width: var(--badge-icon-width, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-image: url(../../../public/images/badge/point.png);
  background-size: contain;
  background-repeat: no-repeat;
  font-weight: 700;
  font-size: 11px;
  background-position: center;

  @include respond-to(576px) {
    --badge-icon-width: 25px;
    background-size: contain;
  }
}

.badge-token {
  width: 35px;
  height: 35px;
  scale: 0.8;
}

.container-lobby,
.container-regulation {
  width: 100vw;
  height: 100vh;

  [id^="in-game"] {
    display: none;
  }


  .paragraph {
    img {
      display: inline;
    }
  }

  .titolo1 {
    width: auto;
    height: 30px;
    font-size: 24px;
  }

  .titolo2 {
    font-size: 20px;
  }

  .titolomenu {
    color: #f39c28;
    min-height: 40px;
    margin-left: 3px;
    text-align: center;
    font: bold 18px Arial;
    border-bottom: 2px solid #CCCCCC;

  }

  .titolomenu2 {
    color: #f39c28;
    /* width: 230px; */
    height: 35px;
    margin-left: 3px;
  }

}

.chat-content {
  height: calc(100vh - 148px) !important
}



.container-regulation {
  &.mobile {
    .button-chat {
      display: none;
    }
  }
}

.mobile {
  @media screen and (orientation: portrait) {
    --deck-pile-hightlight-top: 302px;
    --deck-pile-hightlight-left: 127px;
    --deck-pile-hightlight-width: 98px;
    --deck-pile-hightlight-height: 68px;
  }

  @media screen and (orientation: landscape) {
    --deck-pile-hightlight-top: 255px;
    --deck-pile-hightlight-left: 73px;
    --deck-pile-hightlight-width: 98px;
    --deck-pile-hightlight-height: 68px;
  }
}

.card,
.touch-callout,
.chip-item,
.player-dealer,
.player-sort-suit span,
.player-sort-value span {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

.game-tooltip {
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  border-radius: 8px;
  padding: 13.5px 31.5px 13.5px 18.5px;
  width: 300px;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.38);
  border: 1px solid #ddd;
  // @extend .font-raleway;


  .mobile & {
    width: 200px;
    padding: 10px 30px 10px 10px;
  }

  &.confirm {
    padding-right: 18.5px;

    .mobile & {
      padding-right: 10px;
    }
  }

  &.completed {

    .close,
    .arrow {
      display: none;
    }
  }

  .arrow {
    position: absolute;
    display: none;
    width: 14px;
    height: 14px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    margin-top: -1px;
    animation: animationIntro 0.5s linear infinite;
    margin-left: -14px;

    &.bottom {
      bottom: 100%;
      border-bottom: 14px solid #fff;
    }

    &.top {
      top: 100%;
      border-top: 14px solid #fff;
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/images/common/close.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &.confirm {

    .close,
    .arrow {
      display: none;
    }

    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }

    .confirm-message {
      width: 100%;
      margin-bottom: 20px;

      .mobile & {
        margin-bottom: 10px;
      }
    }

    .cancel,
    .accept {
      width: 100px;
      height: 38px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;

      .mobile & {
        width: auto;
        height: 34px;
        font-size: 12px;
        flex: 1;
      }

      &:hover {
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.38);
      }
    }

    .cancel {
      @extend .btn-warning;
    }

    .accept {
      @extend .btn-primary;
    }
  }

}

.hide-tip {
  display: none;
}

@keyframes animationIntro {
  from {
    border-top-color: red;
    margin-top: 0;
  }

  to {
    border-top-color: yellow;
    margin-top: 2px;
  }
}

.success-animation {
  margin: 10px auto;
}

.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .75s ease-in-out .75s forwards, scale .5s ease-in-out 1s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.border-active {
  &::before {
    content: '';
    left: -4px;
    right: -4px;
    bottom: -4px;
    top: -4px;
    border-width: 2px;
    border-style: solid;
    position: absolute;
    display: block;
    border-radius: 6px;
    animation: animationTooltip 1s linear infinite;
  }
}

@keyframes animationTooltip {
  from {
    border-color: transparent;
  }

  to {
    border-color: #FDE82F;
  }
}

.modal-confirm-subcribe {
  .MuiDialog-paper {
    width: 100%;
    max-width: 420px;
  }
}

.modal-token {
  .MuiDialog-paper {
    width: 100%;
    max-width: 820px;
  }
}

.font-inter {
  font-family: "Inter";
}

.modal-suggest-card {
  .suggest-content {
    flex-wrap: wrap;

    @media screen and (orientation: portrait) {
      align-items: baseline;
    }
  }

  .MuiDialog-paper {
    max-width: 850px;
  }

  .text-or {
    @media screen and (orientation: portrait) {
      width: 100%;
      text-align: center;
      padding: 20px;
    }
  }
}

.Scopa {
  .game-tooltip {
    display: none;
  }

  .border-active {
    &::before {
      border: none;
    }
  }
}

.modal-disconnected {
  .MuiDialog-paper {
    max-width: 500px;

    p {
      line-height: 1.4;
    }
  }
}

.colpo-grosso-info {
  position: absolute;
  color: #fff;
  background-color: rgba($color: #000000, $alpha: 0.4);
  font-weight: 600;
  font-family: "Raleway";

  .desktop & {
    top: 0;
    right: 0;
    font-size: 16px;
    padding: 15px;
    border-radius: 12px;
  }

  .mobile & {
    @media screen and (orientation: landscape) {
      top: 10px;
      right: 10px;
      font-size: 14px;
      padding: 15px;
      border-radius: 12px;
    }

    @media screen and (orientation: portrait) {
      font-size: 10px;
      top: 30px;
      right: 5px;
      padding: 5px;
      border-radius: 8px;
    }
  }

  p+p {
    margin-top: 5px;
  }

  span+span {
    font-weight: 700;
    margin-left: 10px;
    text-transform: uppercase;
  }

}

.modal-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);

  .modal-main {
    @media screen and (max-width: 950px) {
      padding: 10px;
    }
  }
}

.modal-main {
  color: #fff;
  // width: 500px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .modal-header {
    margin: 0;
    line-height: 22px;
    padding: 16px 24px;
    flex: 0 0 auto;
    background: #0E345E;
    color: #fff;
    padding: 10px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    h2 {
      font-size: 18px;
    }
  }

  .modal-body {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    padding: 0px;
    background-color: #fff;
    color: #000;
    padding: 20px;
    line-height: 1.4;
    font-size: 16px;

  }

  .button-gioca {
    border: 1px solid #175EBE;
    padding: 5px 30px;
    border-radius: 4px;
    background-color: #175EBE;
    color: #fff;
    font-family: "Raleway";
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
  }

  .button-close {
    position: absolute;
    top: 5.5px;
    right: 5px;
    width: 35px;
    height: 35px;
    background-color: #CD1B1B;
    border-radius: 50%;

    &:hover {
      background-color: #d82323;
    }

    &::before {
      content: '';
      filter: brightness(0) invert(1);
      background-image: url('../../../public/images/common/close.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .modal-body {}
}

.main-container-lobby {
  .desktop & {
    overflow-y: auto;
    height: calc(100vh - 290px);
    margin-top: 5px;
  }
}

.main-container-lobby-banner {
  .desktop & {
    overflow-y: auto;
    height: calc(100vh - 370px);
    margin-top: 5px;
  }
}

.btn-disabled {
  color: #9EA8B4 !important;
  background-color: #F0F1F3 !important;
  text-transform: uppercase !important;
  pointer-events: none;
}

.header-bar {
  height: var(--header-bar-height, 44px);
  padding: var(--header-bar-padding, 5px) !important;
  display: inline-flex;
  align-items: center;
  svg {
      width: 28px;
      height: 28px;
  }
}

.container-game-Scala40,
.container-game-Burraco {
  .tournament-information {
    background-color: #103939;
  }
  &.mobile {
    --header-bar-padding: 0 5px 0 0;
    --header-bar-height: auto;
    .header-mobile {
        padding-bottom: 0;
    }
  }
}

.score-match-table {
  h4 {
    display: none;
  }
}

.score-match-coundown {
  margin: 10px;
  font-weight: 500;

  span {
    font-weight: 700;
    font-size: 20px;
  }
}

.score-match-vince-text {
  text-transform: initial;
}

.rematch-confirm {
  margin-bottom: 15px;
}

.end-session-dialog {
  .end-session-count-down {
    font-size: 16px;
    margin: 15px 0px;
  }
  .end-session-dialog-button {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
}

.iframe-game-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.options-list {
  width: 100%;
}

h4 {
  @include respond-to(360px) {
    font-size: 18px !important;
  }
}

.tour-header {
  background-color: #0066FF;
  color: #fff;
  font-size: var(--title-font-size, 16px);
  font-weight: 600;
  padding: 0 var(--padding-x, 18px);
  height: var(--height-title, 35px);
  display: flex;
  align-items: center;
  overflow: auto;
  justify-content: center;
  padding: 0 !important;
  &::before {
    content: "Tutorial";
  }
  .introjs-tooltip-title {
    display: none;
  }
}

.mobile:not(.container-lobby).container-game-Scopa {
  @media screen and (orientation: portrait) {
      --button-action-bottom: 30px;
  }
}