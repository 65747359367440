.welcome-user-modal {
    --max-width-paper: 440px !important;

    .modal-main {
        padding: 17px 17px 0 17px;
    }

    .modal-footer {
        .modal-button {
            --button-large-height: 40px;
            --button-large-min-width: 100%;
            --button-large-font-size: 16px;
            font-weight: 500;
        }
    }

    .modal-content {
        @media screen and (max-width: 576px) {
            justify-content: flex-start;
        }
    }

    .Mui-error {
        display: none;
    }
}

.welcome-user-form {
    width: 90%;
    margin-top: 20px;

    .MuiOutlinedInput-root {
        width: 100%;
        height: 40px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }

    input {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        text-align: center;
    }

    .progress svg{
        height: 32px;
        width: 32px;
    }
}

.welcome-user-note {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 8px 0;
    color: #8E8E8E;
}

.welcome-user-logo {
    margin-bottom: 16px;
}

.welcome-user-logo-images {
    max-height: 32px;
}

.welcome-user-avatar-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    position: relative;
    margin-top: var(--welcome-user-avatar-title-margin-top ,16px);
    padding-top: var(--welcome-user-avatar-title-padding-top ,16px);

    &::before {
        content: '';
        position: absolute;
        background-color: #D2D2D3;
        top: var(--welcome-user-avatar-title-top , 0);
        left: var(--welcome-user-avatar-title-left , 50%);
        transform: var(--welcome-user-avatar-title-left , translateX(-50%));
        height: var(--welcome-user-avatar-title-height , 1px);
        width: var(--welcome-user-avatar-title-width , 180px);
    }
}

.welcome-user-left {
    width: var(--welcome-user-left-width, 100%);
    margin: 0 auto;
}

.welcome-user-avatar-list {
    display: grid;
    justify-content: space-between;
    grid-row-gap: 8px;
    grid-template-columns: var(--welcome-user-avatar-list-grid-template-columns, repeat(6, 60px));
    height: var(--welcome-user-avatar-list-height, auto);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 8px;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    @media screen and (max-width: 576px) {
        --welcome-user-avatar-list-grid-template-columns: repeat(4, 60px);
        max-width: 264px;
        margin: 0 auto;
    }
    .mobile-landscape & {
        --welcome-user-avatar-list-grid-template-columns: repeat(4, 60px);
        max-width: 264px;
        margin: 0 auto;
    }
}

.welcome-user-avatar-container {
    flex: 1;
}

.welcome-user-main {
    display: flex;
    flex-direction: var(--welcome-user-main-flex-direction, column);
}

.welcome-user-avatar-item {
    width: var(--welcome-user-avatar-item-width, 60px) !important;
    height: var(--welcome-user-avatar-item-width, 60px) !important;
}

.welcome-user-error {
    color: #E20613;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
}

.welcome-user-valid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #008926;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
}

.logo-1013 {
    height: auto;
}