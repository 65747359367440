@import '../../assets/styles/common/mixin';

.root-container {
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: var(--root-container-height, 100vh);
    min-height: 100vh;
    position: relative;

    @include respond-to(800px) {
        &.container-lobby {
            --root-container-height: auto;
            overflow: inherit;
        }
    }

    &.mobile {
        &.container-lobby {
            height: auto;
            overflow: inherit;
            min-height: 100vh;
        }
    }
}

.tournament-container {
    border-radius: var(--tournament-container-border-radius, 10px);
    position: relative;
    border: 0.5px solid var(--color-boder, #D2D2D3);
    box-shadow: var(--tournament-container-box-shadow, 6px -8px 20px 0px rgba(0, 0, 0, 0.15), -6px 8px 20px 0px rgba(0, 0, 0, 0.15));

    @media screen and (min-width: 1200px) {
        max-width: 100%;
    }
}

.main-page-game {
    height: calc(100vh - 60px);
    margin-top: 60px;
}

.main-content {
    flex: 1;
    overflow: hidden;

    .mobile-landscape & {
        padding-top: 85px;
    }
}

.tournament-container,
.tournament-table-container {
    overflow: hidden;
    height: var(--tournament-container-height, 100%);

    @include respond-to(800px) {
        --tournament-container-overflow-y: inherit !important;
        --tournament-container-height: auto;
    }
}

.main-content,
.main-container {
    overflow-y: var(--tournament-container-overflow-y, auto);
    height: var(--tournament-container-height, 100%);

    @include respond-to(800px) {
        --tournament-container-overflow-y: inherit !important;
        --tournament-container-height: auto;
    }
}

.main-container {
    .mobile.container-lobby & {
        @media screen and (orientation: portrait) {
            padding-bottom: 75px;
        }
    }
}

.header-box {
    transition: all 0.3s;

    .mobile & {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }

    @include respond-to(800px) {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }

    .mobile-landscape & {
        height: 95px;
    }
}

.container-lobby {
    .button-action,
    .chat-button {
        @include respond-to(800px) {
            --button-action-bottom: 80px;
        }
    }

    &.mobile {
        @media screen and (orientation: landscape) {
            .button-action,
            .chat-button {
                --button-action-bottom: 21px;
            }
        }
    }
    
}

.root-container-left:not(.container-game) {
    padding-left: 100px;

    .button-action {
        left: 125px;
    }

    .chat-button {
        right: 25px;
    }
}

.root-container-right:not(.container-game) {
    padding-right: 100px;

    .button-icon {
        left: 25px;
    }

    .chat-button {
        right: 125px;
    }
}