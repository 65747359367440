.Scopone {
    --card-width: 80px;
    --card-height: 132px;
    --my-card-width: 80px;
    --my-card-height: 132px;
    --oppo-card-width: 56px;
    --oppo-card-height: 95px;

    .mobile & {
        --card-width: 42.93px;
        --card-height: 70px;
        --oppo-card-width: 21px;
        --oppo-card-height: 37px;
        --my-card-width: 42.93px;
        --my-card-height: 70px;

        @media (orientation: portrait) {
            --my-card-width: 37px;
            --my-card-height: 60px;
        }
    }
}