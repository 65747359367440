.colpo-grosso-container {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: fixed;
    top: var(--colpo-grosso-container-top, 60px);
    left: 0;
    background-image: var(--colpo-grosso-container-background-image, url("../../../../public/images/colpo-grosso/background-animation-colpoGrosso.webp"));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Riffic Free Bold';

    .colpo-grosso-wrapper {
        position: absolute;
    }

    .colpo-grosso-board {
        position: relative;
        height: 100%;
        width: 100%;
        animation: showAni 2s linear;
    }

    .card-container {
        width: var(--card-container-width, 272px);
        height: var(--card-container-height, 404px);
        position: absolute;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        display: none;
        opacity: 0;
        perspective: 1000px;
        border-radius: var(--card-container-border-radius, 18px !important);
    }

    .card-back {
        background-image: url('../../images/card-up/card-back.webp');
        background-size: contain;
    }

    .card-front {
        transform: rotateY(180deg);
        background-color: #fff;
        flex-direction: column;
        padding: var(--card-front-padding, 30px 0);
        color: #314776;
        font-size: var(--card-front-font-size, 16.67px);
    }

    .card-back,
    .card-front {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        border-radius: var(--card-front-border-radius, 18px);

        &::before {
            content: '';
            position: absolute;
            top: var(--card-front-before-top, 14px);
            right: var(--card-front-before-top, 14px);
            left: var(--card-front-before-top, 14px);
            bottom: var(--card-front-before-top, 14px);
            border: var(--card-front-before-border, 4px) solid #314776;
            border-radius: var(--card-front-before-border-radius, 30px);
            display: block;
        }
    }
}

.front-text {
    -webkit-text-stroke: var(--front-text-stroke, 3px) #944900;
    color: #fdff50;
    font-size: var(--front-text-font-size, 70.83px);
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        color: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::before {
        text-shadow: var(--front-text-text-shadow, 0 9px 3px #322200, -3px 9px 3px #322200);
        z-index: 0;
    }

    &::after {
        background: linear-gradient(180deg, #fdff50 0%, #ffcc00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 2;
    }
}


.card-animation-board {
    position: relative;
    width: 100%;
    height: 100%;
}

.card-choose {
    width: var(--card-choose-width, 857px);
    height: var(--card-choose-height, 126px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--card-choose-font-size, 75px);
    opacity: 0.5;
    color: #fff;
    border: var(--card-choose-border-width, 12px) solid #fff;
    text-transform: uppercase;
    position: absolute;
    top: var(--card-choose-top, 797px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border-radius: var(--card-choose-border-radius, 20px);
}

.mobile {
    @media screen and (orientation: portrait) {
        --colpo-grosso-container-top: 0;
        --colpo-grosso-container-background-image: url("../../../../public/images/colpo-grosso/ColpoGrosso-portrait.webp");
        --card-container-width: 98px;
        --card-container-height: 146px;
        --card-container-border-radius: 7px !important;
        --card-front-padding: 15px 0;
        --card-front-font-size: 6px;
        --card-front-border-radius: 7px;
        --card-front-before-top: 5px;
        --card-front-before-border-radius: 10.5px;
        --front-text-stroke: 1.5px;
        --card-front-before-border: 1.5px;
        --front-text-font-size: 25px;
        --front-text-text-shadow: 0 3px 1px #322200, -1px 3px 1px #322200;
        --card-choose-width: 275px;
        --card-choose-height: 41px;
        --card-choose-font-size: 24px;
        --card-choose-border-width: 4px;
        --card-choose-top: 692px;
        --card-choose-border-radius: 8px;
    }
}