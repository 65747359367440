.Trentuno {
    --card-width: 95px;
    --card-height: 153px;
    --table-bottom: 0;
    --main-table-height: calc(100% - 90px);
    --deck-after-font-size: 17px;
    --deck-pile-hightlight-top: 244px;
    --deck-pile-hightlight-left: 516px;
    --deck-pile-hightlight-width: 95px;
    --deck-pile-hightlight-height: 153px;
    --number-deck-font-size: 3rem;
    --deck-gap: 0;

    .mobile & {
        --card-width: 55.5px;
        --card-height: 89px;
        --deck-pile-hightlight-width: 55.5px;
        --deck-pile-hightlight-height: 89px;

        .btn {
            height: 30px;
        }

        @media screen and (orientation: portrait) {
            --table-bottom: 135px;
            --info-table-box-top: -30px;
            --info-table-box-right: 0;
            --deck-pile-hightlight-top: 270px;
            --deck-pile-hightlight-left: 142px;
        }

        @media screen and (orientation: landscape) {
            --deck-pile-hightlight-top: 118px;
            --deck-pile-hightlight-left: 289px;

            .table-button-list {
                margin-right: -46%;
            }

            .trentuno-table {
                transform: none;
            }
        }
    }

    .overlay {
        background-color: rgba(3, 3, 3, 0.50);
    }
    .discardReload {
        .mobile & {
            @media screen and (orientation: landscape) {
                top: 118px;
                left: 289px;
                transform: none !important;
            }
            @media screen and (orientation: portrait) {
                top: 270px;
                left: 142px;
                transform: none !important;
            }
        }
    }
}

.deck-empty {
    border: 2px solid #fff;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .3);
}

.trentuno-scoring-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.trentuno-scoring-text {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

.trentuno-scoring {
    z-index: 9999;
}

.trentuno-deck {
    position: absolute;
    top: var(--trentuno-deck-top, 244px);
    left: var(--trentuno-deck-left, 617px);
    z-index: 0;
}

.player-point {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
    border: 1px solid #1A4C2F;
    background: #008C3B;
    color: #fff;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    z-index: 200;
}

.trentuno-table {
    background-color: var(--trentuno-table-background-color, rgba(10, 32, 32, 0.2));
    right: var(--trentuno-table-right, 0);
    bottom: var(--trentuno-table-bottom, 0);
    top: var(--trentuno-table-top);
    width: var(--trentuno-table-width, 195px) !important;
    position: var(--trentuno-table-position, fixed) !important;
}

.mobile {
    --trentuno-table-position: absolute;
    --trentuno-table-top: 0;
    --trentuno-table-bottom: auto;
    --trentuno-table-right: 50px;
    --trentuno-number-deck-font-size: 28px;
    --number-deck-top: 20px;

    .Trentuno {
        --deck-after-font-size: 9px;
    }

    .trentuno-table {
        backdrop-filter: blur(20px);
    }

    @media screen and (orientation: landscape) {
        --trentuno-deck-left: 348px;
        --trentuno-deck-top: 118px;
    }

    @media screen and (orientation: portrait) {
        --trentuno-deck-left: 142px;
        --trentuno-deck-top: 365px;
    }

    .trentuno-scoring {
        position: absolute;
        top: 0;
        right: 25px;
    }
}

.point-current-user,
.point-win,
.player-knock {
    position: absolute;
}

.point-win {
    color: #ffff00;
    font-weight: 800;
    font-size: 60px;
    z-index: 9999;
    font-family: 'Metropolis Black';
    line-height: 0.72;
    text-shadow: 0px 5px 2px rgba(125, 8, 22, 0.49), 0px 5px 0px #322200;
    width: 50px;
    height: 50px;
    align-items: flex-end;
    .number-image {
        height: var(--point-win-img-height);
        animation: pointWin 0.75s linear;
        + img {
            margin-left: -5px;
        }
    }
    .dot-image {
        height: var(--point-dot-img-height);
        animation: dotPointWin 0.75s linear;
    }
}

.scritte-images {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    margin-top: -3%;
    img {
        height: var(--scritte-images-height);
        animation: scritteAnimation 0.75s linear;
    }
}

:root {
    --point-win-img-height: 59px;
    --scritte-images-height: 233px;
    --point-dot-img-height: 25px;
}

.mobile {
    --point-win-img-height: 42px;
    --scritte-images-height: 150px;
}

.player-knock {
    z-index: 9999;
    animation: knockText 1s linear;
    width: var(--knock-text-width);
    height: var(--knock-text-height);
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9999;
    transition: width 1s ease, height 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
    }
}

@keyframes knockText {
    0% {
        --knock-text-width: 0;
        --knock-text-height: 0;
    }

    100% {
        --knock-text-width: var(--knock-text-width);
        --knock-text-height: var(--knock-text-height);
    }
}

@keyframes pointWin {
    0% {
        height: 0;
    }

    80% {
        height: calc(var(--point-win-img-height) * 1.25);
    }

    100% {
        height: var(--point-win-img-height);
    }
}

@keyframes dotPointWin {
    0% {
        height: 0;
    }

    80% {
        height: calc(var(--point-dot-img-height) * 1.25);
    }

    100% {
        height: var(--point-dot-img-height);
    }
}

@keyframes scritteAnimation {
    0% {
        height: 0;
    }

    100% {
        height: var(--scritte-images-height);
    }
}