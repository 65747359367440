.drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 5px;
    background-color: #0066FF;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    button {
        width: 35px;
        min-width: 35px !important;
        color: #fff;
        padding: 0;
    }

    .drawer-header-action {
        display: flex;
        align-items: center;
    }
}

.drawer-header-title {
    flex: 1;
    font-size: var(--font-size-16, 16px);
    font-weight: 600;
    color: #fff;
    padding-left: 13px;
    &:not(.text-left) {
        &.center {
            text-align: center;
            padding-left: 0;
        }
    }
    @media screen and (max-width: 576px) {
        --font-size-16: 20px;
    }
}

.drawer-container {
    box-shadow: 2px 4px 6.4px 5px rgba(0, 0, 0, 0.25);
}

.drawer-container-left {
    .MuiDrawer-paper {
        left: auto;
        right: 0;
    }
}

.drawer-container-right {
    .MuiDrawer-paper {
        left: 0;
        right: auto;
    }
}