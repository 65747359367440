

.lost-connection-container {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    .reconnect-container {
        display: flex;
        align-items: center;
        gap: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(33 33 33 / 80%);
        color: white;
        padding: 10px 20px;
        width: 80%;
        max-width: 500px;
    }
}