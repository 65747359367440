.crazy-prize-container {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: fixed;
    top: 60px;
    left: 0;

    .mobile & {
        top: 0;
    }

    .crazy-prize-wrapper {
        position: absolute;
    }

    .crazy-prize-board {
        position: relative;
        height: 100%;
        width: 100%;
        animation: showAni 2s linear;
    }

    .wheel,
    .wheel-container,
    .wheel-indicator,
    .wheel-light {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .wheel-container {
        background-image:
            url(./../../../../public/images/crazy-prize/inner-wheel.png),
            url(./../../../../public/images/crazy-prize/rear.png),
            url(./../../../../public/images/crazy-prize/base-1.png),
            url(./../../../../public/images/crazy-prize/base-2.png),
            url(./../../../../public/images/crazy-prize/base-3.png);
        z-index: 100;
    }

    .wheel-light {
        background-image:
            url(./../../../../public/images/crazy-prize/outer-light.png),
            url(./../../../../public/images/crazy-prize/rear-light.png);
        z-index: 101;
        animation: blinkAni 1s linear infinite;
    }

    .wheel-indicator {
        background-image: url(./../../../../public/images/crazy-prize/indicator.png);
        z-index: 102;
    }

    .wheel {
        background-image: url(./../../../../public/images/crazy-prize/wheel.png);
        z-index: 99;
        transform-origin: 50% calc(50% + 3px);

        .adjusted-line-height {
            line-height: 0.5;
            position: relative;
            top: -15px;
        }

        .prize {
            width: 50px;
            height: 150px;
            line-height: 1;
            letter-spacing: 6px;
            transform: translateX(-50%);
            transform-origin: top left;
            color: #ffff00;
            font-weight: 800;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #ffffff;
            font-family: 'Metropolis Black';
            line-height: 0.72;
            text-shadow: 0px 5px 2px rgba(125, 8, 22, 0.49), 0px 5px 0px #322200;
            -webkit-text-stroke-width: 0.5;
            -webkit-text-stroke-color: #944900;
            span {
                text-align: center;
                &:first-child {
                    font-size: 70px;
                }
                &:nth-child(2) {
                    font-size: 60px;
                } 
                &:nth-child(3) {
                    font-size: 50px;
                }
                &:nth-child(4) {
                    font-size: 40px;
                }
                &:nth-child(5) {
                    font-size: 35px;
                }
                &:nth-child(6) {
                    font-size: 30px;
                }
                &:nth-child(7) {
                    font-size: 25px;
                }
            }
        }
    }

    .final-prize {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        padding: 20px 50px;
        z-index: 199;
        background-color: #ff0303;
        border: 5px solid #ffffff;
        border-radius: 20px;
        font-size: 50px;
        font-weight: 800;
        align-items: center;
        justify-content: center;
        scale: 0;
        display: flex;
        color: #ffff00;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #ffffff;
        animation: borderBlinkAni 0.5s infinite;
        &::before {
            content: '€ ';
            font-weight: normal;
            padding-right: 8px;
        }
    }
}

@keyframes blinkAni {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

@keyframes showAni {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes borderBlinkAni {
    from {
        border-color: #ffffff;
        -webkit-text-stroke-color: #ffffff;
        color: #ffff00;
    }

    to {
        border-color: #ffff00;
        -webkit-text-stroke-color: #ffff00;
        color: #ffffff;
    }
}