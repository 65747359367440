@import '../../../assets/styles/common/mixin';

.match-switch-main {
    max-height: calc(100% - 40px);
    height: 100%;

    .MuiTableContainer-root {
        overflow-x: hidden;
    }
}

.match-switch {
    z-index: 1100 !important;

    .MuiPopover-paper {
        max-width: 600px;
        height: var(--match-switch-height, 292px);
        top: auto !important;
        bottom: var(--match-switch-bottom, 155px);
        width: 100%;

        @media screen and (max-width: 900px) and (orientation: landscape) {
            bottom: 85px;
        }

        @include respond-to(576px) {
            left: 7px !important;
            right: 7px !important;
            max-width: calc(100vw - 14px);
        }
    }

    &.desktop {
        @media screen and (max-height: 500px) {
            --match-switch-height: calc(100vh - 160px);
        }
    }
    .table-container tr th {
        span {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
        }
    }
}

.match-switcher-drawer {
    .MuiDrawer-paper {
        height: auto;
        bottom: 0; //135px;

        @include respond-to(576px) {
            bottom: 135px;
        }
    }

    &.mobile-landscape {
        z-index: 1100;

        .table-container tr {

            th,
            td {
                height: 50px;
            }
        }
    }
}

.button-action,
.chat-button {
    width: var(--button-action-width, 54px);
    height: var(--button-action-width, 54px);
    bottom: var(--button-action-bottom, 93px);

    .button-icon {
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.38);
    }
}

.container-game-Scala40, 
.container-game-Burraco {
    --button-action-bottom: 16px;
}

.mobile {
    --button-action-width: 40px !important;
}

.mobile:not(.container-lobby) {
    &.container-game-ItalianPoker,
    &.container-game-Telesina,
    &.container-game-Bestia {
        @media screen and (orientation: landscape) {
            --button-action-bottom: 17px;
        }

        @media screen and (orientation: portrait) {
            --button-action-bottom: 70px;
        }
    }

    &.container-game-Scopone,
    &.container-game-Tresette,
    &.container-game-AssoPigliaTutto,
    &.container-game-Cirulla,
    &.container-game-Peppa {
        @media screen and (orientation: landscape) {
            --button-action-bottom: 50px;
        }

        @media screen and (orientation: portrait) {
            --button-action-bottom: 44px;
        }
    }

    &.container-game-Scopa,
    &.container-game-Briscola {
        @media screen and (orientation: landscape) {
            --button-action-bottom: 36px;
        }

        @media screen and (orientation: portrait) {
            --button-action-bottom: 30px;
        }
    }
    
    &.container-game-Burraco,
    &.container-game-Scala40,
    &.container-game-Trentuno {
        @media screen and (orientation: landscape) {
            --button-action-bottom: 33px;
        }
        @media screen and (orientation: portrait) {
            --button-action-bottom: 33px;
        }
    }

    &.container-game-Solitario {
        --button-action-bottom: 16px;
    }
}

.container-game-Scopa,
.container-game-Briscola {
    --button-action-bottom: 16px;
}



.notification-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-timeout {
    width: var(--notification-timeout-width, 84px);
    height: var(--notification-timeout-height, 30px);
    border-radius: 999px;
    border: 1px solid #E20613;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: var(--notification-timeout-color, #101223);
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.notification-goLobby {
    padding: 10px;
}

.button-action-icon {
    &.close {
        &:hover {
            svg {
                color: #fff !important;
            }
        }

        svg {
            color: inherit;
        }
    }
}

.item-switcher {
    cursor: pointer;

    * {
        color: #666 !important;
    }

    &:hover {
        background-color: #666;
    }

    .table-container {
        overflow-x: hidden;
    }
}

.my-turn {
    * {
        font-weight: 700;
        color: var(--notification-timeout-color, #000) !important;
    }
}

.number-timeout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #E20613;

    +span {
        z-index: 9;
        position: relative;
    }
}

.match-switch-mobile-list {
    padding: 10px;
}

.match-switch-mobile-item {
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid var(--light-stroke, #D2D2D3);
    box-shadow: 4px 5px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 13px 10px;

    .match-switch-label {
        font-weight: 400;
    }

    .notification-timeout {
        width: 130px;
        height: 40px;
    }
}

.match-switch-table-header {
    border-bottom: 1px solid #d2d2d3;
    white-space: nowrap;
    font-size: var(--font-size-th, 16px);
    background-color: #f4f8f9;
    color: #101223;
    font-weight: var(--font-weight, 700);
    height: 45px;
}

.match-switch-desktop-item {
    font-size: var(--font-size, 14px);
    font-weight: 500;
    color: var(--match-switch-desktop-item-color, #666) !important;
    border-bottom: 1px solid #d2d2d3;
    height: 40px;
    &.active {
        --match-switch-desktop-item-color: #101223;
    }
}

.match-switch-table-header,
.match-switch-desktop-item {
    > span {
        padding: var(--padding, 9.5px 12px);
        display: inline-block;
    }
}

.match-switch-main,
.match-switch-table {
    .column-game {
        flex: 1;
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
    }
    .column-tournament {
        width: 17%;
    }
    .column-mode {
        width: 20%;
    }
    .column-cost {
        width: 22%;
    }
    .column-turn {
        width: 110px;
    }
}
