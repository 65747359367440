.stanleybet {
    --background-footer: #CD0000;
    --background-header-top: #3B3C43 ;
    --header-balance-background-color: #222;
    --background-header-slider: #222;
    --border-bottom-header-top: none;
    --background-header-tabs: #CD0000;
    --card-back-background-color: #3B3C43;
    --card-back-background-image: url('../../images/card-up/logo-stanleybet-png.webp');
    --card-back-background-size: 45%;
    &.container-game {
        --background-header-top: #CD0000;
    }
}