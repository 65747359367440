.classifiche-table-container {
    th {
        text-transform: uppercase;
    }
    td {
        font-family: 'Inter';
    }
}

.header-bar {
    height: var(--header-bar-height, 44px);
    padding: var(--header-bar-padding, 5px) !important;
    display: inline-flex;
    align-items: center;
    svg {
        width: 28px;
        height: 28px;
    }
}

.tabs-classifiche {
    .ant-tabs-content-holder {
        overflow: hidden;

    }
    .ant-tabs-content {
        height: 100%;
        width: 100%;
    }
}

.classifiche-table-container {
    max-height: calc(100vh - 200px);
}