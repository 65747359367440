.Scala40 {
    --list-meld-height: 435px;
    --card-back-border-radius: 4px;
    --card-width: 57.333px;
    --card-height: 86px;
    &.table-Scala40 {
        .mobile & {
            margin-bottom: 0;
            --table-round-height: calc(100% - 20px);

            @media screen and (orientation: portrait) {
                margin-top: 10px;
            }
        }
    }

    .my-card,
    .oppo-card {
        .desktop & {
            box-shadow: 0 0 5px black;
        }
    }

    .mobile & {

        .result-you,
        .result-me {
            font-size: 8px;
        }

        @media screen and (orientation: landscape) {
            .result-you {
                left: 5px;
            }

            .result-me {
                right: 5px;
            }

            .result-you,
            .result-me {
                bottom: 2px;
                width: 310px;
            }
        }
    }
}

.scala40-deck {
    position: absolute;
    bottom: 0;
    left: 137px;
    display: flex;

    .mobile & {
        scale: 0.6976744186046512;

        @media screen and (orientation: landscape) {
            left: 68px;
            bottom: 9px;
        }

        @media screen and (orientation: portrait) {
            left: 123px;
            top: 293px;
            bottom: auto;
        }
    }
}

.player-sort-box {
    position: absolute;
    display: flex;
    z-index: 200;
}

.bg-game-Scala40 {
    .header-mobile {
        .mobile & {
            @media screen and (orientation: portrait) {
                margin-top: 15px;
            }
        }
    }
}

.Scala40,
.Burraco {
    .table-result-content {
        padding: 10px 5px;
        flex-direction: column;
        gap: 8px;
        display: flex;

        >div {
            width: 100%;
            line-height: 17px;
        }
    }
}