.filter-item-label {
    color: var(--filter-item-label-color, #8E8E8E);
    &.checked {
        opacity: 1;
        color: var(--filter-item-label-color-active, #000);
        svg {
            color: #0066FF;
        }
    }

    span, p {
        color: inherit;
    }
}