.club-invitation-user-modal {
    p {
        font-size: 16px;
        font-weight: bold;
    }
    .time-out {
        font-size: 16px;
        color: #CCFF00
    }
}

.modal-club-canceled {
    p {
        font-weight: bold;
        font-size: 16px;
    }
}

.modal-club-disclaimer {
    p {
        font-size: 16px;
        font-weight: bold;
        margin: 8px 0;
    }
}