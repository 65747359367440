$deck-w-sette: 63px;
$deck-h-sette: 111px;
$deck-w-sette-mb: 38px;
$deck-h-sette-mb: 62px;

.SetteEMezzo {
    .mobile & {
        --player-content-width: 84px;
        --player-content-padding-right: 4px;
    }
    &.table-SetteEMezzo {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            width: auto;
            height: auto;
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
        }
    }

    .deck {
        width: $deck-w-sette;
        height: $deck-h-sette;
        border-radius: 4px;
        top: 0 !important;
        left: 0 !important;

        .mobile & {
            width: $deck-w-sette-mb;
            height: $deck-h-sette-mb;
            border-radius: 2px;

            &::after {
                font-size: 6.5px;
            }

            .number-deck {
                font-size: 18px;
                bottom: 8%;
            }

            @media (orientation: landscape) {
                width: 32.5px;
                height: 47.5px;

                &::after {
                    font-size: 5px;
                    padding: 6px 24px;
                }

                .number-deck {
                    font-size: 16px;
                    bottom: 0;
                    left: 12%;
                }
            }
        }
    }

    .card {
        width: var(--card-width, 80px);
        height: var(--card-height, 125px);

        .mobile & {
            width: 57px;
            height: 90px;
        }

        &.my-card {
            width: 80px;
            height: 125px;

            .mobile & {
                @media (orientation: landscape) {
                    width: 46px;
                    height: 75px;
                }

                width: 57px;
                height: 90px;
            }
        }

        &.oppo-card {
            width: 65px;
            height: 98px;

            .mobile & {
                width: 46px;
                height: 75px;
            }
        }
    }

    .table-bottom {
        gap: 10px;
        .custom-btn {
            min-width: 153px;
        }

        .mobile & {
            gap: 6px;
            bottom: 2px;

            .custom-btn {
                width: 80px;
                min-width: unset;
            }

            @media (orientation: landscape) {
                bottom: 10px;

                .custom-btn {
                    min-width: 110px;
                }
            }
        }
    }

    .bet-slider {
        width: 97px;
        left: 772px;
        bottom: 100%;

        .ant-slider {
            &-track {
                width: 12px;
            }

            &-handle {
                width: 25px;
                height: 25px;
                margin-left: -6px;
                border-radius: 50%;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
                background: #ffffff;
            }
        }

        .ant-drawer-content-wrapper {
            bottom: 10px;
        }

        .mobile & {
            width: 80px;
            left: 474px;

            .raise-option .ant-radio-button-wrapper {
                width: 40px;
                height: 40px;
                font-size: 10px;
            }

            .ant-slider {
                &-vertical {
                    padding-inline: 2px;
                    margin: 5px 6px;
                }

                &-rail {
                    width: 8px !important;
                }

                &-track {
                    width: 8px !important;
                }

                &-handle {
                    width: 20px;
                    height: 20px;
                    margin-left: -5px;
                }
            }

            .ant-drawer-content-wrapper {
                bottom: 5px;
            }

            @media (orientation: portrait) {
                .ant-drawer-content-wrapper {
                    bottom: 62px;
                }

                left: 247px;
            }
        }
    }

    .type-horizontal,
    .type-vertical {
        .mobile & {
            .player-content {
                margin-left: -8px;
            }

            .player-avatar {
                width: 44px;
                height: 43px;
            }
        }

        .point-badge {
            background-color: #175EBE;
            color: #ffffff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;
            line-height: 1;
            z-index: 100;
            box-shadow: #000000 0 0 6px;

            .desktop & {
                width: 40px;
                height: 40px;
                font-size: 20px;
                font-weight: bold;
                right: 0;
                top: 10px;
                transform: translate(50%, -50%);
            }

            .mobile & {
                width: 24px;
                height: 24px;
                font-size: 10px;
                bottom: -6px;
                left: 30px;
            }
        }

        .banco-badge {
            background-color: #F30301;
            color: #ffffff;
            word-break: break-all;
            text-align: center;
            text-transform: uppercase;
            position: absolute;
            box-shadow: #000000 0 0 6px;
            line-height: 1.2;

            .desktop & {
                width: 18px;
                height: 70px;
                padding: 3px;
                font-size: 10px;
                border-radius: 9px;
                font-weight: bold;
                left: -22px;
            }

            .mobile & {
                width: 12px;
                height: 50px;
                padding: 3px;
                font-size: 7px;
                border-radius: 7px;
                font-weight: bold;
                top: -3px;
                left: -16px;
            }
        }
    }

    .chip-stack {
        top: 0;
        left: 0;

        &.player {
            .mobile & {
                @media (orientation: portrait) {
                    .amount-text {
                        top: 36px !important;
                        left: 18.5px;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    .max-bet {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 0 8px;
        border-radius: 8px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 130px;
        gap: 4px;
        flex-wrap: nowrap;

        .mobile & {
            min-width: 80px;
            font-size: 10px;
            height: 20px;
        }

        &::before {
            content: "Max bet:";
        }
    }
}

.announce-text {
    font-family: 'Riffic Free Bold';
    position: absolute;
    z-index: 500;
    top: 50%;
    left: 50%;
    font-size: 80px;
    translate: -50% -50%;
    transform: scale(0);
    transition: all 1s;
    -webkit-text-stroke: 3px #944900;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.8;

    &::before,
    &::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        color: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    &::before {
        text-shadow: 0 7px 1px #322200, -3px 7px 1px #322200;
        z-index: 0;
    }

    &::after {
        background: -webkit-linear-gradient(#ffea00, #14ff00);
        background: linear-gradient(#ffea00, #14ff00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 2;
        line-height: 0.8;
    }

    .mobile & {
        font-size: 55px;
    }
}

.announce-text.show {
    transform: scale(1);
}