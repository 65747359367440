@import '../../../assets/styles/common/mixin';

.modal-component {
    .modal-title {
        background-color: #0066FF;
        color: #fff;
        font-size: var(--title-font-size, 16px);
        font-weight: 600;
        padding: 0 var(--padding-x, 18px);
        height: var(--height-title, 40px);
        display: flex;
        align-items: center;
        overflow: auto;
        @include respond-to(576px) {
            --title-font-size: 20px;
        }
    }

    .MuiDialog-paper {
        width: 100%;
        max-width: var(--max-width-paper);
        height: var(--height-paper, auto);
        background-color: var(--modal-component-bg, #fff);
        @include respond-to(912px) {
            margin: 0;
            --height-paper: 100vh;
            --max-width-paper: 100%;
            max-height: 100%;
        }
     
    }

    .modal-button {
        + .modal-button {
            margin-left: 0;
        }
    }

    .modal-main {
        border: none;
        @include respond-to(576px) {
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }
    }

    .modal-footer {
        gap: var(--gap-footer, 7px);
        padding: var(--padding-x, 18px);
        .button-component {
            @include respond-to(576px) {
                width: 100%;
            }
        }
    }

    .modal-close {
        padding: 6px;
        path {
            fill: #fff;
        }
    }

    &.modal-small {
        --padding-x: 13px;
        --height-title: 35px;
        --gap-footer: 4px;
        --max-width-paper: 400px
    }

    &.modal-medium {
        --max-width-paper: 600px;
    }
    
    &.modal-notification,
    &.modal-chat {
        --padding-x: 0;
    }

    &.modal-confirm {
        text-align: center;
        .modal-footer {
            justify-content: center;
        }
    }
}

.modal-medium {
    --max-width-paper: 360px
}

.modal-xl {
    --max-width-paper: 760px;
}

.modal-large {
    --max-width-paper: 1110px;
}

.modal-content {
    color: var(--modal-content-color, #101223);
    @include respond-to(576px) {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
}

.mobile-landscape {
    .MuiDialog-paper {
        height: 100%;
        --max-width-paper: 100%;
    }
 
    .modal-footer {
        padding: 10px 50px;
        @media screen and (max-width: 750px) {
            padding: 10px 20px;
        }
    }
}
