.Bestia {
    --chip-item-width: 58px;
    --player-avatar-width: 80px;

    .mobile & {
        --chip-item-width: 37px;
        --player-avatar-width: 46px;
    }

    .pot {
        position: absolute;
        top: 0;
        left: 0;
        --chip-item-width: 76px;
        width: var(--chip-item-width, 58px);
        height: var(--chip-item-width, 58px);

        .mobile & {
            --chip-item-width: 58px;
        }

        .amount-text {
            top: -20px;
            width: var(--chip-item-width, 58px);
            text-align: center;

            .mobile & {
                left: 0;
            }
        }
    }

    .player.chip-stack {
        top: 0;
        left: 0;
        .amount-text {
            left: 65px;
            .mobile & {
                left: 35px;
            }
        }
    }

    .card {
        &.oppo-card {
            top: 25px;
        }

        .mobile & {
            &.my-card {
                top: 15px;

                @media (orientation: landscape) {
                    width: 50px;
                    height: 90px;
                    top: 15px;
                }
            }

            &.oppo-card {
                width: 48px;
                height: 90px;
                top: 50px;
                left: 10px
            }
        }

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .card-trump {
        width: $deck-w-briscola;
        height: $deck-h-briscola;
        border-radius: 4px;
        left: 82px;
        rotate: 70deg;
        top: 530px;

        .mobile & {
            width: $deck-w-briscola-mb;
            height: $deck-h-briscola-mb;
            border-radius: 2px;
            top: 540px;
            left: 280px;
            rotate: 250deg;

            @media (orientation: landscape) {
                top: 260px;
                left: 603px;
                rotate: 250deg;
            }
        }
    }

    .deck-card {
        width: $deck-w-briscola;
        height: $deck-h-briscola;
        border-radius: 4px;
        top: 530px;
        left: 50px;

        .mobile & {
            width: $deck-w-briscola-mb;
            height: $deck-h-briscola-mb;
            border-radius: 2px;
            top: 530px;
            left: 300px;

            @media (orientation: landscape) {
                top: 250px;
                left: 620px;
            }
        }
    }

    .point {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        position: absolute;
        top: 500px;
        left: 27px;
        justify-content: center;
        align-items: center;
        background: #092020;
        color: #fff;
        border-radius: 100% !important;
        width: 40px;
        height: 40px;
        z-index: 100;

        .mobile & {
            scale: 0.5;
            border-radius: 2px;
            top: 510px;
            left: 310px;

            @media (orientation: landscape) {
                top: 235px;
                left: 633px;
            }
        }
    }
}