@import '../../../assets/styles/common/mixin';

.header-small {
    --height-slider-table-container: 30px;
    --height-slider-table-item: 25px;

    .slider-table-des,
    .slider-table-user {
        display: none;
    }
}

.slider-table-container {
    height: var(--height-slider-table-container, 70px);
    background-color: var(--background-header-slider, #0E345E);
    border-bottom: 0.5px solid var(--border-bottom-slider-table, rgba(255, 255, 255, 0.1));

    @include respond-to(800px) {
        --border-bottom-slider-table: rgba(0, 0, 0, 0.50);
    }
}

.slider-table-item {
    display: flex;
    align-items: center;
    gap: 7px;
    border-radius: 5px 5px 0 0;
    padding: 0 6px;
    height: var(--height-slider-table-item, 60px);
    background-size: cover;
    cursor: pointer;
}

.slider-table-images {
    width: 47px;
    height: 47px;
}

.slider-table-name {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.1;

    @include respond-to(480px) {
        font-size: 15px;
    }
    @include respond-to(360px) {
        font-size: 14px;
    }
}

.slider-table-des {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.slider-table-user {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;

    @include respond-to(576px) {
        font-size: 16px;
    }
}

.slider-table-content {
    flex: 1;
    margin-top: 2px;
    overflow: hidden;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    z-index: 9;
    padding: 0 7px;
    display: flex;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
}

.swiper-button-next {
    right: 0;

    &::before {
        right: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.6) 100%);
    }
}

.swiper-button-prev {
    left: 0;

    &::before {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.00) 100%);
        left: 0;
    }

    svg {
        rotate: -180deg;
    }
}

.swiper-button-disabled {
    display: none;
    opacity: 0;
}

.tournament-animation {
    position: absolute;
    z-index: 9;
    color: #fff;
    border-radius: 5px 5px 0 0;

    img {
        width: 40px;
    }
}

.swiper-slide {
    transition: transform 0.5s ease-in-out;
    will-change: transform;
    min-width: var(--swiper-slide-min-width, 200px);
}

.slide-and-fade-out {
    animation: slideAndFadeOut 1.5s ease-in-out forwards;
    position: absolute;
    z-index: 999;
    overflow: hidden;
}

@keyframes slideAndFadeOut {
    0% {
        transform: translateY(0);
        height: var(--height-slider-item, 60px);
    }

    50% {
        transform: translateY(var(--slider-item-position-1, 50px));
        height: var(--height-slider-item, 60px);
    }

    100% {
        transform: translateY(var(--slider-item-position-2, 110px));
        height: 0;
    }
}

.mobile {
    --slider-item-position-1: 50px;
    --slider-item-position-2: 110px;
    --swiper-slide-min-width: 180px;

    .swiper-more {
        --swiper-slide-min-width: 100px;
    }
    .header-small {
        --height-slider-item: 25px;
        --slider-item-position-1: 30px;
        --slider-item-position-2: 55px;
    }
}

.hidden-tour {
    opacity: 0 !important;
}

.slide-enter {
    transform: translateX(-100%) !important;
}