.toast-lobby-container {
    .MuiAlert-icon {
        color: #fff;
        svg {
            color: #fff
        }
    }
    .MuiAlert-action {
        display: none;
    }
}