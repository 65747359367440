@import '../../assets/styles/common/mixin';

.content-main-modal {
    @include respond-to(576px) {
        flex: 1;
    }
}

.cash-join-modal {
    --max-width-paper: 460px !important;

    .modal-title {
        justify-content: center;
    }

    .MuiDialogContent-root.MuiDialogContent-dividers {
        padding: 15px 23px 18px;
    }

    .MuiModal-backdrop {
        background-color: rgba(0, 0, 0, 0.8);
    }

    .balance-label {
        height: 19px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    
    .stake-range-label {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 40px;
        margin-left: -14.5px;
        padding: 3px;
        line-height: 17px;
        width: 91px;

        &:last-child {
            margin-right: -14.5px;
            margin-left: auto;
        }
        
        &>span {
            color: #E20613;
        }
    }
    
    .btn-section {
        border-top: 1px solid #D2D2D3;
        padding-top: 20px;
        margin: 0 14.5px;
        width: calc(100% - 29px);
    }

    &.mobile, &.mobile-landscape {
        .MuiDialog-paper {
            height: auto;
            --height-paper: auto;
            --max-width-paper: 300px !important;
        }
        .MuiDialogContent-root.MuiDialogContent-dividers {
            padding: 15px 10px;
        }

        .balance-label {
            margin-bottom: 9px;
        }

        .stake-range-label {
            font-size: 12px;
            width: 64px;
            padding: 0;
            margin: 0;
        }

        .btn-section {
            margin: 0 30px;
            width: calc(100% - 60px);
            padding-top: 12px;
            button {
                min-width: 95px !important;
            }
        }
    }
}