@import '../assets/styles/common/mixin';

.container-lobby-page {
    padding: var(--container-lobby-page-padding, 30px 12px) !important;
    height: 100%;

    @include respond-to(800px) {
        --container-lobby-page-padding: 8px;
    }

    @include respond-to(576px) {
        --container-lobby-page-padding: 0;
    }
}

.info-table-box {
    position: absolute;
    right: var(--info-table-box-right, 16px);
    top: var(--info-table-box-top, 16px);
    z-index: 9;
    // padding: 5px;
}

.mobile {
    @media screen and (orientation: landscape) {
        --info-table-box-right: 0;
        --info-table-box-top: 0;
    }

    @media screen and (orientation: portrait) {
        --info-table-box-right: 5px;
        --info-table-box-top: 12px;
    }
}

.Scopa,
.Briscola,
.AssoPigliaTutto {
    .mobile & {
        @media screen and (orientation: portrait) {
            --info-table-box-top: -33px;
        }
    }
}

.ant-modal-root {
    position: relative;
    z-index: 9999;
}

.tournament-information {
    position: absolute;
    border-radius: 8px;
    background: #024632;
    color: #fff;
    display: flex;
    gap: 7px;
    flex-direction: column;
    font-family: 'Raleway';
    font-weight: 500;
    justify-content: center;
    line-height: 16px;
    padding: 10px;
    font-size: 14px;
    top: 100%;
    right: 0;
    width: 250px;
}

.info-table-icon {
    min-width: auto !important;

    svg {
        color: #fff;
        width: 30px;
        height: 30px;
    }
}

.overlay-tutorial {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    user-select: none;
    overflow: hidden;
}

.introjs-showElement {
    z-index: 999 !important;
    position: relative;

    &:before {
        width: 100%;
        height: 100%;
        left: 0;
        position: absolute;
        content: "";
        z-index: 999999 !important;
        user-select: none;
    }
}

.introjs-tooltipReferenceLayer {
    visibility: visible !important;
}
.translate-component {
    height: 20px;
    width: 150px;
    overflow: hidden;
}

.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
    height: 20px;
    width: 150px;

    &>span {
        display: none;
    }
    .goog-te-combo {
        width: 100%;
    }
}

.goog-te-gadget .goog-te-combo {
    color: #101223 !important;
}