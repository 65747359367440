.dark-mode {
    --color-boder: #10171F;
    --background-footer: #040F1B;
    --tournament-box-shadow: none;
    --background-header-tabs: #040F1B;
    --background-header-slider: #040F1B;
    --footer-color-desktop: #E7E4E4; //footerTextDesktop
    --footer-color-mobile: #E7E4E4; //footerTextMobile
    --background-header-top: #0F0F11; //backgroundTop
    --White-2: #040F1B;
    --light-stroke: #10171F;
    --profile-user-container-bg: #040F1B;
    --chat-input-box-bg: #000;
    --user-chat-content-bg: #000;
    --notification-timeout-color: #fff;

    // #chat-message-input {
    //     color: #fff;
    // }

    &.side-bar-game {

        span,
        h3 {
            color: #fff !important;
        }
        .link-hover:hover {
            span {
                color: #000 !important;
            }
        }
    }


    &:not(.drawer-container),
    .tournament-info-btn {
        background-color: #10171F;
    }

    .rule-game,
    .MuiDrawer-paper,
    .MuiTypography-root,
    svg,
    .profile-user-info-lable,
    .profile-user-number,
    .tournament-info-select label,
    .MuiTableCell-root:hover,
    .tournament-container,
    .mobile-menu-link,
    .MuiTableSortLabel-root:focus, {
        color: #fff;
    }

    .MuiSwitch-track {
        background-color: rgba($color: #fff, $alpha: 0.7);
    }

    .rule-tabs .ant-tabs-nav,
    .sub-tabs-list,
    .mobile-menu {
        background-color: #040F1B;
        color: #fff;
        border-color: transparent;
    }

    .rule-tabs .ant-tabs-nav .ant-tabs-tab,
    .rule-tabs .ant-tabs-nav .ant-tabs-tab-btn {
        border-color: #030015;
        border-width: 0 0 1px 0;
    }

    .tournament-mobile-item {
        background: var(--Dark-mode---list, linear-gradient(180deg, #17171A 0%, #16171E 50%, #101015 100%));
        
        +.tournament-mobile-item {
            border-color: #030015;
            border-width: 2px 0 0 0;
            
        }
    }

    table {
        tr {
            box-shadow: 0px 1px 1px 0px #1E1E1E inset;

            &:nth-of-type(2n) {
                background: transparent;
            }

            th,
            td {
                border-color: #030015;
                border-width: 0 0 2px 0;
                --color: #fff;
                color: #fff !important;
                background: var(--Dark-mode---list, linear-gradient(180deg, #17171A 0%, #16171E 50%, #101015 100%));

                .filter-btn svg path {
                    fill: #fff !important;
                }
            }

            th {
                border-width: 0 0 3px 0;
            }

            .MuiTableSortLabel-root {
                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .loading-tournament,
    .profile-collapse-table .loading-box {
        svg {
            fill: #fff;
        }
    }

    .setting-item svg {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
    }


    .drawer-header,
    .profile-edit-main {
        background-color: #040F1B;
        border-bottom: 1px solid #3b3b3d;
        color: #fff;
    }

    .MuiDrawer-paper {
        box-shadow: 0 10px 10px rgba($color: #fff, $alpha: 0.28);
        background-color: #10171F;
    }

    .menu-list-container .profile-menu-item,
    .history-detail-item {
        border-color: #030015;
        border-width: 0 0 2px 0;
    }

    .history-detail {
        border-color: #030015;
        border-width: 2px;
        background-color: transparent;
    }

    .MuiFormControlLabel-label.Mui-disabled {
        color: rgba($color: #fff, $alpha: 0.5) !important;
    }

    .select-component .MuiSelect-select {
        border: 1px solid #d2d2d3 !important;
        color: #fff;
    }

    .setting-item {
        border-color: #373737;
    }
}

.loading-tournament {
    background-color: transparent;
}

.modal-dark-mode {
    --modal-component-bg: #000;
    --filter-item-label-color-active: #fff;
    --subcribe-pay-color: #fff;
    --modal-content-color: #fff;
    --subcribe-password-input-border-color: #fff;
    color: #fff;

    .MuiDialog-paper {
        box-shadow: 0px 0px 14px #e7d7d747;
    }
    p {
        color: #fff;
        --filter-item-label-color: #8E8E8E;
    }
    input {
        color: #fff
    }
}
