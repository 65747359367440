.main-table {
    position: var(--main-table-position, fixed);
    left: var(--main-table-left, 0);
    right: var(--main-table-right, 0);
    bottom: var(--main-table-bottom, 0);
    top: var(--main-table-top, 70px);
    height: var(--main-table-height, calc(100% - 70px));

    & * {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}

.mobile {
    --main-table-top: 48px;
    --main-table-height: calc(100% - 48px);
    --table-bottom: 5px;

    @media (orientation: landscape) {
        --main-table-position: static;
    }
}

.cirulla-message,
.settebello-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9999;
    width: 200px;
    height: 200px;

    @media screen and (min-width: 768px) {
        width: 350px;
        height: 350px;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }

    &:after {
        background-image: url('../../../public/images/common/asset_scopa.png');
        width: 0;
        height: 0;
        z-index: 99;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        transition: width 0.75s ease, height 0.75s ease;
    }

    &::before {
        background-image: url('../../../public/images/common/asset_radialLight.png');
        width: 200px;
        height: 200px;
        animation: scopaRotate 4s infinite linear;
        left: 0;
        top: 0;

        @media screen and (min-width: 768px) {
            width: 350px;
            height: 350px;
        }
    }
}

.scopa-message,
.settebello-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9999;
    width: 200px;
    height: 200px;

    @media screen and (min-width: 768px) {
        width: 350px;
        height: 350px;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }

    &:after {
        background-image: url('../../../public/images/common/asset_scopa.png');
        width: 0;
        height: 0;
        z-index: 99;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        transition: width 0.75s ease, height 0.75s ease;
    }

    &::before {
        background-image: url('../../../public/images/common/asset_radialLight.png');
        width: 200px;
        height: 200px;
        animation: scopaRotate 4s infinite linear;
        left: 0;
        top: 0;

        @media screen and (min-width: 768px) {
            width: 350px;
            height: 350px;
        }
    }
}

.briscola-message {
    position: absolute;
    top: 25%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 700px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 700px 250px;
    z-index: 9999;

    @media screen and (orientation: portrait) {
        top: 40%;
        width: 450px;
        height: 150px;
        background-size: 450px 150px;
    }
}

.denari {
    background-image: url('../../../public/images/common/bristona_a_denara.png');
}

.coppe {
    background-image: url('../../../public/images/common/bristona_a_coppe.png');
}

.bastoni {
    background-image: url('../../../public/images/common/bristona_a_bastoni.png');
}

.spade {
    background-image: url('../../../public/images/common/bristona_a_spade.png');
}

.active {
    &:after {
        width: 150px;
        height: 150px;
        transition: width 0.5s ease, height 0.5s ease;

        @media screen and (min-width: 768px) {
            width: 300px;
            height: 300px;
        }
    }
}

.settebello-message {
    z-index: 9999;

    &:after {
        background-image: url('../../../public/images/common/asset_settebello.png');
    }
}

@keyframes cirullaRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes scopaRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes scopaMessage {
    from {
        width: 0;
        height: 0;
    }

    to {
        width: 150px;
        height: 150px;

        @media screen and (min-width: 768px) {
            width: 350px;
            height: 350px;
        }
    }
}

.message-table {
    animation: messageTable 0.25s alternate;
    top: 25%;

    @media all and (max-height: 670px) and (max-width: 576px) {
        top: 30%
    }

    @media all and (min-width: 768px) {
        top: 23%;
    }

    @media all and (min-width: 1280px) {
        top: 19%;
    }
}

@keyframes messageTable {
    0% {
        top: 50%;

        @media all and (min-width: 768px) {
            top: 30%;
        }
    }

    100% {
        @media all and (max-width: 767px) {
            top: 35%;
        }

        @media all and (max-height: 670px) and (max-width: 576px) {
            top: 30%
        }

        @media all and (min-width: 768px) {
            top: 25%;
        }

        @media all and (min-width: 1280px) {
            top: 15%;
        }
    }
}

@keyframes winImages {
    0% {
        width: 70px;
        height: 70px;

        @media all and (min-width: 1024px) {
            width: 90px;
            height: 90px;
        }
    }

    100% {
        width: 90px;
        height: 90px;

        @media all and (min-width: 1024px) {
            width: 110px;
            height: 110px;
        }
    }
}

.player-collapse {
    writing-mode: tb;
}

.table-in-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 999px;
    width: calc(100% - 260px);
    height: calc(100% - 180px);
    border: 3px solid rgba(255, 255, 255, 0.2);
    z-index: -1;

    .mobile & {
        @media screen and (orientation: landscape) {
            top: 14px;
            transform: translate(-50%, 0);
            width: calc(100% - 86px);
            height: calc(100% - 52px);
        }

        @media screen and (orientation: portrait) {
            top: 30px;
            transform: translate(-50%, 0);
            width: calc(100% - 42px);
            height: calc(100% - 66px);
        }
    }
}

.table-bottom {
    bottom: var(--table-bottom, 12px);
}

.board-card-game {
    width: 100%;
    height: 100%;
    // border: 1px solid;
}

.table-logo img {
    max-width: var(--table-logo-img-max-width, 300px);
    height: var(--table-logo-img-height, 40px);

    @media (min-width: 1280px) {
        height: var(--table-logo-img-height-1280, 50px);
    }

    @media (max-width: 1280px) and (orientation: portrait) {
        height: var(--table-logo-img-height-1280-portrait);
    }
}

.sanremo {
    --table-logo-img-max-width: 400px !important;
    --table-logo-img-height: 35px !important;
    --table-logo-img-height-1280: 40px !important;
    --table-logo-img-height-1280-portrait: 22px !important;
}

.macaowin {
    --table-logo-img-max-width: 400px !important;
    --table-logo-img-height: 100px !important;
    --table-logo-img-height-1280: 100px !important;
    --table-logo-img-height-1280-portrait: 100px !important;
}

.ringing {
    -webkit-animation: ring 4s .7s ease-in-out 3;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out 3;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out 3;
    transform-origin: 50% 4px;
    color: #fff;
}

@keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }

    1% {
        -webkit-transform: rotateZ(30deg);
    }

    3% {
        -webkit-transform: rotateZ(-28deg);
    }

    5% {
        -webkit-transform: rotateZ(34deg);
    }

    7% {
        -webkit-transform: rotateZ(-32deg);
    }

    9% {
        -webkit-transform: rotateZ(30deg);
    }

    11% {
        -webkit-transform: rotateZ(-28deg);
    }

    13% {
        -webkit-transform: rotateZ(26deg);
    }

    15% {
        -webkit-transform: rotateZ(-24deg);
    }

    17% {
        -webkit-transform: rotateZ(22deg);
    }

    19% {
        -webkit-transform: rotateZ(-20deg);
    }

    21% {
        -webkit-transform: rotateZ(18deg);
    }

    23% {
        -webkit-transform: rotateZ(-16deg);
    }

    25% {
        -webkit-transform: rotateZ(14deg);
    }

    27% {
        -webkit-transform: rotateZ(-12deg);
    }

    29% {
        -webkit-transform: rotateZ(10deg);
    }

    31% {
        -webkit-transform: rotateZ(-8deg);
    }

    33% {
        -webkit-transform: rotateZ(6deg);
    }

    35% {
        -webkit-transform: rotateZ(-4deg);
    }

    37% {
        -webkit-transform: rotateZ(2deg);
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
    }

    41% {
        -webkit-transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
    }

    100% {
        -webkit-transform: rotateZ(0);
    }
}

.highlight-box {
    position: absolute;
    display: block;
    box-shadow: 0 0 0 6px #D66C01 !important;
    border-radius: 8px;
}

// new iframe css
.main-table {
    &-Scopa,
    &-Briscola,
    &-Scala40,
    &-Burraco,
    &-ItalianPoker,
    &-Scopone,
    &-SetteEMezzo,
    &-AssoPigliaTutto,
    &-Peppa {
        --main-table-top: 60px;
        --main-table-height: calc(100% - 60px);
    }
}

.desktop.container-game {
    &-AssoPigliaTutto {
        --button-action-bottom: 16px;
    }
}

.mobile {
    @media screen and (orientation: portrait) {
        .main-table {
            &-Scopa,
            &-Briscola,
            &-Scala40,
            &-Burraco,
            &-ItalianPoker,
            &-SetteEMezzo,
            &-Tresette,
            &-Scopone,
            &-AssoPigliaTutto,
            &-Peppa {
                --main-table-top: 0;
                --main-table-height: 100%;
                --info-table-box-top: 25px;
            }

            &-SetteEMezzo {
                --info-table-box-top: 10px;
            }

            &-ItalianPoker,
            &-Peppa {
                --info-table-box-top: 0px;
            }
        }

        &.container-game-ItalianPoker {
            .header-mobile {
                padding: 0;
                --header-bar-height: 28px;
                --header-bar-padding: 0;
                flex-direction: column;
                width: 50px !important;
                gap: 8px;

                button {
                    margin: 0 !important;
                }
            }
        }
    }
}