@import '../../../assets/styles/common/mixin';

.button-component {
    @include respond-to(576px) {
        --button-medium-height: 40px;
    }

    &.button-medium {
        height: var(--button-medium-height, 30px);
        min-width: var(--button-medium-min-width, 95px);
        min-height: var(--button-medium-height, 30px);
        max-width: var(--button-medium-max-width, 100%);

        @include respond-to(576px) {
            border-radius: 10px;
            --button-medium-height: 40px;
        }

        .mobile-landscape & {
            border-radius: 10px;
            font-size: 16px;
        }

        .game-select-btn-grp & {
            min-width: unset;
        }
    }

    &.button-small {
        height: 25px;
        min-width: auto;
    }

    &.button-large {
        height: var(--button-large-height, 35px);
        min-width: var(--button-large-min-width, auto);
        font-size: var(--button-large-font-size, 15px) !important;
        max-width: var(--button-medium-max-width, 100%);

        @include respond-to(576px) {
            --button-large-font-size: 15px;
            --button-large-height: 40px;
        }
    }

    &.btn-rouned-large {
        border-radius: 10px;
        font-weight: 500;
    }
}