@import "../../assets/styles/common/mixin";

.profile-container {
    @include respond-to(576px) {
        --height-currency: 30px;
        --padding-currency: 4px;
        --gap-user-currency-container: 7px;
        --font-size-12: 16px;
        --font-size-14: 16px;
        --profile-user-number-font-size: 20px;
        --profile-avatar-info-gap: 5px;
        --mt-credit-container: 0;
        --profile-menu-item-height: 50px;
        --profile-menu-item-child-pl: 56px;
        --profile-user-info-list-gap: 30px;
        --profile-user-info-list-padding: 19px 12px;
        --width-profile-user-info-lable: 170px;
    }
}

.profile-user-info-list {
    @include respond-to(576px) {
        --width-profile-user-info-lable: 170px;
        --profile-user-info-list-padding: 19px 13px;
        --profile-user-info-item-font-size: 20px;
    }
}

.profile-edit-container {
    @include respond-to(576px) {
        --avatar-list-grid-template-columns: repeat(3, 110px);
        --profile-edit-avatar-width: 110px;
    }
}

.profile-header-info-container {
    display: flex;
    padding: 16px var(--padding-x-content, 20px);
    gap: 15px;
    background: var(--White-2, #F4F8F9);
    border-bottom: 0.5px solid var(--light-stroke, #D2D2D3);
    width: 100%;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .ranking-star {
        width: 18px;
        height: 18px;
       
    }
}

.user-currency-container {
    margin-top: 3px;
    gap: var(--gap-user-currency-container, 2px) !important;
    justify-content: flex-start !important;

    .currency {
        width: auto;
        background-color: #0066FF;
        border-radius: 15px;
        padding: var(--padding-currency, 2px);
        height: var(--height-currency, 23px);

        .balance {
            color: white;
            padding: 0 5px;
            font-size: var(--font-size-14, 14px);
            font-weight: 500;
            flex: 1;
        }
    }
}

.menu-list-container {
    .MuiList-root {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .profile-menu-item {
        border-bottom: 1px solid #d9d9d9;
        padding: 5px var(--padding-x-content, 20px);
        height: var(--profile-menu-item-height, 40px);
        gap: 10px;
    }

    .child {
        padding-left: var(--profile-menu-item-child-pl, 40px);
    }

    .MuiListItemIcon-root {
        min-width: auto;
    }

    .MuiTypography-root {
        font-size: var(--font-size-12, 12px);
        font-weight: 500;

        @include respond-to(800px) {
            --font-size-12: 20px;
        }
    }

    .MuiSvgIcon-root,
    .profile-menu-icon {
        color: inherit;
    }
}

.profile-user-container {
    padding: var(--profile-user-container-padding, 11px 13px 17px 13px);
    border-bottom: 0.5px solid var(--light-stroke, #D2D2D3);
    background-color: var(--profile-user-container-bg, #F4F8F9);

    @include respond-to(576px) {
        --font-size-10: 16px;
        --font-size-12: 16px;
        --font-size-14: 20px;
        --profile-user-number-height: 40px;
        --profile-user-number-width: 110px;
        --profile-user-label-text-align: center;
        --profile-avatar-info-gap: 5px;
    }

    .mobile-landscape & {
        display: flex;
        gap: 64px;
    }
}

.profile-avatar-info {
    display: flex;
    flex-direction: var(--profile-avatar-info-flex-direction, column);
    gap: var(--profile-avatar-info-gap, 0);

    .credit-text {
        font-size: var(--font-size-12, 12px);
    }
}

.profile-avatar-name {
    font-size: var(--font-size-20, 20px);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2;

    @include respond-to(576px) {
        --font-size-20: 24px;
    }
}

.profile-avatar-id {
    color: var(--Mid-grey, #8E8E8E);
    font-size: var(--font-size-14, 14px);
    font-weight: 500;
    line-height: 17px;

    @include respond-to(576px) {
        --font-size-20: 16px;
    }
}

.profile-avatar {
    display: flex;
    gap: 15px;
    align-items: center;
}

.profile-avatar-image {
    width: var(--avatar-size, 50px) !important;
    height: var(--avatar-size, 50px) !important;
    overflow: visible !important;
    img {
        border-radius: 50%;
    }
    &::before {
        content: '';
        position: absolute;
        left: -4px;
        right: -4px;
        bottom: -4px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    @include respond-to(576px) {
        --avatar-size: 64px;
    }
}

.profile-user-info-list {
    padding: var(--profile-user-info-list-padding, 13px);
    display: flex;
    flex-direction: var(--profile-user-info-list-flex-direction, column);
    gap: var(--profile-user-info-list-gap, 20px);
    flex-wrap: wrap;
}

.profile-user-info-column {
    gap: var(--profile-user-info-list-gap, 20px) !important;
    width: var(--profile-user-info-item-width, auto);
}

.profile-user-info-item {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: var(--profile-user-info-item-font-size, 14px) !important;
    gap: var(--profile-user-info-item-gap, 20px);
}

.profile-user-info-lable {
    width: var(--width-profile-user-info-lable, 150px);
    color: var(--Text-color, #101223);
}

.profile-user-info-txt {
    color: var(--Mid-grey, #8E8E8E);
    // font-size: var(--font-size-14, 14px) !important;

    &.success {
        --Mid-grey: #00BE2A;
    }

    &.warning {
        --Mid-grey: #E20613;
        text-decoration-line: underline;
    }

    &.link {
        --Mid-grey: #06F;
        text-decoration-line: underline;
    }
}

.profile-user-rate {
    justify-content: var(--justify-content-profile-user-rate, space-between) !important;
    margin-top: var(--profile-user-rate-margin-top, 23px);
    gap: var(--profile-user-rate-gap, 10px) !important;
    flex: 1;
}

.profile-user-label {
    font-size: var(--font-size-10, 10px);
    font-weight: 500;
    margin-bottom: 3px;
    text-align: var(--profile-user-label-text-align, left);
}

.profile-user-item {
    flex: 1;
}

.profile-user-number {
    max-width: var(--profile-user-number-width, 87px);
    height: var(--profile-user-number-height, 30px);
    border-radius: 4px;
    border: 1px solid var(--light-stroke, #D2D2D3);
    background: var(--White-2, #F4F8F9);
    color: #000;
    font-size: var(--profile-user-number-font-size, 14px);
    font-weight: 500;
}

.profile-edit-container {
    padding: 13px;
    font-size: var(--font-size-14, 14px);
    font-weight: 500;
    color: #000;
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: var(--profile-edit-container-align-items, left);
}

.avatar-list {
    display: grid;
    justify-content: space-between;
    grid-row-gap: 10px;
    grid-template-columns: var(--avatar-list-grid-template-columns, repeat(4, 50px));
    margin-bottom: var(--avatar-list-margin-bottom, 24px);
    height: var(--height-avatar-list, auto);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3px var(--avatar-list-padding, 3px);
    width: 100%;

    @include respond-to(576px) {
        --height-avatar-list: 100%;
        --avatar-list-margin-bottom: 0;
    }
}

.profile-edit-avatar {
    width: var(--profile-edit-avatar-width, 50px) !important;
    height: var(--profile-edit-avatar-width, 50px) !important;
}

.profile-edit-main {
    margin-top: 13px;
    border-radius: 5px;
    border: 0.5px solid var(--light-stroke, #D2D2D3);
    background: #FFF;
    box-shadow: 0px 3px 9.4px 3px rgba(0, 0, 0, 0.10);
    padding: 20px;
}

.profile-edit-lable {
    margin-bottom: 18px;
}

.color-success {
    color: #00BE2A !important
}

.color-error {
    color: #E20613 !important;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.break-word {
    word-wrap: break-word;
}

.credit-container {
    margin-top: var(--mt-credit-container, 8px);
}

.change-avatar-btn:not(.scroll-end) {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 70px;
        background: linear-gradient(0, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0) 100%);
    }
}

.popover-edit-avatar {
    .MuiPaper-root {
        margin-top: var(--popover-edit-avatar-margin-top, 10px);
        width: var(--popover-edit-avatar-width, 280px);
        margin-left: var(--popover-edit-avatar-margin-left, 5px);
        height: var(--popover-edit-avatar-height, auto);
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        @include respond-to(576px) {
            transform-origin: 0 0 !important;
            --popover-edit-avatar-width: 100vw;
            --popover-edit-avatar-height: 100vh;
            --popover-edit-avatar-margin-top: 0;
            --popover-edit-avatar-margin-left: 0;
            top: 0 !important;
            left: 0 !important;
        }
    }
}

.setting-item {

    &,
    .MuiFormControlLabel-label {
        @include respond-to(576px) {
            font-size: 16px !important;
        }

        .mobile-landscape & {
            font-size: 16px !important;
        }
    }

    .mobile-landscape & {
        height: 50px;
        min-height: 50px;

        svg {
            +span {
                font-size: 20px !important;
            }
        }

        .MuiSwitch-root {
            width: 80px;
            height: 40px;
        }

        .MuiSwitch-track {
            height: 40px !important;
        }

        .MuiSwitch-switchBase {
            height: 35px;
            padding: 6px;
            margin-top: 3px;

            &.Mui-checked {
                transform: translateX(38px);
            }
        }

        .MuiSwitch-thumb {
            width: 30px !important;
            height: 30px !important;
        }
    }
}

.profile-menu-box {
    flex: 1;
    width: 100%;
}

.footer-container-mobile {
    background-color: var(--background-footer, #0e345e);
    width: 100%;

    .mobile-landscape & {
        padding-left: var(--padding-x-content, 50px);
        padding-right: var(--padding-x-content, 50px);

        .MuiContainer-root {
            padding: 0;
        }
    }
}

.profit {
    &::before {
        margin-right: 4px;
    }

    &.losses {
        color: #E20613 !important;

        &::before {
            content: '-',
        }
    }

    &.wins {
        color: #00BE2A !important;

        &::before {
            content: '+';
        }
    }
}

.tooltip-link {
    text-decoration: underline;
}

.ranking-list {
    span {
        +span {
            margin-left: -2px;
        }
    }
}

@mixin ranking-vip($index) {
    &::before {
        background-image: url('../../../public/images/ranking/ranking-vip-#{$index}.svg');
        @if $index >= 3 {
            top: -6px;
          } @else {
            top: -6px;
          }
    }
}

@for $i from 0 through 5 {
    .ranking-vip-#{$i} {
        @include ranking-vip($i);
    }
}

.chat-message {
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    display: block;
}

.profile-collapse-table {
    --padding: 9.5px 6px;
}

.profile-account-code {
    font-size: 17px;
    font-weight: 500;
    @media screen and (min-width: 576px) {
        font-size: 14px;
    }
}