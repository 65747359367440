@import '../../assets/styles/common/mixin';

.chat-form-input {
  position: relative;
  margin-left: 10px;
  flex: 1;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  input {
    border: 0.5px solid #D2D2D3;
    padding: 0 35px 0 7px;
    height: 100%;
    background-color: #fff;
    border-radius: 30px;

    &:focus {
      outline: none;
    }
  }

}

.chat-clear {
  cursor: pointer;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #828B92;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background-color: #bf0000;
    color: #fff;
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: hidden;

  @media screen and (orientation: landscape) {
    height: calc(100% - 50px);
  }

}

.chat-box {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-list {
  height: 100%;
  padding: var(--chat-list-padding, 0);
}

.chat-send-btn {
  padding: 0 !important;
  min-width: 0 !important;
}

.chat-button {
  z-index: 1200 !important;

  &.active {
    background-color: #fff !important;
    color: inherit;
  }

  svg {
    color: inherit !important;
  }
}

.user-chat-content {
  background-color: var(--user-chat-content-bg, #F4F8F9);
}

.chat-input-box {
  background-color: var(--chat-input-box-bg, #F4F8F9);
  border-top: 0.5px solid #D2D2D3;
  height: var(--chat-input-box-height, 40px);
  padding: var(--chat-input-box-padding, 0 5px);
  // margin-bottom: 20px;
}

.chat-input-game {
  --chat-input-box-height: auto;
  --chat-input-box-padding: 0;
  border-bottom: 0.5px solid var(--light-stroke, #D2D2D3);
  gap: 2px;
}

.chat-input-top {
  box-shadow: 3px 4px 8px 2px rgba(0, 0, 0, 0.10);
}

.chat-emoji-icon {
  padding: 5px !important;

  svg {
    width: 50px;
    height: 50px;
  }
}

.chat-suggest-icon {
  padding: 0 !important;

  svg {
    width: 60px;
    height: 60px;
  }
}

.chat-tabs {
  border-bottom: 0.5px solid var(--light-stroke, #D2D2D3);
  background: var(--White-2, #F4F8F9);
  height: 40px;
  padding: var(--chat-tabs-padding, 0 18px);
  align-items: flex-start;
  gap: 7px;
  min-height: 40px !important;
  align-items: center;

  .MuiTab-root {
    display: flex;
    height: 24px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    min-height: auto;
    min-width: auto;
    line-height: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    background: #D2D2D3;
    color: #8E8E8E !important;

    &:hover {
      box-shadow: none;
    }
  }

  .MuiTabs-flexContainer {
    gap: 7px;
  }

  .Mui-selected {
    background: var(--Blue, #06F);
    color: #FFF !important;
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.chat-suggest-item {
  border-radius: 40px;
  border: 1px solid #101223;
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: rgba(230, 230, 230, 0.7);
  }
}


@media screen and (max-width: 678px) {
  .chat-suggest-item {
    &:hover {
      background-color: #fff;
    }
  }
}



.chat-noti-countdown {
  font-weight: bold;
  padding: 0 10px;
  white-space: nowrap;
}

.chat-emoji-suggest {
  padding: 12px;
  width: 100%;
}

.chat-suggest-list {
  display: flex;
  gap: 5px;
  font-size: 14px;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
  max-height: var(--chat-suggest-list-max-height, 121px);
  width: 100%;
}

.chat-emoji-list {
  flex-wrap: wrap;
  gap: var(--chat-emoji-list-gap, 10px);

  @media screen and (max-width: 576px) {
    --chat-emoji-list-gap: 5px;
  }
}