@import '../../assets/styles/common/mixin';

.header-top-container {
    background-color: var(--background-header-top, #0E345E); //backgroundTop
    padding: 5px 0;
    height: var(--height-header-top-container, 60px);
    border-bottom: 0.5px solid var(--border-bottom-header-top, rgba(255, 255, 255, 0.10));

    @include respond-to(800px) {
        --border-bottom-header-top: rgba(0, 0, 0, 0.50);
    }
}

.header-top-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.change-game-image {
    width: 40px;
    height: 40px;

    img {
        max-width: 100%;
    }
}

.header-small {
    --height-change-game-button: 26px;
    --padding-change-game-button: 0 6px;
    --border-width-change-game-button: 2px;
    --width-header-avatar: 24px;
    --height-border-change-game: 80%;
    --height-svg-change-game-button: 22px;
    --height-header-balance: 24px;
    --width-svg-header-balance: 16px;
    --width-svg-add-header-balance: 16px;
    --height-header-top-container: 35px;
    --font-size-header-balance-text: 14px;

    .question-icon svg {
        height: 18px;
    }

    .filter-btn {

        &,
        svg {
            height: 22px;
        }
    }
}

.header-avartar {
    width: var(--width-header-avatar, 32px) !important;
    height: var(--width-header-avatar, 32px) !important;
    position: relative;
    overflow: visible !important;

    img {
        border-radius: 50%;
    }

    &::before {
        content: "";
        position: absolute;
        left: -4px;
        right: -4px;
        bottom: -4px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.change-game-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 44px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 2px 7px;
    cursor: pointer;
    border: var(--border-width-change-game-button, 3px) solid #092340;
    
    svg {
        width: 24px;
        height: 24px;
    }

    @include respond-to(420px) {
        width: 120px;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        padding: 2px 5px;
        border: var(--border-width-change-game-button, 2px) solid #092340;
        border-radius: 10px;

        svg {
            width: 22px;
            height: 22px;
        }
    }
    .change-game {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        border-width: 0 1px 0 0;
        border-style: solid;
        padding-right: 6px;
        margin-right: 9px;
        flex: 1;
        text-transform: uppercase;
        height: var(--height-border-change-game, 100%);
        word-break: keep-all;
        @include respond-to(420px) {
            max-width: 78px;
            word-break: break-word;
            justify-content: flex-start;
        }
    }
}

.change-game-button-small {
    height: 26px;
    border-radius: 6px;
    width: 120px;
    font-size: 10px;
    padding: 2px 5px;
    svg {
        width: 20px;
        height: 20px;
    }
    .change-game {
        margin-right: 4px;
    }
    .small-text {
        font-size: 9px;
        line-height: 10px;
    }
}

.header-top-right,
.header-balance {
    display: flex;
    align-items: center;
}

.header-top-right {
    gap: 5px;
}

.header-balance {
    height: var(--height-header-balance, 33px);
    padding: 4px;
    border-radius: 999px;
    border: var(--header-balance-border, 1px solid rgba(255, 255, 255, 0.12));
    background-color: var(--header-balance-background-color, rgba($color: #000000, $alpha: 0.1));
    min-width: var(--min-width-header-balance, 135px);
    gap: 7px;
    cursor: pointer;
    >svg {
        width: var(--width-svg-header-balance, 22px);
        height: var(--width-svg-header-balance, 22px);
    }

    button>svg {
        width: var(--width-svg-add-header-balance, 25px);
        height: var(--width-svg-add-header-balance, 25px);
    }
    .header-top-main & {
        --min-width-header-balance: 150px;
        width: 150px;
    }
}

.header-balance-text {
    color: var(--color-header-top, #fff);
    position: relative;
    font-weight: 400;
    flex: 1;
    font-size: var(--font-size-header-balance-text);
    
    span {
        white-space: nowrap;
    }
}

.popover-balance-item {
    display: flex;
    padding: 5px 10px;

    span {
        flex: 1;

        &:first-child {
            width: 70px;
            display: inline-block;
        }
    }

    +.popover-balance-item {
        border-top: 1px solid #d2d2d3;
    }
}

.popover-balance {
    .MuiPopover-paper {
        min-width: 150px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 35px;
    }
}

.header-sound-config {
    color: var(--color-header-top, #fff);
    cursor: pointer;
}

.popup-sound-config-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    &:not(:last-child) {
        border-bottom: 1px solid #d2d2d3;
        padding-bottom: 5px;
    }
}

.header-avatar {
    width: 32px;
    height: 32px;
}

.header-regulation-icon,
.sound-volumn {
    svg {
        color: var(--color-header-top, #fff);
    }
}

.sound-volumn {
    cursor: pointer;
    padding: 5px;
}