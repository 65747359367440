.Scopa,
.AssoPigliaTutto,
.Cirulla,
.Briscola,
.Scopone,
.Tresette,
.Peppa,
.Bestia {
    --player-content-margin-left: -15px;
    --player-content-padding-left: 20px;
    --player-avatar-width: 70px;
    --player-content-width: 156px;

    .flex-row-reverse {
        --player-content-margin-right: -15px;
        --player-content-padding-right: 20px;
    }

    .table-result-head {
        min-height: 35px;
        height: auto;
    }

    .mobile & {
        --player-content-width: 100px;
        --player-avatar-width: 46px;
    }

    .deck.deck-vertical {
        --number-deck-left: 12px;
        --deck-after-left: -12px;
    }

    .deck {
        --card-width: var(--deck-width, 65px);
        --card-height: var(--deck-height, 111px);
        --deck-border-radius: 4px;

        .mobile & {
            --deck-width: 33px;
            --deck-height: 56px;
            --deck-border-radius: 2px;

            .number-deck {
                font-size: 18px;
                bottom: 0;
            }

            &:not(.hidden) {
                &::before {
                    border-radius: 2px;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                }

                &::after {
                    font-size: 6.5px;
                }
            }
        }
    }

    .card {
        width: var(--card-width, 87px);
        height: var(--card-height, 144px);

        &.my-card {
            --card-width: var(--my-card-width, 96px);
            --card-height: var(--my-card-height, 155px);
        }

        &.oppo-card {
            --card-width: var(--oppo-card-width, 69px);
            --card-height: var(--oppo-card-height, 115px);
        }
    }

    .pile .card {
        width: var(--pile-card-width, 44px);
        height: var(--pile-card-height, 72px);
    }
}

#table-round {
    height: var(--table-round-height, calc(100% - 20px));
    width: var(--table-round-width, calc(100vw - 130px));

    .mobile & {
        position: fixed;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
    }
}

.Piacentine {
    --card-width: 85px;
    --card-height: 144px;
    --my-card-width: 91px;
    --my-card-height: 155px;
    --oppo-card-width: 69px;
    --oppo-card-height: 115px;
    --pile-card-width: 42px;
    --pile-card-height: 72px;
}

.Siciliane {
    --card-width: 87px;
    --card-height: 144px;
    --my-card-width: 104px;
    --my-card-height: 155px;
    --oppo-card-width: 77px;
    --oppo-card-height: 115px;
    --pile-card-width: 48px;
    --pile-card-height: 72px;
}

.mobile {
    --card-width: 54px;
    --card-height: 88px;
    --my-card-width: 61px;
    --my-card-height: 101px;
    --oppo-card-width: 22px;
    --oppo-card-height: 38.5px;
    --pile-card-width: 20px;
    --pile-card-height: 32px;
    --player-avatar-width: 46px;
    --table-round-width: auto;
    --table-round-height: auto;

    @media (orientation: landscape) {
        --my-card-width: 52px;
        --my-card-height: 83px;
    }

    .Piacentine {
        --card-width: 52px;
        --card-height: 88px;
        --my-card-width: 59px;
        --my-card-height: 101px;
        --oppo-card-width: 22px;
        --oppo-card-height: 38.5px;
        --pile-card-width: 19px;
        --pile-card-height: 32px;

        @media (orientation: landscape) {
            --my-card-width: 49px;
            --my-card-height: 83px;
        }
    }

    .Siciliane {
        --card-width: 59px;
        --card-height: 88px;
        --my-card-width: 68px;
        --my-card-height: 101px;
        --oppo-card-width: 26px;
        --oppo-card-height: 38.5px;
        --pile-card-width: 21px;
        --pile-card-height: 32px;

        @media (orientation: landscape) {
            --my-card-width: 56px;
            --my-card-height: 83px;
        }
    }
}