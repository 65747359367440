.alert-message-container {
    .modal-main {
        min-height: 80px;
        overflow-y: scroll;
    }

    .modal-close:hover {
    }
    
    .alert-message-item {

        // background: #f4f8f9;
        height: 100%;

        .title-text {
            min-height: 40px;
            background-color: blue;
            color: white;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
        }

        .content-text {
            text-align: initial;
            // word-break: break-all;
            ul {
                list-style: disc;
                padding: revert;
            }
        }
    }
}
