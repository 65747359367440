
@import "../../../assets/styles/common/mixin";


.tournament-table-container {
    overflow-x: hidden;
    tr {
        align-items: center;
        td {
            color: var(--color, #101223);
            font-size: var(--font-size, 14px);
            font-weight: 500;
            height: 40px;
            padding: 4.5px 6px;
        }

        .subscribe-button {
            width: 100%
        }
        .play-button {
            width: 100%
        }
    }
    th {
        background-color: #F4F8F9;
        .description-filter {
            width: 40px;
            height: 40px;
            color: var(--color, #101223);
            
        }
    }

    
}

.ribbon {
    &-bronze {
        --Gradient-Bronze: linear-gradient(180deg, #AC5837 0%, #FFC4A6 49.5%, #BD5F34 100%);
    }

    &-gold {
        --Gradient-Bronze: linear-gradient(180deg, #B57E11 0%, #FEF2A4 49.17%, #C08F22 100%);
    }

    &-silver {
        --Gradient-Bronze: linear-gradient(180deg, #999 0%, #FFF 49.5%, #999 100%);
    }
}