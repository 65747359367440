@import "../../../assets/styles/common/mixin";


.tournament-table-container {
    tr {
        align-items: center;

        td {
            color: var(--color, #101223);
            font-size: var(--font-size, 14px);
            font-weight: 500;
            height: 40px;
            padding: 4.5px 6px;
        }


        .subscribe-button {
            width: 100%;
        }

        .play-button {
            width: 100%;
        }
        &:last-child {
            border-bottom: 1px solid #d2d2d3;
        }
    }

    

   
}