.tags-component {
    font-size: 12px;
    font-weight: 500;
    background-color: #F4F8F9;
    padding: 4px 5px;
    border-radius: 3px;
    color: var(--color);
    white-space: nowrap;
}

.tags-scheduled {
    --color: #237893;
}

.tags-closed {
    --color: #8E8E8E;
}

.tags-success {
    --color: #00BE2A;
}

.tags-processing {
    --color: #0066FF;
}

.tags-error {
    --color: #E20613;
}

.tags-warning {
    --color: #ffab04;
}

