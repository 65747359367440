@import '../../assets/styles/common/mixin';

.footer-main {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 10px 0;
    align-items: var(--footer-main-align-items, center);
    gap: var(--footer-main-gap, 30px);
    flex-wrap: var(--footer-main-flex-wrap, nowrap);
    flex-direction: var(--footer-main-flex-direction, row);

    @include respond-to(576px) {
        --footer-main-flex-direction: column;
        --footer-main-align-items: start;
        --footer-main-gap: 10px;
    }

    img {
        max-width: var(--footer-image-width-desktop, 203px);
        max-height: 35px;

        @include respond-to(576px) {
            --footer-image-width-mobile: 176px;
        }
    }
}

.footer-container {
    background-color: var(--background-footer, #0e345e);
    position: relative;

    .mobile & {
        @media screen and (orientation: portrait) {
            position: fixed;
            overflow: hidden;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .slick-slider {
        @include respond-to(576px) {
            margin-bottom: 74px;
        }
    }
}

.footer-content {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--color-footer-content, #fff);
    align-items: var(--footer-content-align-items, center);
    width: 100%;

    @include respond-to(576px) {
        --footer-content-align-items: flex-start;
    }
}

.footer-content-top {
    display: flex;
    gap: var(--footer-content-top-gap, 35px);
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
    justify-content: var(--footer-content-top-justify-content, center);

    @include respond-to(576px) {
        margin-top: 10px;
        flex-direction: column;
        align-items: self-start;
        gap: 5px;
    }
}

.footer-content-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-lable {
    background-color: #E7E4E4;
    border: 1px solid #102D2F;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    min-width: 41px;

    @include respond-to(900px) {
        position: absolute;
        top: 5px;
        right: 0;
    }

    .mobile-landscape & {
        position: absolute;
        top: 5px;
        right: 0;
    }
}

.mobile-menu {
    position: fixed;
    right: 0;
    height: var(--mobile-menu-height, 75px) !important;
    justify-content: space-between !important;
    bottom: 0;
    left: 0;
    z-index: 9;
    border-top: 1px solid #d2d2d3;
    svg {
        width: var(--mobile-menu-svg-height, 30px);
        height: var(--mobile-menu-svg-height, 30px);
        min-height: var(--mobile-menu-svg-height, 30px);
    }

    .mobile-menu-link {
        color: #8E8E8E;
        padding: 0;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        justify-content: center;
        flex: 1 1 0px;
        text-align: center;
        span {
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: var(--border-color-bottom-menu, transparent);
            padding: 2px 0;
            font-size: 11px;
        }

        &.active {
            &,
            svg {
                color: #0066FF;
            }

            span {
                --border-color-bottom-menu: #0066FF;
            }
        }
    }
}

.mobile-menu-left,
.mobile-menu-right {
    position: fixed;
    top: 0;
    width: 100px;
    bottom: 0;
    flex-direction: column;
    --mobile-menu-height: 100% !important;
    font-size: 12px;
    justify-content: space-around !important;

    svg {
        --mobile-menu-svg-height: 42px;
    }
}

.mobile-menu-right {
    right: 0;
    left: auto;
    border-left: 1px solid #d2d2d3;
}

.mobile-menu-left {
    left: 0;
    border-right: 1px solid #d2d2d3;
}

.slick-slide {
    >div {
        display: flex;
    }
}