.ItalianPoker {
    .bet-panel {
        z-index: 9999;
        .ant-drawer-content-wrapper {
            left: 776px;
            bottom: 66px;

            .mobile & {
                left: 247px;
                bottom: 91px;

                @media (orientation: landscape) {
                    left: 547px;
                    bottom: 53px;
                }
            }
        }
    }

    .player-seat {
        &.Fold {
            // opacity: 0.5;
        }

        &.Busted {

            img,
            .player-content,
            .player-avatar::after {
                opacity: 0.5;
            }
        }
    }

    .pot {
        position: absolute;
        top: 0;
        left: 0;
        width: 76px;
        height: 76px;

        &-label {
            position: absolute;
            top: calc(100% + 16px);
            line-height: 1.0;
            white-space: nowrap;
            left: 0;
            right: 0;
            color: #fff;
            font-weight: bold;
            text-align: center;

            .mobile & {
                font-size: 10px;
            }
        }

        .mobile & {
            width: 58px;
            height: 58px;
        }
    }

    .border-active {
        border-width: 3px;
        bottom: 0;
        border-style: solid;
        position: absolute;
        border-radius: 12px;
        animation: animationTooltip 1s linear infinite;
    }

    @keyframes animationTooltip {
        from {
            border-color: transparent;
        }

        to {
            border-color: #FDE82F;
        }
    }

}

.playerWinRound {
    position: absolute;
    border-radius: 8px;
    font-family: 'Raleway';
    // background: #3a5364;
    background: #4b5e6d;
    color: #fff;
    display: flex;
    font-weight: 700;
    justify-content: center;
    width: 385px;
    min-height: 30px;
    padding: 10px;
    font-size: 18px;
    left: 430px;
    align-items: center;
    z-index: 10001;
    top: 280px;

    .mobile & {
        padding: 4px;
        @media screen and (orientation: landscape) {
            width: 275px;
            min-height: 25px;
            top: 126px;
            left: 240px;
            font-size: 12px;
        }

        @media screen and (orientation: portrait) {
            width: 240px;
            min-height: 25px;
            font-size: 12px;
            top: 290px;
            left: 50%;
            transform: translateX(-50%);

            &.table-3-seats, &.table-4-seats {
                top: 160px;
            }
        }
    }
}

.itpoker-options {
    width: 100px;
    height: 78px;
    position: absolute;
    top: 459px;
    left: 968px;

    span {
        color: #fff;
        font-size: 12px;
    }

    .mobile & {
        .ant-checkbox+span {
            padding-inline-start: 4px;
        }

        @media screen and (orientation: landscape) {
            top: 263px;
            left: 258px;
        }

        @media screen and (orientation: portrait) {
            top: 518px;
            left: 25px;
        }
    }

    .Telesina & {
        .mobile & {
            @media screen and (orientation: portrait) {
                top: 600px;
            }

            @media screen and (orientation: landscape) {
                left: 558px;
            }
        }
    }
}