.premio-matto-container {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    position: fixed;
    top: 48px;
    left: 0;

    .mobile & {
        top: 0;
    }
}

.premio-matto-board {
    position: absolute;

    .ticket {
        position: absolute;
        background-image: url(./../../../../public/images/common/ticket_finance.png);
        width: 250px;
        height: 220px;
        left: -125px;
        top: -110px;
        display: none;
        text-align: center;
        color: #fff;
        font-size: 22px;
        padding-top: 92px;
        font-weight: 600;
    }

    .caption {
        position: absolute;
        background-image: url(./../../../../public/images/common/colpo_grosso_caption.png);
        width: 780px;
        height: 120px;
        left: -390px;
        top: -45px;
    }
}

$heightCappello: 334px; //284px
$widthCappello: 383px; //383px;

$heightCappelloAction: 234px;
$widthCappelloAction: 433px;

.cappello {
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    &:not(.active) {
        height: 284px;
        width: 383px;
    }

    &.active {
        animation: cappello 1s infinite linear;
    }
}

.parte_sotto-images,
.cappello-images {
    position: fixed;
    bottom: 100px;
    left: 50%;
    display: block;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateX(-50%);

    &.active {
        animation: cappello 1s infinite linear;
    }
}

.cappello-images {
    z-index: 1000;
}

.parte_sotto-images {
    bottom: 120px;
}

@keyframes cappello {
    0% {
        width: $widthCappello;
        height: $heightCappello;
    }

    50% {
        width: $widthCappelloAction;
        height: $heightCappelloAction;
        // margin-left: -$widthCappelloAction/2;
    }

    100% {
        width: $widthCappello;
        height: $heightCappello;
    }
}