.Burraco {
    --card-back-border-radius: 4px;
    --card-width: 57.333px;
    --card-height: 86px;

    &.table-Burraco {
        .mobile & {
            margin-bottom: 0;
            --table-round-height: calc(100% - 20px);

            @media screen and (orientation: portrait) {
                margin-top: 10px;
            }
        }
    }

    .my-card,
    .oppo-card {
        .desktop & {
            box-shadow: 0 0 5px black;
        }
    }

    .mobile & {

        .result-you,
        .result-me {
            font-size: 8px;
        }

        @media screen and (orientation: landscape) {
            .result-you {
                left: 5px;
            }

            .result-me {
                right: 5px;
            }

            .result-you,
            .result-me {
                bottom: 2px;
                width: 310px;
            }
        }
    }

    .player-bg {
        height: 98px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        border-radius: 8px;
        position: absolute;
        width: 550px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #114443;

        .mobile & {
            width: 100%;
            height: 60px;
            margin-top: 5px;

            @media screen and (orientation: landscape) {
                max-width: 329px;
            }
        }

        .desktop & {
            box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.4);
        }
    }
    .bg-show-meld {
        .mobile & {
            @media screen and (orientation: portrait) {
                bottom: 145px;
            }
        }
    }
}

.burraco-deck {
    position: absolute;
    top: 523.5px;
    left: 397px;
    scale: 0.872093023255814;
    display: flex;
    height: max-content;

    .mobile & {
        scale: 0.5263;

        @media screen and (orientation: landscape) {
            left: 332px;
            transform: rotate(90deg);
            top: 81px;

            .number-deck {
                rotate: 180deg;
                writing-mode: vertical-rl;
                left: 24%;
                top: 0;
                width: 100%;
                height: 100%;

                .Scopa & {
                    left: 12px;
                }
            }

            .deck::after {
                rotate: 180deg;
                writing-mode: vertical-rl;
                left: -12px;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 30px;
                font-size: 9px;
            }
        }

        @media screen and (orientation: portrait) {
            left: 58px;
            top: 285px;
            bottom: auto;
        }
    }

    &.border-active {
        &::before {
            top: -12px;
            bottom: -12px;
            left: -12px;
            right: -12px;

            .mobile & {
                bottom: -13px;

                @media screen and (orientation: portrait) {
                    right: -11px;
                }

                @media screen and (orientation: landscape) {
                    right: -4px;
                }
            }
        }
    }
}

.bg-game-Burraco {
    .header-mobile {
        .mobile & {
            @media screen and (orientation: portrait) {
                margin-top: 15px;
            }
        }
    }
}

.burraco-pozzetto {
    position: absolute;
    top: var(--burraco-pozzetto-top, 523.5px);
    left: var(--burraco-pozzetto-left, 303px);
    bottom: var(--burraco-pozzetto-bottom);
    right: var(--burraco-pozzetto-right);
    scale: var(--burraco-pozzetto-scale, 0.872093023255814);
}

.mobile {
    --burraco-pozzetto-scale: 0.48316762602;
    @media screen and (orientation: portrait) {
        --burraco-pozzetto-top: auto;
        --burraco-pozzetto-left: 40px;
        --burraco-pozzetto-bottom: 0px;
        .burraco-pozzetto-spectator {
            --burraco-pozzetto-top: auto;
            --burraco-pozzetto-left: 40px;
            --burraco-pozzetto-bottom: 50px;
        }
    }

    @media screen and (orientation: landscape) {
        --burraco-pozzetto-top: -15px;
        --burraco-pozzetto-left: auto;
        --burraco-pozzetto-right: -2px;
    }
}

.burraco-bg-discard {
    position: absolute;
    top: 522px;
    left: 481px;
    width: 624px;
    padding: 7px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
    height: 89px;

    .mobile & {
        width: 160px;
        padding: 3px;
        border-radius: 6px;
        background: #0F3D3C;
        height: 52px;

        @media screen and (orientation: portrait) {
            top: 302px;
            left: 111.5px;
        }

        @media screen and (orientation: landscape) {
            top: 170px;
            left: 309px;
            transform: rotate(90deg);
            width: 104px;
        }
    }
}


.burraco-meld-number {
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    line-height: 24px;
    padding: 5px 10px;
    border-top-left-radius: 20px;
    right: 0;
    font-size: 16px;
    border-left: 2px solid #FFC857;
    border-top: 2px solid #FFC857;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.meld-not-burraco {
    background-color: #0E3636;
}

.meld-burraco-clean {
    background-color: #D86502;
}

.meld-burraco-semi {
    background-color: #c58609;
}

.meld-burraco-dirty {
    background-color: #830005;
}

.burraco-meld-bottom {
    border-radius: 999px;
    background: linear-gradient(180deg, #0A9898 0%, #0E3636 80.73%, #0E3636 96.87%);
    width: 102px;
    height: 8px;
    bottom: -4px;
    right: 0px;
    z-index: 1000;
    position: absolute;
}

.burraco-meld-bottom-not-burraco {
    background: linear-gradient(180deg, #0A9898 0%, #0E3636 80.73%, #0E3636 96.87%);

}

.burraco-meld-bottom-clean {
    background: linear-gradient(180deg, #F82 0%, #F25F07 39.06%, #3C1414 100%);
}

.burraco-meld-bottom-semi {
    background: linear-gradient(180deg, #c58609 0%, #fdc34e 100%);
}

.burraco-meld-bottom-dirty {
    background: linear-gradient(180deg, #E40000 0%, #461111 100%);
}

.burraco-point {
    font-family: Metropolis;
    background-clip: text;
    line-height: normal;
    color: #FCFC4E;
}

.burraco-point-card {
    @extend .burraco-point;
    text-shadow: -2px 4px 0px rgba(125, 8, 22, 0.49),
        2px 3px 0px #322200;
    font-size: 14px;
    top: -15px;
    left: 3px;
    margin-right: 5px;
    animation: showPoints 5s ease-in-out infinite;
    -webkit-text-stroke: rgba(125, 8, 22, 0.49);
    -webkit-text-stroke-width: 0.2px;
    will-change: transform;

    @media screen and (max-width: 600px) {
        font-size: 14px;
        top: -25px;
    }

}

.burraco-point-meld {
    @extend .burraco-point;
    text-shadow: -2px 4px 0px rgba(125, 8, 22, 0.49),
        2px 3px 0px #322200;
    font-size: 25px;
    top: -45px;
    margin-right: 5px;
    animation: showPoints 5s ease-in-out infinite;
    -webkit-text-stroke: rgba(125, 8, 22, 0.49);
    -webkit-text-stroke-width: 0.5px;
    will-change: transform;
}


@keyframes showPoints {
    0% {
        transform: scale(0);
    }

    10% {
        transform: scale(1.3);
    }

    30% {
        transform: scale(1);
    }

    80% {
        transform: scale(1);
    }

    95% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(0);
    }
}