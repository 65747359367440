@import '../../../assets/styles/common/mixin';

.popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    height: 40px;
    background-color: #06F;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    @include respond-to(576px) {
        font-size: 20px;
    }
}

.popover-container {
    .MuiPopover-paper {
        border-radius: 5px;
        box-shadow: 5px 4px 13.7px 0px rgba(0, 0, 0, 0.15);
        border: 0.5px solid #D2D2D3;
    }
}