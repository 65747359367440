.quantico {
    --card-back-background-image: url('../../images/card-up/logo-png.webp');
}

.snai {
    --card-back-background-image: url('../../images/card-up/logo-snai-png.webp');
    --card-back-background-color: #333F48;
}

.sisal {
    --card-back-background-image: url('../../images/card-up/logo-sisal-png.webp');
}

.sks365 {
    --card-back-background-image: url('../../images/card-up/logo-sks365-png.webp');
    --card-back-background-size: 70%;
    --card-back-background-color: #22262B;
}

.eplay24 {
    --card-back-background-image: url('../../images/card-up/logo-eplay24-png.webp');
    --card-back-background-size: 80%;
    --card-back-background-color: #272727;
}

.betpoint {
    --card-back-background-image: url('../../images/card-up/logo-betpoint-png.webp');
    --card-back-background-color: #56585B;
}

.newgioco {
    --card-back-background-image: url('../../images/card-up/logo-newgioco-png.webp');
    --card-back-background-color: #272630;
    --card-back-background-size: 50%;
}

.betsson {
    --card-back-background-image: url('../../images/card-up/logo-betsson-png.png');
    // --card-back-background-color: #39ABBF;
}

.scommettendo {
    --card-back-background-image: url('../../images/card-up/logo-scommettendo-png.webp');
    --card-back-background-size: 50%;
    --card-back-background-color: #19384D;
}

.macaowin {
    --card-back-background-image: url('../../images/card-up/logo-macaowin-png.webp');
    --card-back-background-size: 50%;
    --card-back-background-color: #000000;
}

.casinomania {
    --card-back-background-image: url('../../images/card-up/logo-casinomania-png.webp');
    --card-back-background-size: 95%;
    --card-back-background-color: #000000;
}


.foursevenplay {
    --card-back-background-image: url('../../images/card-up/logo-foursevenplay-png.webp');
    --card-back-background-size: 50%;
    --card-back-background-color: #000000;
}

.bgame {
    --card-back-background-image: url('../../images/card-up/logo-bgame-png.webp');
    --card-back-background-size: 50%;
    --card-back-background-color: #000000;
}

.sanremo {
    --card-back-background-image: url('../../images/card-up/logo-sanremo-png.webp');
    --card-back-background-size: 50%;
    --card-back-background-color: #000000;
}