.Telesina {
  &.table-Telesina {
    .mobile & {
        height: auto;
        width: auto;
    }
  }

  @media screen and (orientation: portrait) {
    .chip-stack {
      position: absolute;
      z-index: 9999;
    }
  }

  .MuiDialogContent-root {
    background-color: #182138;
  }

  .bet-panel {
    .ant-drawer-content-wrapper {
      left: 776px;
      bottom: 66px;

      .mobile & {
        left: 247px;
        bottom: 91px;

        @media (orientation: landscape) {
          left: 547px;
          bottom: 53px;
        }
      }
    }
  }

  .player-seat {
    &.Fold {
      opacity: 0.5;
    }

    &.Busted {
      img,
      .player-content,
      .player-avatar::after {
        opacity: 0.5;
      }
    }

    .player-main {
      scale: 0.85;
    }
  }

  .pot {
    position: absolute;
    top: 0;
    left: 0;
    width: 76px;
    height: 76px;

    &-label {
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      color: #fff;
      font-weight: bold;
      text-align: center;

      .mobile & {
        font-size: 10px;
      }
    }

    .mobile & {
      width: 58px;
      height: 58px;
    }

    @media screen and (orientation: portrait) {
      .chip-item {
        width: 76px;
        height: 76px;
        min-width: 76px;

        .mobile & {
          width: 64px;
          height: 64px;
          min-width: 64px;
        }
      }
    }
  }

  .border-active {
    border-width: 3px;
    bottom: 0;
    border-style: solid;
    position: absolute;
    border-radius: 12px;
    animation: animationTooltip 1s linear infinite;
  }

  @keyframes animationTooltip {
    from {
      border-color: transparent;
    }

    to {
      border-color: #FDE82F;
    }
  }

  .ant-modal-root .ant-modal-mask {
    background: transparent !important;
  }

  .combination:not(:empty) {
    position: relative;
    display: flex;
    width: 150px;
    height: 25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #0B62A1;
    box-shadow: 0px 0px 4px 0px #000;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;

    .mobile & {
      width: 100px;
      height: 22px;
      font-size: 8px;
    }

    .position-tooltip {
      position: absolute;
    }
  }



  .table-in-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 45%;
    border-radius: 999px;
    width: calc(100% - 260px);
    height: calc(100% - 120px);
    border: 3px solid rgba(255, 255, 255, 0.2);
    z-index: -1;

    .mobile & {
      @media screen and (orientation: landscape) {
        top: 14px;
        transform: translate(-50%, 0);
        width: calc(100% - 86px);
        height: calc(100% - 52px);
      }

      @media screen and (orientation: portrait) {
        top: 30px;
        transform: translate(-50%, 0);
        width: calc(100% - 42px);
        height: calc(100% - 66px);
      }
    }
  }

  .playerWinRound {
    position: absolute;
    border-radius: 8px;
    font-family: 'Raleway';
    background: #4b5e6d;
    color: #fff;
    display: flex;
    font-weight: 700;
    justify-content: center;
    width: 385px;
    height: 30px;
    padding: 10px;
    font-size: 18px;
    align-items: center;
    left: 800px;
    top: 500px;

    .mobile & {
      @media screen and (orientation: landscape) {
        width: 220px;
        height: 25px;
        font-size: 11px;
        left: 30px;
        margin-top: 136px;
        text-align: center;
      }

      @media screen and (orientation: portrait) {
        width: 220px;
        height: 25px;
        font-size: 11px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        top: 60px;
      }
    }
  }


}

.telesina-options {
  .variant-options {

    .variant-item {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

    }

    .variant-item-active {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-image: linear-gradient(#faae34, #faae34) 30;
      border-width: 5px;
      border-style: solid;
      background: #00f;
    }
  }
  .MuiSvgIcon-root {
    path {
      color: #175EBE
    }
  }
}