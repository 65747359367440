.club-info-title {
    text-align: center;
    font-size: 14px;
    background-color: rgba(204, 255, 0, 1);
    width: 70%;
    padding: 4px 8px;
    border-radius: 15px;
    margin: 0 auto;
    line-height: 17px;
    color: #000;
}

.club-container {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .button-prev {
        background-image: linear-gradient(#CE0000, #860101);
        border: none;
        color: #fff;
        border-radius: 10px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            background-image: linear-gradient(#860101, #CE0000);
        }

        .MuiButtonBase-root {
            color: white;
            width: 312px;
            padding: 12px 0px;
        }

    }

    .filter {
        width: 100%;
        height: calc(100% - 31px);
        position: absolute;
        display: flex;
    }

    .club-info-container {
        background-color: rgba($color: #011E624D, $alpha: 0.7);
        width: 440px;
        height: 100%;
        position: relative;

        .image-right {
            position: absolute;
            left: 0;
            top: 6%;
            right: 0;
            margin: 0 auto;
        }

        .image-text {
            position: absolute;
            left: 0;
            top: 39%;
            right: 0;
            margin: 0 auto;
        }

        .club-info-title-mobile {
            display: none;
        }

        .info-container-desktop {
            position: absolute;
            left: 0;
            top: 72%;
            right: 0;
            margin: 0 auto;
            padding: 0 10%;
            font-family: 'Inter', 'Inter V', 'Raleway', sans-serif;

            .info-content-container {
                margin: 0 auto;
                padding: 2px 40px;
            }

            .content {
                color: white;


                p {
                    font-size: 15px;
                    line-height: 27px;
                }
            }
        }

        .user-online-container {
            padding: 36px 0;

            .title {
                text-align: center;
                font-size: 16px;
                color: #CCFF00;
                text-transform: uppercase;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            .list-user-container {
                width: 300px;
                margin: 0 auto;
                position: relative;

                .club-settings-field-icon {
                    position: absolute;
                    right: 5px;
                    top: 3px;

                    svg {
                        width: 30px;
                        height: 40px;
                        color: white;
                    }
                }

                .reload-button {
                    position: absolute;
                    right: -40px;
                    top: 10px;
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                    }

                    &:active {
                        transform: scale(0.98);
                        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                    }
                }

                .tag-container {
                    .player-tag {
                        color: #fff;
                        background-color: #2644b5;
                        margin: 4px 2px;

                        svg {
                            color: #fff;
                        }
                    }
                }

            }

            .reload-button {
                right: -30px;
            }
        }

    }

    .title {
        color: rgba(204, 255, 0, 1);
        font-size: 24px;
        line-height: 29px;
        text-align: center;
    }

    .settings-container {
        padding: 30px;
        width: calc(100% - 440px);
        text-align: center;

        .setting-fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin-top: 30px;
        }

        .title {
            text-transform: uppercase;
            font-weight: bold;
        }

        .button-create {
            background-image: linear-gradient(#0066FF, #003D99);
            border: none;
            color: #fff;
            border-radius: 10px;
            font-size: 16px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-image: linear-gradient(#0147af, #0066ff);
            }

            .MuiButtonBase-root {
                color: white;
                width: 312px;
                padding: 12px 0px;
            }

            .MuiSvgIcon-root {
                margin-top: 0px;
            }
        }

    }

    .club-settings-field {
        background-image: linear-gradient(#051D4E80, #0C43B480);
        border: 2px solid #4438CF;
        color: #fff !important;
        padding: 0px 10px;
        text-align: left;
        height: 40px;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;

        .Mui-focused {
            border: none;
        }

        fieldset {
            border: none;
        }

        .Mui-disabled {
            color: white !important;
            -webkit-text-fill-color: rgb(255 255 255 / 100%);
        }

    }

    ::placeholder {
        color: rgb(255, 255, 255);
        opacity: 1;
        /* Firefox */
    }

    .club-setting-ul {
        width: 100%;
        margin-top: 10px;
        position: absolute;
        background-color: rgba($color: #011E624D, $alpha: 0.3);
        overflow: auto;
        height: 250px;
        border: 2px solid #4438CF;
        border-radius: 5px;
        scrollbar-color: #4438CF #061147;
        z-index: 1;

        li {
            color: white;
            padding: 8px 15px;
            font-size: 16px;
            border-bottom: 1px solid;
            border-color: rgba($color: #4438CF, $alpha: 0.8);
            cursor: pointer;

            &:hover {
                background-image: linear-gradient(to right, #003E9A, #062656);
            }
        }

        .no-hover {
            cursor: default;

            &:hover {
                background-image: none;
            }
        }

        .li-selected {
            background-image: linear-gradient(to right, #003E9A, #062656) !important;
        }

        .text-no-result {
            color: white;
            padding: 8px 15px;
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
        }
    }

    .club-settings-label {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;

        p {
            color: white;
            font-size: 16px;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    .list-player-container {
        width: calc(100% - 440px);
        padding: 30px;
        position: relative;

        .list-invite {
            color: white;
            font-size: 16px;
            width: 200px;
            margin: 30px auto;

            p {
                margin: 10px 0;
            }

            .other-invite {
                display: grid;
                grid-template-columns: 1fr;
                /* Single column by default */
                grid-auto-rows: auto;
                /* Ensures rows adjust to content */
                gap: 5px;
                /* Adjust gap as needed */
                justify-content: center;
                margin-left: 60px;

                &-overflow {
                    grid-template-columns: 1fr 1fr;
                    /* Single column by default */
                }

                div {
                    width: 200px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }


        }

        .title {
            text-transform: uppercase;
            font-weight: bold;
        }
    }


    .bottom-container {
        position: absolute;
        width: 695px;
        bottom: 20px;

        .list-invite-buttons {
            display: flex;
            justify-content: center;
            gap: 10px;
        }

        .button-submit {
            background-image: linear-gradient(#0066FF, #003D99);
            border: none;
            color: #fff;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;

            &:hover {
                background-image: linear-gradient(#0147af, #0066ff);
            }

            .MuiButtonBase-root {
                color: white;
                width: 312px;
                padding: 12px 0px;
            }

            .MuiSvgIcon-root {
                margin-top: 0px;
            }
        }
    }


    .join-table-container {
        width: calc(100% - 440px);
        padding: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .list-invite {
            color: white;
            font-size: 16px;
            width: 200px;
            margin: 40px auto;

            p {
                margin: 10px 0;
            }
        }

        .title {
            text-transform: uppercase;
            font-weight: bold;
        }

        .table-relative {
            position: relative;
            margin-top: 15px;

            .table-image {
                width: 261px;
                height: 135px;
            }

            .player {
                text-align: center;

                position: absolute;

                p {
                    font-size: 10px;
                    color: white;
                }

                .player-image {
                    width: 36px;
                    height: 36px;
                    margin: 0 auto;
                }

                &-me {
                    left: 110px;
                    bottom: -30px;
                }
            }

            .invite-button {
                cursor: pointer;
                border: 2px solid rgba(255, 255, 255, 0.2);
                position: relative;
                width: 43px;
                height: 43px;
                padding: 0px !important;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .join-table {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 369px;
            height: 240px;
            margin: 28px auto;
        }




        // .table-container {
        //     margin: 0 auto;
        //     width: 100%;
        //     height: 80%;
        // }
    }

    .timeout-container {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        background-color: #05002133;
        padding: 8px 0;

        .timeout-text {
            font-size: 16px;
            display: flex;
            gap: 5px;
            align-items: center;

            .time-text {
                font-size: 20px;
                color: #CCFF00
            }
        }

    }

    @media screen and (max-width: 520px) {

        .filter {
            display: block;

            .club-info-container {
                height: 375px;
                width: 100%;
                display: flex;
                padding: 0 5px;
               
                .image-right {
                    position: relative;
                    width: 50%;
                    height: 200px;
                    object-fit: contain;
                }

                .image-text {
                    position: relative;
                    width: 50%;
                    top: 0;
                    height: 200px;
                    object-fit: contain;

                }

                .info-container-desktop {
                    .club-info-title-desktop {
                        display: none;
                    }

                    top: 65%;
                }

                .club-info-title-mobile {
                    display: block;
                    font-size: 13px;
                    width: 195px;
                    position: absolute;
                    bottom: 180px;
                    right: 7px;
                    border-radius: 20px;
                }

                .info-content-container {
                    top: 10px;
                    text-align: center;
                }

                .user-online-container {
                    width: 100%;
                    height: 175px;
                    padding: 10px 0px;

                    .reload-button {
                        right: -34px
                    }
                }


            }

            .settings-container {
                width: 100%;
                padding: 20px;

                .setting-fields {
                    grid-template-columns: 1fr;
                }

                .button-create {
                    width: calc(100% - 40px);

                    .MuiButtonBase-root {
                        width: 100%;
                    }
                }
            }

            .club-user-online-container {
                height: 175px;
            }

            .list-player-container {
                width: 100%;
                padding: 20px;
                height: 70%;

                .other-invite {
                    grid-template-columns: 1fr;
                }

                .list-invite-buttons {
                    display: block;

                    .club-button {
                       text-align: center;
                       margin: 10px 0;
                    }
                }
            }

            .bottom-container {
                width: calc(100% - 40px);
                padding: 0px;
                position: relative;
                margin: 100px auto;
            }

            .join-table-container {
                width: 100%;
                padding: 20px;

                .join-table {
                    width: 100%;
                    height: 200px;
                    margin: 70px 0;
                }
            }

            .timeout-container {
                padding: 10px 5px;

                .timeout-text {
                    font-size: 16px;
                    flex-wrap: wrap;
                    justify-content: center;
                    
                }
            }
        }

        .club-setting-ul {
            background-color: rgba(1, 30, 98, 1)
        }
    }
}

.club-layout {
    overflow: hidden;
    width: 1176px;
    height: 485px;
    position: relative;
    border-radius: 10px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(2, 0, 16, 0.8), rgba(6, 0, 32, 0.8)), url(../../../public/images/club/background-left.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }

    .loading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        position: absolute;
    }


    .club-type {
        border: none;
        border-radius: unset;
        overflow: hidden;
    }

    .tabs-desktop {
        width: 100%;
        display: flex;
        justify-content: start;
        position: relative;

        .tab {
            text-transform: uppercase;
            background: linear-gradient(180deg, #051D4E 0%, #0C43B4 100%);
            color: #fff;
            font-size: 14px;
            padding: 5px 30px;
            border-right: 2px solid #10171F;
            font-weight: bold;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }

            &-active {
                opacity: 1;
            }
        }

    }

    .table-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            opacity: 0.3;
        }
    }

    .player-me-1 {
        bottom: 101%;
        left: 110px;
        margin-bottom: 5px;
        margin-bottom: -15px;

        .player-group {
            flex-direction: column-reverse;
        }


    }

    .player-empty-0 {
        left: 100%;
        margin-left: 5px;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
    }

    .player-empty-1 {
        right: 100%;
        margin-right: 5px;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
    }

    .player-name {
        white-space: nowrap;
        font-size: 10px;
        line-height: 1.1;
        color: #fff;
        overflow: visible;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;

        &-top {
            top: -15px;
        }

        &-bottom {
            bottom: -15px;
        }
    }


}

.club-footer {
    height: 20px;
}

@media screen and (max-width: 520px) {
    .club-layout {
        overflow: hidden;
        width: 100vw;
        height: 1150px;
        border-radius: 0px;
        padding: 0px;

        .tabs-desktop {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(2, 0, 16, 0.8), rgba(6, 0, 32, 0.9)), url(../../../public/images/club/background-left.webp);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
        }

    }

    .club-footer {
        height: 0px;
    }
}

.club-information {
    background-color: rgba(1, 30, 98, 0.7);
    color: white;
    padding: 10px;
    position: absolute;
    right: 10px;
    width: 300px
}

.club-player-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.club-cancel-invite-btn {
    width: 100%;
    padding: 12px !important;
    max-width: 50%;
    margin-top: 10px !important;
}

.club-mobile-tabs {
    display: flex;
    padding: 30px 20px 20px 20px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(1, 30, 98, 0.7);

    p {
        color: white
    }

    .select {
        width: 60%;
        position: relative;

        .select-field-icon {
            position: absolute;
            top: 8px;
            right: 5px;
        }
    }

}