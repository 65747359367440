.scheduled-table-container {
    max-height: calc(100vh - 200px);
    th {
        text-transform: uppercase;
        padding: 10px !important;
        span {
            white-space: break-spaces;
        }
    }
    td {
        font-family: 'Inter';
    }
}