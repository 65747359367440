@import "../../../assets/styles/common/mixin";


.table-container {
    @include respond-to(576px) {
        --font-size: 16px;
    }

    .MuiTable-root {
        overflow: hidden;
    }

    .detail-chip {
        td:empty {
            display: none;
        }
    }

    tr {
        th {
            background-color: #F4F8F9;
            font-weight: var(--font-weight, 700);
            padding: var(--padding, 9.5px 6px);
            color: #101223;
            font-size: var(--font-size-th, 14px);
            white-space: nowrap;

            @include respond-to(576px) {
                --font-size-th: 20px;
                --font-weight: 600;
                border-top: 1px solid #d2d2d3;
                --padding: 6.25px 12px;
            }
        }

        th,
        td {
            border-bottom: 1px solid #d2d2d3;

            &:first-child {
                padding-left: var(--mobile-landscape-padding, 6px) !important;

                .tournament-container & {
                    --mobile-landscape-padding: 0;
                }
            }

            &:last-child {
                padding-right: var(--mobile-landscape-padding, 6px) !important;
            }
        }

        td {
            color: var(--color, #101223);
            font-weight: 500;
            padding: 4.5px 6px;
            font-size: var(--font-size, 14px);
            height: 40px;
        }
    }

    .filter-btn svg {
        color: #101223;
        width: 20px;
        height: 20px;
    }
}

.nodata-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 13% 0;
}

.nodata-header {
    img {
        width: 250px;

        .mobile & {
            width: 100%;
        }
    }
}

.nodata-content {
    font-size: 16px;
    font-weight: 500;

    p {
        margin-bottom: 5px;
        .rule-game & {
            &:not(:first-of-type) {
                display: none;
            }
        }
    }
}

.nodata-content-title {
    margin-bottom: 15px;
    color: #0E345E;
}

.hidden-sort {
    >svg {
        display: none;
    }
}

.table-sort-asc {
    >svg {
        rotate: 180deg;
    }
}

.table-container-pagination {
    p {
        margin-bottom: 0 !important;
    }
}