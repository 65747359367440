@import "../../assets/styles/common/mixin";

.header-small {
    --height-header-tabs-container: 30px;
    --height-search-form: 25px;
}

.header-tabs {
    background-color: var(--background-header-tabs, #0e345e);

    .filter-btn {
        height: 36px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;
    }


.filter-btn-disabled {
    opacity: 0.5;
}
    .filter-applies {
        position: absolute;
        top: 8px;
        left: 70px;
        color: white;
        font-size: 12px;
        width: 120px;
    }

    .filter-desktop {
        position: relative;
        width: 35px;

        .filter-btn-desktop {
            height: 25px;
            color: #fff;
            font-size: 16px;
            margin-top: 5px;
        }

        .filter-btn-desktop-disabled {
            opacity: 0.5;
        }

        .symbol {
            position: absolute;
            top: 0px;
            right: -15px;
            color: red;
            background-color: white;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid red;
            font-weight: bold;
            font-size: 13px;
            padding-top: 1px;
        }
    }

    .filter-mobile {
        position: relative;

        .symbol {
            position: absolute;
            top: 5px;
            right: -10px;
            color: red;
            background-color: white;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid red;
            font-weight: bold;
            font-size: 13px;
        }
    }



}

.header-tabs-container {
    height: var(--height-header-tabs-container, 50px);
    overflow: hidden;
}

.filter-mobile-list {
    flex: 1;
    margin: var(--filter-mobile-list-margin, -16px -24px);
}

.header-menu-link {
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: fit-content;
    // width: 100px;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: var(--border-color-header-menu-link, transparent);
    color: var(--color-header-menu-link, rgba(255, 255, 255, 0.4));
    text-wrap: nowrap;
    white-space: nowrap;

    &.active {
        --color-header-menu-link: #fff;
        --border-color-header-menu-link: #fff;
        font-weight: 700;

        path {
            fill: #fff;
        }
    }
}

.club-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.club-count-mobile {
    position: absolute;
    left: 20px;
    top: -2px;
}

.filter-mobile-item {
    padding: var(--padding-filter-mobile-item, 15px 10px);

    +.filter-mobile-item {
        border-top: 1px solid #D2D2D3;

        .filter-item>.filter-item-label {
            display: none;
        }
    }
}

.filter-mobile-label {
    font-size: 20px;
    font-weight: 600;
    color: #0066FF;
    margin-bottom: 15px;
}

.filter-mobile-modal {
    .filter-item-label {
        margin: 5px 0;
        gap: 8px;
        justify-content: flex-start;
        width: 50%;

        span,
        p {
            font-size: 16px;
            padding: 0;

            svg {
                font-size: 25px;
            }
        }
    }

    .filter-item-input {
        justify-content: flex-start;
    }

    .filter-item {
        >p {
            font-size: 16px;
            color: inherit;
            margin: 10px 0;
        }
    }
}

.filter-desktop-modal {
    .filter-desktop-label {
        font-size: 20px;
        font-weight: 600;
        color: #0066FF;
        margin-bottom: 15px;
    }

    .filter-item-label {
        margin: 5px 0;
        gap: 8px;
        justify-content: flex-start;
        width: 30%;

        span,
        p {
            font-size: 16px;
            padding: 0;

            svg {
                font-size: 25px;
            }
        }
    }

    .filter-item-input {
        justify-content: flex-start;
    }

    .filter-item {
        >p {
            font-size: 16px;
            color: inherit;
            margin: 10px 0;
        }
    }

    .filter-desktop-item {
        padding: var(--padding-filter-mobile-item, 15px 10px);

        +.filter-desktop-item {
            border-top: 1px solid #D2D2D3;

            .filter-item>.filter-item-label {
                display: none;
            }
        }
    }
}

.search-form {
    .MuiInputBase-root {
        height: var(--height-search-form, 36px);
    }

    @include respond-to(576px) {
        max-width: 100% !important;
    }
}