$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

.amount-text {
  color: #fff;
  font-weight: bold;
  font-size: var(--amount-text-font-size, 18px);
  white-space: nowrap;
  position: absolute;
}

.chip-stack {
  position: absolute;
  z-index: 9999;

  .chip-item {
    width: var(--chip-item-width, 52px);
    height: var(--chip-item-width, 52px);
    min-width: var(--chip-item-width, 52px);
    transform: translate(-50%, var(--chip-item-skew, 17px));
  }

  .pot & {
    .amount-text {
      top: -20px;
      text-align: center;
      width: var(--amount-text-width, 58px);
      left: 0;
      transform: translateX(-50%);
    }
    left: 50%;
  }
}

.player.chip-stack {
  .chip-item {
    transform: none;
  }
  .amount-text {
    left: 50px;
    top: 8px !important;

    .mobile & {
      left: 35px;
    }
  }
}

.pot {
  --chip-item-width: 76px;
  --chip-item-skew: 26px;
}

.rake {
  --amount-text-width: 52px;
  .amount-text {
    left: 0;
    transform: translateX(-50%);
  }
}

.last-action:not(:empty) {
  position: absolute;
  display: flex;
  width: var(--last-action-width, 90px);
  height: var(--last-action-height, 30px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #0C3332;
  text-align: center;
  font-size: var(--last-action-font-size, 14px);
  font-weight: 700;
  color: #fff;
}

.combination:not(:empty) {
  position: relative;
  display: flex;
  width: var(--combination-width, 200px);
  height: var(--combination-height, 30px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #0B62A1;
  box-shadow: 0px 0px 4px 0px #000;
  color: #FFF;
  text-align: center;
  font-size: var(--combination-font-size, 16px);
  font-weight: 700;

  .position-tooltip {
    position: absolute;
  }
}

#qg-cirulla,
#qg-scopa,
#gg-italianPoker,
#gg-telesina {
  -webkit-user-drag: none;
}

.card,
.deck {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  will-change: transform;
  border-radius: var(--card-border-radius, 8px);

  .back {
    background-size: var(--card-back-background-size, 55%);
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    background-image: var(--card-back-background-image, none);
    background-color: var(--card-back-background-color, #193161);
    border: var(--card-back-border-width, 2px) solid #fff;
    border-radius: var(--card-back-border-radius, 8px);
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    box-shadow: var(--card-back-box-shadow, 0 0 8px rgba($color: #000000, $alpha: 1));
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .face {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 1px #0003;
    border-radius: var(--card-back-border-radius, 8px);

    img {
      border-radius: var(--card-back-border-radius, 8px);
      width: 100%;
      height: 100%;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  .highlight {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 6px #D66C01 !important;
    border-radius: 8px;
    overflow: hidden;
    
    &.taken {
      box-shadow: 0 0 0 6px #01d613 !important;
    }
    .mobile & {
      box-shadow: 0 0 0 4px #D66C01 !important;
    }
  }

  .highlight-many {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 5px #D66C01;
    border-radius: 8px;
    overflow: hidden;

    &.taken {
      box-shadow: 0 0 0 6px #01d613 !important;
    }
  }

  .card-trump {
    z-index: 0;
  }
}

// .Briscola,
// .Tresette,
.AssoPigliaTutto {
  .card {
    height: 202px;
  }
}

.card,
.deck,
.face {
  width: var(--card-width);
  height: var(--card-height);
  img {
    box-shadow: 0 0 0 1px #0003;
  }
}

.ItalianPoker,
.Telesina,
.GinRummy {
  --card-height: 144px;
  --card-width: 96px;
}

.player-bg {
  height: 98px;
  background-color: rgba($color: #000000, $alpha: 0.1);
  border-radius: 8px;
  position: absolute;
  width: 550px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #114443;

  .mobile & {
    width: 100%;
    height: 70px;

    @media screen and (orientation: landscape) {
      max-width: 329px;
    }
  }

  .desktop & {
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.4);
  }

}

.player-info {
  display: flex;
  align-items: center;
}

.player-avatar {
  position: relative;
  z-index: 2;
  width: var(--player-avatar-width, 70px);
  height: var(--player-avatar-width, 70px);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #B57E11;
  }

  &-main {
    border-radius: 50%;
    position: relative;
    aspect-ratio: 1 / 1 !important;
  }
}

.player-avatar-image {
  img {
    border-radius: 50%;
  }

  &::before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}

.player-ranking-list {
  display: flex;
  align-items: center;

  span+span {
    margin-left: var(--player-ranking-list-margin-left, -1px);
  }

  img {
    max-width: var(--player-ranking-list-img-max-width, 14px);
  }

  .player-balance+& {
    margin-top: -2px;
  }

}


.player-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  font-size: var(--player-time-font-size, 14px);
  background-color: var(--player-time-background-color);

  &.overlay {
    --player-time-background-color: rgba(3, 3, 3, 0.50);
  }
}

.player-status {
  font-size: var(--player-status-font-size, 20px);
  font-weight: 900;
  -webkit-text-stroke: var(--player-status-stroke, 1.5px) #000000;
  line-height: 1.1;
}

.player-passo {
  font-size: var(--player-passo-font-size, 15px);
  font-weight: 900;
  -webkit-text-stroke: var(--player-passo-stroke, 0.1px) #000000;
}

.player-content {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-family: 'Inter';
  justify-content: center;
  margin-left: var(--player-content-margin-left, -25px);
  margin-right: var(--player-content-margin-right, 0);
  border: 1px solid #9E9E9E;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(7.5px);
  border-radius: var(--player-content-border-radius, 0 100px 100px 0);
  height: var(--player-content-height, 52.5px);
  width: var(--player-content-width, 147px);
  padding-left: var(--player-content-padding-left, 30px);
  padding-right: var(--player-content-padding-right, 10px);
  overflow: hidden;
}

.player-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--player-name-font-size, 15px);
  line-height: 1.1;
  overflow: hidden;
  color: #fff;

  +.player-ranking-list {
    margin-top: 2px;
  }
}

.player-dealer {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  right: -13px;
  width: 35px;

  .mobile .flex-row-reverse & {
    left: 0;
    right: auto
  }

  .mobile & {
    right: 0;
    left: auto;
  }

  @media screen and (min-width: $screen-xl) {
    top: 66%;
    width: 45px;
  }
}

.player-counter {
  position: absolute;
  border-radius: 50%;
  background-color: #092020;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-counter-me {
  bottom: 0;
  top: auto;

  @media screen and (min-width: $screen-xl) {
    bottom: -7px;
  }
}

.flex-row-reverse {
  --player-content-margin-right: -25px;
  --player-content-margin-left: 0;
  --player-content-border-radius: 100px 0 0 100px;
  --player-content-padding-right: 30px;

  .player-content {
    align-items: flex-end;
  }

  .mobile & {
    --player-content-margin-right: -15px;
    --player-content-padding-right: 20px;
  }
}

.player-time-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F30301; //F30301

  +span {
    z-index: 1;
  }
}

.player-main {
  display: flex;
  align-items: center;
}

.player-balance {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--player-balance-font-size, 12px);
  color: #fff;
  margin-top: -1px;
}

.player-my-turn {
  --box-shadow-color: transparent;
  animation: blinking 2s infinite;
  --player-time-background-color: rgba(3, 3, 3, 0.50);

  .player-content {
    background-color: var(--player-content-bg-color, rgba(0, 0, 0, 0.50));
    border-color: var(--player-content-border-color, #9E9E9E)
  }
}

@keyframes blinking {
  50% {
    --player-content-bg-color: rgba(255, 136, 34, 0.60);
    --player-content-border-color: #FF8822;
  }
}

.number-deck {
  color: #fff;
  @extend .font-raleway;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--number-deck-font-size, 30px);
  line-height: var(--number-deck-line-height, 1.0);
}


.deck {
  position: relative;

  &:not(.hidden) {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--deck-gap, 5px);
    flex-direction: var(--deck-flex-direction, column-reverse);

    * {
      position: relative;
      z-index: 9;
    }

    &::before {
      content: '';
      position: absolute;
      top: var(--deck-before-top, 2px);
      left: var(--deck-before-left, 2px);
      right: var(--deck-before-right, 2px);
      bottom: var(--deck-before-bottom, 2px);
      border-radius: 4px;
      background-color: rgba(9, 32, 32, 0.65) !important;
      z-index: 9;
    }

    &::after {
      content: '';
      background-size: var(--card-back-background-size, 55%);
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      background-image: var(--card-back-background-image, none);
      background-color: var(--card-back-background-color, #193161);
      border: var(--card-back-border-width, 2px) solid #fff;
      border-radius: var(--card-back-border-radius, 8px);
      height: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      box-shadow: var(--card-back-box-shadow, 0 0 8px rgba($color: #000000, $alpha: 1));
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .card-deck {
      &::before {
        content: "CARTE MAZZO";
        font-size: var(--deck-after-font-size, 12px);
        display: inline-block;
        color: #fff;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        height: var(--card-deck-height);
        line-height: var(--card-deck-line-height);
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }

  &.deck-vertical {
    --number-deck-left: 22%;
    --number-deck-top: 0;
    --deck-after-left: -15px;
    --deck-after-top: 0;
    --deck-flex-direction: row-reverse;
    --number-deck-line-height: 0.8;
    --card-deck-height: 50px;
    --card-deck-line-height: 1.1;
    --deck-gap: 0;

    .number-deck,
    .card-deck {
      rotate: 180deg;
      writing-mode: vertical-rl;
      display: flex;
    }
  }
}

.mobile {
  --deck-after-top: 15%;
}

.pile {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  left: 0;
}

.game-hand {
  position: absolute;
}

.hand-highlight {
  filter: drop-shadow(0px 4px 66px #FFC857);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  &:not(.hand-hidden) {

    .mobile & {
      z-index: 200;
    }
  }
}

.game-hand {
  position: absolute;
  top: 0;
  left: 0;
}

:root {
  --win-box-width: 146px;
  --win-box-height: 66px;
  --win-text-width: 118px;
  --win-text-height: 52px;
  --win-background-width: 146px;
  --win-background-height: 66px;
  --win-small-w-bg: 120px;
  --win-small-h-bg: 60px;
  --knock-text-width: 300px;
  --knock-text-height: 300px;
}

.mobile {
  --player-avatar-width: 50px;
  --player-emoji-width: 35px;
  --player-name-font-size: 10px;
  --player-content-margin-left: -15px;
  --player-time-font-size: 10px;
  --player-content-width: 108px;
  --player-content-padding-left: 20px;
  --player-content-height: 37.5px;
  --player-ranking-list-margin-left: -2px;
  --player-ranking-list-img-max-width: 12px;
  --player-status-font-size: 15px;
  --player-status-stroke: 1px;
  --player-balance-font-size: 8px;
  --player-passo-font-size: 10px;
  --player-passo-stroke: 0.5px;
  --chip-item-width: 37px;
  --chip-item-skew: 12px;
  --last-action-width: 66.528px;
  --last-action-height: 11.659px;
  --last-action-font-size: 9px;
  --combination-width: 144px;
  --combination-height: 22px;
  --combination-font-size: 12px;
  --amount-text-font-size: 12px;
  --card-back-border-width: 1px;
  --card-back-border-radius: 4px;
  --knock-text-width: 200px;
  --knock-text-height: 200px;

  .pot {
    --chip-item-width: 58px;
    --chip-item-skew: 19px;
  }

  .rake {
    --amount-text-width: 37px;
  }

  .result-you,
  .result-me {
    background: rgba(11, 14, 17, 0.2);
    border-radius: 8px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #fff;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    left: 0;
    right: 0;
  }

  .result-you {
    top: 2px;
  }

  .result-me {
    bottom: 2px;
  }

  @media (orientation: landscape) {
    
    .result-you,
    .result-me {
      bottom: 8px;
      top: auto;
      transform: translateX(0);
      width: calc(50% - 80px);
      min-width: 300px;
      max-width: 360px;
    }

    .result-me {
      right: 0;
      left: auto;
    }

    .result-you {
      left: 0;
      right: auto;
    }
  }

  @media screen and (orientation: portrait) {
    --win-box-width: 146px;
    --win-box-height: 66px;
    --win-text-width: 78.4px;
    --win-text-height: 34.55px;
    --win-background-width: 97px;
    --win-background-height: 43.85px;
    --win-small-w-bg: 77px;
    --win-small-h-bg: 66px;
  }

  @media screen and (orientation: landscape) {
    --win-box-width: 117px;
    --win-box-height: 52.89px;
    --win-text-width: 78.4px;
    --win-text-height: 34.55px;
    --win-background-width: 97px;
    --win-background-height: 43.85px;
    --win-small-w-bg: 80px;
    --win-small-h-bg: 66px;
  }
}

.game-hand {
  .win,
  .open {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9998 !important;
    width: var(--win-box-width);
    height: var(--win-box-height);
    display: none;

    &::before,
    &::after {
      content: '';
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    &:after {
      background-image: var(--win-open-after-background-image, url('../../../public/images/common/win.webp'));
      z-index: 99;
      width: 0;
      height: 0;
    }


    &::before {
      width: var(--win-background-width);
      height: var(--win-background-height);
      background-image: url('../../../public/images/common/background_win.webp');

      .Scala40 &,
      .Burraco & {
        width: var(--win-small-w-bg);
        height: var(--win-small-h-bg);
      }
    }

    &.active {
      display: block;
    
      &:after {
        animation: showWinText 0.25s linear, winText 0.7s linear 0.25s;
        width: var(--win-text-width);
        height: var(--win-text-height);
      }
    
      &::before {
        animation: winBackground 0.7s linear;
      }
    }
  }
}

.game-hand {
  .win {
    .Scala40 & {
      --win-open-after-background-image: url('../../../public/images/common/chiudo.png');
    }
  
    .Burraco & {
      --win-open-after-background-image: url('../../../public/images/common/burraco.png');
    }
  }

  .open {
    .Scala40 & {
      --win-open-after-background-image: url('../../../public/images/common/apro.png');
    }
    .Burraco & {
      --win-open-after-background-image: url('../../../public/images/common/pozzetto.png');
    }
  }
}

@keyframes winBackground {
  0% {
    width: 0;
    height: 0;
  }

  80% {
    width: calc(var(--win-background-width) * 1.25);
    height: calc(var(--win-background-height) * 1.25);
  }

  100% {
    width: var(--win-background-width);
    height: var(--win-background-height);
  }
}

@keyframes showWinText {
  0% {
    --win-text-width: 0;
    --win-text-height: 0;

  }

  100% {
    --win-text-width: 0;
    --win-text-height: 0;
  }
}

@keyframes winText {
  0% {
    --win-text-width: 0;
    --win-text-height: 0;
  }

  70% {
    --win-text-width: calc(var(--win-text-width) * 1.25);
    --win-text-height: calc(var(--win-text-height) * 1.25);
  }

  80% {
    --win-text-width: calc(var(--win-text-width) * 0.8);
    --win-text-height: calc(var(--win-text-height) * 0.8);
  }

  100% {
    --win-text-width: var(--win-text-width);
    --win-text-height: var(--win-text-height);
  }
}

.list-meld {
  display: block;
  position: absolute;
  z-index: -1;
  width: var(--list-meld-width, 570px);
  height: var(--list-meld-height, 394px);
  top: var(--list-meld-top, 122px);
  left: var(--list-meld-left, auto);
  bottom: var(--list-meld-bottom, auto);
  right: var(--list-meld-right, auto);
  border-radius: 8px !important;
  background-color: var(--list-meld-background-color, #095958);

  &.active {
    --list-meld-background-color: #076260;
    box-shadow: 0px 0px 20px 0px #369694 inset;
  }

  .mobile & {
    @media screen and (orientation: landscape) {
      --list-meld-width: 261px;
      --list-meld-bottom: 90px;
      --list-meld-left: 75px;
      --list-meld-top: 100px;
      --list-meld-height: auto;
    }

    @media screen and (orientation: portrait) {
      --list-meld-height: 137px;
      --list-meld-width: 100%;
      --list-meld-left: 0;
    }
  }
}

.list-meld-us {
  --list-meld-left: 120px;

  .mobile & {
    @media screen and (orientation: landscape) {
      transform: translate(0, 0);
    }

    @media screen and (orientation: portrait) {
      --list-meld-height: auto;
      --list-meld-top: 393.5px;
      --list-meld-bottom: 146.5px;
      --list-meld-left: 0;
    }
  }
}

.list-meld-them {
  --list-meld-right: 120px;

  .mobile & {
    @media screen and (orientation: portrait) {
      --list-meld-top: 105px;
    }

    @media screen and (orientation: landscape) {
      --list-meld-left: auto;
      --list-meld-right: 75px;
      transform: translate(0, 0);
    }
  }
}

.bg-show-meld {
  background-color: #076260;
  border-radius: 8px;
  position: absolute;
  z-index: -1;
  top: var(--bg-show-meld-top, 122px);
  height: var(--bg-show-meld-height, 394px);
  left: var(--bg-show-meld-left, 120px);
  right: var(--bg-show-meld-right, 120px);
  display: var(--bg-show-meld-display, none);

  .mobile & {
    --bg-show-meld-display: block;

    @media screen and (orientation: portrait) {
      bottom: 128px;
      --bg-show-meld-top: 105px;
      --bg-show-meld-left: 0;
      --bg-show-meld-right: 0;
      --bg-show-meld-height: auto;

      .table-logo {
        margin-top: -20px;
      }
    }

    @media screen and (orientation: landscape) {
      --bg-show-meld-top: 100px;
      width: 575px;
      --bg-show-meld-height: 153px;
      --bg-show-meld-left: 74.5px;
    }
  }
}

.player-sort-suit,
.player-sort-value {
  display: flex;
  position: absolute;
  visibility: hidden;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  span {
    transition: top 0.5s ease;
    width: 25px;

    .mobile & {
      width: 21.23px;
    }

    &:nth-child(1) {
      position: absolute;
      z-index: 1;
      left: -14px;

      .mobile & {
        left: -11.89px;
      }
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      position: absolute;
      right: -14px;
      z-index: 3;

      .mobile & {
        right: -11.89px;
      }
    }
  }
}

.player-sort-suit,
.active.player-sort-value {
  span {
    &:nth-child(1) {
      top: -6px;

      .mobile & {
        top: -5.09px
      }
    }

    &:nth-child(3) {
      top: 6px;

      .mobile & {
        top: 5.09px
      }
    }
  }
}

.active.player-sort-suit,
.player-sort-value {
  span {
    &:nth-child(1) {
      top: 6px;

      .mobile & {
        top: 5.09px
      }
    }

    &:nth-child(3) {
      top: -6px;

      .mobile & {
        top: -5.09px
      }
    }
  }
}

.active.player-sort-value,
.active.player-sort-suit {
  display: flex;
  visibility: visible;
  z-index: 2;
}

.player-sort-box {
  height: 50px;
  overflow: hidden;
  width: 53px;
  justify-content: center;
  align-items: center;

  .mobile & {
    width: 45px;
    height: 41.6px;
  }
}

.player-avatar-main > img {
  border-radius: 50%;
}