@import '../../assets/styles/common/mixin';

.modal-subsccribe {
    --font-size-14: 14px;
    --font-size-12: 12px;
    --header-balance-border: 1px solid #4A4A4A;
    --header-balance-background-color: #fff;
    --color-header-top: #101223;
    --font-size-header-balance-text: var(--font-size-14);

    @include respond-to(576px) {
        --font-size-14: 20px;
        --height-header-balance: 40px;
        --min-width-header-balance: 200px;
        --subcribe-pay-font-size: 20px;
        --tournament-info-font-size: 16px;
        --select-component-font-size: 16px;
        --select-component-height: 40px;
        --select-component-padding: 0 10px;
        --subcribe-radio-font-size: 20px;
    }

    &.mobile-landscape {
        --font-size-14: 20px;
        --height-header-balance: 40px;
        --min-width-header-balance: 200px;

        .modal-content {
            height: 100%;
        }

        .modal-footer {
            button {
                --button-medium-max-width: 375px;
                width: 100%;
            }
        }
    }

    .modal-footer {
        @media screen and (min-width: 600px) {
            padding-bottom: 20px;
            padding-top: 0;
        }
    }

    .modal-main {
        padding-left: var(--modal-main-padding, 0);
        padding-right: var(--modal-main-padding, 0);
    }

    .modal-title {
        justify-content: center;
    }

    .header-balance {
        .header-balance-text {
            text-align: left;

            @include respond-to(576px) {
                font-weight: 500;
            }
        }
    }

    .subcribe-pay {
        border-top: var(--subcribe-pay-border, 1px solid #d2d2d3);
        max-width: 284px;
        margin: 0 auto 10px auto;
        padding-top: var(--subcribe-pay-padding, 20px);
        font-size: var(--subcribe-pay-font-size, 14px);
        font-weight: 600;
        width: 100%;
        color: var(--subcribe-pay-color, inherit);
    }

    .MuiRadio-root {
        opacity: 0.5;
        margin: var(--subcribe-radio-icon-margin, 0);

        &.Mui-checked {
            opacity: 1;

            +.MuiTypography-root {
                opacity: 1;
            }
        }

        +.MuiTypography-root {
            opacity: 0.5;
            font-size: var(--subcribe-radio-font-size, 14px);
            color: var(--subcribe-radio-color);
        }
    }

    .text-error {
        font-weight: 500;
        font-size: var(--font-size-10, 10px);
        color: #E20613;
        margin-top: var(--margin-top-error, 2px);

        @include respond-to(576px) {
            --font-size-10: 16px;
            --margin-top-error: 10px;
        }
    }
}

.tournament-table-container {
    --color: #101223;

    tr {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: var(--Gradient-Bronze, transparent);
            box-shadow: var(--tournament-box-shadow, 0px 1px 1px 0px #FFF inset);
            width: 32px;
            bottom: 0;
            display: block;
        }

        td,
        th {
            &:first-child {
                padding-right: 0;
                width: 47px;
            }
        }
    }

    &.table-container tr {

        td,
        th {
            @include respond-to(1024px) {
                padding-left: 7px;
                padding-right: 7px;
            }
        }
    }

    .tournament-favorite {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
    }

    td {
        &:first-child {
            position: relative;
            padding: 0;
        }
    }
}

.ribbon {
    &-bronze {
        --Gradient-Bronze: linear-gradient(180deg, #AC5837 0%, #FFC4A6 49.5%, #BD5F34 100%);
    }

    &-gold {
        --Gradient-Bronze: linear-gradient(180deg, #B57E11 0%, #FEF2A4 49.17%, #C08F22 100%);
    }

    &-silver {
        --Gradient-Bronze: linear-gradient(180deg, #999 0%, #FFF 49.5%, #999 100%);
    }
}
.pulse-tournament-favorite {
    transition: 0.5s ease-out;
    -webkit-transform: scale(1.7);
    opacity: 0;
}
.tournament-des {
    display: flex;
    align-items: center;
    gap: 14px;

    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
}

.tournament-des-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.15;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    height: 20px;
}


.tournament-des-title-schedule-mobile {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    height: 38px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    .mobile-landscape & {
        -webkit-line-clamp: 1;
        height: 20px;
        line-height: 20px;
        margin-bottom: 0px;
        font-size: 14px;
    }

    @include respond-to(360px) {
        font-size: 14px;
    }
}


.tournament-mobile-cost {
    .mobile-landscape & {
        width: 105px;
    }
}
.tournament-des-time {
    font-size: 16px;
    color: #8E8E8E;
    font-weight: 400;
    .mobile-landscape & {
        margin-left: 10px;
    }
    @include respond-to(360px) {
        font-size: 13px;
    }
}

.badge-list {
    display: flex;
    align-items: center;
    min-width: var(--badge-list-min-width, 140px);

    img {
        height: var(--badge-list-height, auto);
    }
}

.tournament-info {
    border-bottom: 0.5px solid var(--tournament-info-border-bottom, #D2D2D3);
    padding: var(--tournament-info-padding, 20px 20px 15px 20px);
    font-size: var(--tournament-info-font-size, 14px);
    width: var(--tournament-info-table-width, 100%);

    @include respond-to(800px) {
        --tournament-info-border-bottom: #fff;
    }

    @include respond-to(576px) {
        --tournament-info-padding: 15px 10px 0 10px;

        img {
            width: 30px;
        }
    }
}

.tournament-info-list {
    padding-top: var(--tournament-info-list-padding, 8px);
    padding-bottom: var(--tournament-info-list-padding, 8px);
}

.tournament-info-title {
    font-weight: 600;
    font-size: var(--font-size-14);
    line-height: 1.3;

    @include respond-to(576px) {
        --font-size-14: 20px;
    }
}

.tournament-info-select {
    display: flex;
    justify-content: flex-end;
    padding: 7px 16px;
    align-items: center;

    >label {
        margin-right: 6px;
        color: var(--Dark-gray, #4A4A4A);
        font-size: var(--font-size-12, 12px);
        font-weight: 400;
    }

    .MuiSelect-select {
        font-size: var(--font-size-12, 12px) !important;
    }
}

.MuiMenu-paper {
    font-size: 14px;

    @include respond-to(576px) {
        font-size: 16px;
    }
}

.select-component {
    .MuiSelect-select {
        display: flex;
        min-width: 96px;
        height: var(--select-component-height, 27px) !important;
        padding: var(--select-component-padding, 0 5px);
        align-items: center;
        gap: 6px;
        font-size: var(--select-component-font-size, 14px);
        font-weight: 500;
        color: var(--Text-color, #101223);
    }
}

.MuiMenu-paper {
    li {
        font-size: var(--font-size-14);
    }
}

.tournament-filter-modal {
    .MuiDialog-paper {
        width: 100%;
        max-width: 600px;
    }

    .filter-item-input {
        flex-direction: column;
    }
}

.cashtable-filter-modal {
    .MuiDialog-paper {
        width: 100%;
        max-width: 400px;
    }

    .filter-item-input {
        flex-direction: column;
    }
}

.status-filter-modal {
    .MuiDialog-paper {
        width: 100%;
        max-width: 230px;

        .modal-main {
            padding: 8px 13px;

            .filter-item-input {
                flex-direction: column;
                overflow: hidden;
            }

            .MuiBox-root {
                margin-top: 0px;

                .MuiFormControlLabel-root {
                    height: 40px;
                }
            }

        }

        .modal-footer {
            justify-content: center;
        }
    }
}

.subcribe-title {
    font-weight: 500;
}

.subcribe-left,
.subcribe-right {
    padding-left: var(--subcribe-left-padding, 50px);
    padding-right: var(--subcribe-left-padding, 50px);
    max-width: 400px;
    margin: 0 auto;
}

.subcribe-money {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto 25px auto;
}

.subcribe-password-input {
    fieldset {
        border-color: var(--subcribe-password-input-border-color) !important;
    }

    .MuiInputBase-root {
        color: var(--subcribe-password-input-border-color) !important;
    }
}

.MuiRadio-root {
    &.Mui-checked {
        .MuiSvgIcon-root {
            color: #0066FF;
        }
    }

    .MuiSvgIcon-root {
        transform: scale(var(--subcribe-radio-scale, 1));
    }
}

.MuiSelect-select {
    .flex-1 {
        flex: none;
    }
}

.note-error {
    color: #E20613;
    line-height: 1;
    display: block;
}

.subcribe-main {
    width: 100%;
    // padding: var(--subcribe-main-padding, 0);

    p {
        font-size: var(--subcribe-main-font-size, 14px);
    }

    @include respond-to(576px) {
        // --subcribe-main-padding: 0 50px;
        --subcribe-main-font-size: 20px;
        --margin-form-control: 5px 0 10px -7px;
    }
}

.form-control {
    label {
        margin: var(--margin-form-control, 0 0 0 -7px);
    }

    @include respond-to(576px) {
        width: 100%;
    }
}

.tournament-mobile-favorite {
    padding: 0px 10px 0px 0px;
    z-index: 99;
    svg {
        width: 25px !important;
        height: 25px !important;
    }
    &.mobile-portrait {
        align-self: center;
    }
   
}

.state-label {
    font-size: var(--state-label-font-size, 12px);
    line-height: var(--state-label-height, 23px);
    line-height: var(--state-label-height, 23px);

    @media screen and (max-width: 576px) {
        --state-label-font-size: 16px;
        --state-label-height: 27px;
    }

    @include respond-to(360px) {
        --state-label-font-size: 12px;
    }
}

.tournament-mobile-item {
    --button-large-min-width: 100px;
    padding: var(--tournament-mobile-item-padding, 10px);
    position: relative;
    border-bottom: 1px solid #D2D2D3;
    height: 120px;
    .mobile-landscape & {
        height: 55px;
    }

    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: var(--Gradient-Bronze, transparent);
        box-shadow: var(--tournament-box-shadow, 0px 1px 1px 0px #FFF inset);
        width: 32px;
        bottom: 0;
    }

    .button-component {
        max-width: var(--tournament-mobile-item-width-button, 100px);
    }

    @include respond-to(360px) {
        font-size: 90%;
    }
    
}

.tournament-info-table {
    width: var(--tournament-info-table-width, 100%);
    padding: var(--tournament-info-table-padding, 0);
    height: var(--tournament-info-table-height, auto);

    @include respond-to(576px) {
        --tournament-info-table-padding: 0 0 60px 0;
    }
}

.tournament-mobile-player {
    svg {
        width: 24px;
        height: 24px;
        color: #8E8E8E;
    }
}

.tournament-mobile-line {
    font-weight: 500;
    --badge-list-min-width: auto;
    --badge-list-height: 25px;

    .label {
        color: #8E8E8E;
    }
}

.tournament-info-label {
    width: var(--tournament-info-label-width, 130px);
    color: #828B92;

    @include respond-to(576px) {
        --tournament-info-label-width: 150px;
    }
}

.tournament-info-value {
    font-weight: 500;
}

.tournament-info-btn {
    background-color: #fff;

    @include respond-to(576px) {
        position: fixed !important;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
        padding: 10px;
        z-index: 9;
    }

    .mobile-landscape & {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 55px;
        width: calc(50% - 25px);
    }
}

.tournament-info-drawer .MuiPaper-root {
    @include respond-to(576px) {
        padding-bottom: 40px;
    }
}

.mobile-landscape {
    .loading-tournament {
        z-index: 1000;
    }
}

.tournament-mobile-content-landscape {
    font-size: 14px;
    width: 100%;
}

.tournament-type-container {
    width: var(--tournament-type-container-width, 1176px);
    overflow: hidden;

    .mobile & {
        @media screen and (orientation: portrait) and (max-width: 678px) {
            --tournament-container-height: calc(100vh - 253px) !important;
            --tournament-type-container-width: 100%;
            min-height: 434px;
        }

        @media screen and (orientation: landscape) {
            --tournament-container-height: 487px !important;
        }

        @media screen and (orientation: portrait) and (min-width: 678px) {
            --tournament-container-height: 487px !important;
        }
    }

    .desktop & {
        --tournament-container-height: 487px !important;
    }

    .loader-container {
        width: 100% !important;
        top: 0 !important;
    }
}

.colpo-grosso-tournament,
.filotto-tournament {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background-image: var(--bg-tournament);
        background-color: var(--bg-tournament-color);
    }

    &::before {
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }
    .colpo-grosso-content, .filotto-content {
        z-index: 1;
    }

}

.colpo-grosso,
.filotto {
    &-main {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &-images {
        position: absolute;
        top: var(--tourament-images-top);
        left: var(--tourament-images-left);
        img {
            width: var(--tourament-images-width);
        }
        .mobile & {
            @media screen and (orientation: portrait) and (max-width: 678px) {
                position: static;
                --tourament-images-width: 340px;
            }
        }
    }

    &-content {
        position: var(--tourament-content-position, absolute);
        top: var(--tourament-content-top, 16.77631578947368%);
        right: var(--tourament-content-right, 119px);
        width: var(--tourament-content-width, 450px);
        z-index: 9;
    }

    &-item {
        background-repeat: no-repeat;
        width: var(--tourament-item-width, 140px);
        height: var(--tourament-item-height, 205px);
        background-position: center;
        cursor: pointer;
        border-radius: var(--tourament-item-border-radius, 10px);
        border: 1px solid #999;
        padding: var(--tourament-item-padding, 21px 5px 5px 5px);
        overflow: hidden;
        position: relative;
        background-image: var(--bg-tournament-item);

        &:hover {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: var(--tournament-type-bg-hover, rgba(192, 144, 40, 0.32));
                z-index: 0
            }
        }
    }

    &-title {
        background: var(--tournament-type-background-text, linear-gradient(180deg, #B57E11 0%, #FEF2A4 49.17%, #C08F22 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: var(--tournament-type-title-font-size, 24px);
        font-weight: 500;
        text-align: center;
        margin-bottom: var(--tournament-type-title-margin-bottom, 16px);
        line-height: var(--tournament-type-title-line-height);
        width: 100%;
        color: var(--tournament-type-title-color, #5D1F07);
        z-index: 1;
    }

    &-prize {
        font-size: var(--tournament-type-prize-font-size, 72px);
        font-weight: 600;
        line-height: var(--tournament-type-prize-line-height, 87px);
        color: var(--tournament-type-title-color, #5D1F07);
        z-index: 1;
    }

    &-text {
        font-size: var(--tournament-type-text-font-size, 20px);
        font-weight: 500;
        line-height: var(--tournament-type-text-line-height, 24px);
        color: var(--tournament-type-text-color, #4A4A4A);
        padding-top: var(--tournament-type-text-padding-top, 10px);
        border-top: 1px solid var(--tournament-type-text-border-color, #5D1F07);
        margin-top: var(--tournament-type-text-margin-top, 15px);
        width: 100%;
        text-align: center;
        z-index: 1;
    }

    &-total-prize {
        font-size: var(--tournament-type-total-prize-font-size, 26px);
        font-weight: 600;
        line-height: var(--tournament-type-total-prize-line-height, 31px);
        color: var(--tournament-type-title-color, #5D1F07);
        z-index: 1;
    }

    &-bottom-content {
        background-color: #212121;
        border-radius: 5px;

        &:hover {
            background-color: #101010;
        }
    }

    &-link {
        color: #fff;
        padding: 0 10px;
        height: 40px;
        background: var(--tournament-type-link-color, linear-gradient(180deg, #B57E11 0%, #FEF2A4 49.17%, #C08F22 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border: 1px solid var(--tournament-type-link-border-color, #B57E11);
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    &-bottom {
        margin-top: 12px;
    }
}

.colpo-grosso {
    &-images {
        --tourament-images-top: 3.7280701754386%;
        --tourament-images-left: 15.19213583556747%;
        --tourament-images-width: 360px;
    }
    &-tournament {
        --bg-tournament-item: url('../../../public/images/tournament/colpogrosso-items.webp');

        .mobile & {
            @media screen and (orientation: portrait) and (max-width: 678px) {
                --bg-tournament: url('../../../public/images/tournament/colpogrosso-mobile.webp');
                --bg-tournament-color: #1a1a18;
            }

            @media screen and (orientation: landscape) {
                --bg-tournament: url('../../../public/images/tournament/colpogrosso-desktop.webp');
            }
            @media screen and (orientation: portrait) and (min-width: 678px) {
                --bg-tournament: url('../../../public/images/tournament/colpogrosso-desktop.webp');
            }
        }

        .desktop & {
            --bg-tournament: url('../../../public/images/tournament/colpogrosso-desktop.webp');
        }
    }
}

.filotto {
    &-images {
        --tourament-images-top: 9.86842105263158%;
        --tourament-images-left: 16.0857908847185%;
        --tourament-images-width: 343px;
    }
    &-tournament {
        --tournament-type-title-color: #464646;
        --tournament-type-text-border-color: #BEBEBE;
        --tournament-type-total-prize-font-size: 32px;
        --tournament-type-total-prize-line-height: 39px;
        --tournament-type-background-text: linear-gradient(180deg, #999 0%, #FFF 49.5%, #999 100%);
        --tournament-type-link-color: linear-gradient(180deg, #999 0%, #FFF 49.5%, #999 100%);
        --tournament-type-link-border-color: #999;
        --tournament-type-bg-hover: #fff;
        --bg-tournament-item: url('../../../public/images/tournament/filotto_items.webp');

        .mobile & {
            @media screen and (orientation: portrait) and (max-width: 678px) {
                --bg-tournament: url('../../../public/images/tournament/filotto-mobile.webp');
                --bg-tournament-color: #1a1114;
            }

            @media screen and (orientation: landscape) {
                --bg-tournament: url('../../../public/images/tournament/filotto-desktop.webp');
            }

            @media screen and (orientation: portrait) and (min-width: 678px) {
                --bg-tournament: url('../../../public/images/tournament/filotto-desktop.webp');
            }
        }

        .desktop & {
            --bg-tournament: url('../../../public/images/tournament/filotto-desktop.webp');
        }
        
    }
}

.mobile {
    @media screen and (orientation: portrait) and (max-width: 678px) {
        --tournament-type-title-font-size: 16px;
        --tournament-type-prize-font-size: 50.4px;
        --tournament-type-prize-line-height: 60.9px;
        --tourament-item-width: 98px;
        --tourament-item-height: 146px;
        --tourament-item-padding: 14.7px 3.5px 3.5px 3.5px;
        --tourament-item-border-radius: 7px;
        --tournament-type-text-font-size: 14px;
        --tournament-type-text-line-height: 16.8px;
        --tournament-type-text-padding-top: 7px;
        --tournament-type-text-margin-top: 10px;
        --tournament-type-total-prize-font-size: 18.2px;
        --tournament-type-title-margin-bottom: 11px;
        --tournament-type-title-line-height: 19px;
        --tourament-content-position: static;
        --tourament-content-top: 193px;
        --tourament-content-right: 0;
        --tourament-content-width: 100%;
        --tournament-container-border-radius: 0;

        .colpo-grosso-list {
            gap: 10px;
        }

        .filotto-tournament {
            --tournament-type-total-prize-font-size: 22.4px;
            --tournament-type-total-prize-line-height: 27.3px;
        }
    }
}


.tournament-mobile-prize {
    white-space: nowrap;
    .mobile-landscape & {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.colpo-grosso-main,
.filotto-main {
    .mobile & {
        @media screen and (orientation: portrait) and (max-width: 678px){
            justify-content: flex-start;
            padding-top: 20px;
        }
    }
}

.tournament-mobile-badge {
    .mobile-landscape & {
        width: 85px;
    }
}

.table-list-eye {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 16px;
        filter: grayscale(0%) brightness(0) saturate(100) hue-rotate(180deg);
        transition: background-image 0.3s ease-in-out;
    }
    &:hover {
        cursor: pointer;
        img {
            filter: none;
        }
    }
}